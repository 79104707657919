import React, { useContext, useEffect, useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import {DataTable, DataTableStateEvent} from "primereact/datatable";
import { Column } from "primereact/column";
import {
  RegionOptions,
  StatusOptions,
} from "../../data/data";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "../../styles/IACServiceBidList.css";
import {getServiceBidList} from "../../services/serviceBidService";
import {SortOrder} from "primereact/api";

// IACServiceBidList component
const IACServiceBidList = () => {
  const navigate = useNavigate();
  const {state} = useLocation();

  // State variables
  const [bidListData, setBidListData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedTotalArea, setSelectedTotalArea] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedSortBy, setSelectedSortBy] = useState(null);
  const [searchText, setSearchText] = useState("");
  const initialParams: DataTableStateEvent = {
    first: 0,
    rows: 20,
    page: 0,
    sortField: '', // single sort only
    sortOrder: SortOrder.UNSORTED, // single sort only
    multiSortMeta: [],
    filters: {}
  };
  const [tableParams, setTableParams] = useState<DataTableStateEvent>(initialParams);

  // Utility function to format date strings
  const formatDate = (value: string) => {
    const date = new Date(value);
    return date.toISOString().slice(0, 10);
  };

  // Custom body template for the "Create Date" column
  const createDateBodyTemplate = (rowData: any) => {
    const updatedAt = new Date(rowData.createDate);
    const formattedDate = updatedAt.toISOString().split("T")[0];
    return formatDate(formattedDate);
  };

  // Custom body template for the "Due Date" column
  const dueDateBodyTemplate = (rowData: any) => {
    const updatedAt = new Date(rowData.dueDate);
    const formattedDate = updatedAt.toISOString().split("T")[0];
    return formatDate(formattedDate);
  };

  // Custom body template for the "Maintenance" column
  const maintenanceBodyTemplate = (rowData: any) =>
    rowData.maintenanceYn === "Y" ? "O" : "X";

  // Custom body template for the "Performance" column
  const performanceBodyTemplate = (rowData: any) =>
    rowData.performanceYn === "Y" ? "O" : "X";

  // Custom body template for the "Total Area" column
  const totalAreaBodyTemplate = (rowData: any) => {
    const totalArea = rowData.totalArea;
    const fraction = Math.floor(totalArea) - Math.floor(totalArea * 10) / 10;
    const secondFraction = fraction < 4 ? fraction : Math.ceil(fraction);
    return Math.round(totalArea * 100) / 100;
  };

  const panelHeaderTemplate = (rowData: any) => {
    return <div className="hidden"></div>;
  };

  const titleBodyTemplate = (rowData: any) => {
    return (
      <a
        onClick={() => navigate(`/IACServiceBidDetail/${rowData.bidSeq}`)}
        className={'cursor-pointer text-blue-600'}
      >
        {rowData.title}
      </a>
    );
  };

  const onSort = (event: DataTableStateEvent) => {
    event.first = 0; // sorting 하면 항상 첫 페이지로 이동
    setTableParams(event);
  };
  const onPageChange = (event: DataTableStateEvent) => {
    setTableParams(event);
  };

  // Function to handle search filter form submission
  const handleSearchFilterSubmit = () => {
    const requestBody = {
      koreanProvinces: selectedRegions,
      areaLimit: selectedTotalArea || null,
      status: selectedStatus || null,
      searchText,
      pageNumber: tableParams.page ? tableParams.page + 1 : 1,
      pageSize: tableParams.rows,
      orderColumn: tableParams.sortField
        ? tableParams.sortField.split(/\.?(?=[A-Z])/).join('_').toUpperCase()
        : '',
      sortOrder: tableParams.sortOrder,
    };

    getServiceBidList(requestBody).then((data)=>{
      setBidListData(data.iacServiceBidList);
      setTotalCount(data.totalCount);
    });
  };

  useEffect(() => {
    handleSearchFilterSubmit();
  }, [tableParams]);

  // Fetch bid list data on component mount
  useEffect(() => {
    // 기존 검색조건 초기화
    setSelectedSortBy(null);
    setSearchText("");
    setSelectedRegions([]);
    setSelectedStatus(null);
    setSelectedTotalArea(null);

    const requestBody = {
      koreanProvinces: selectedRegions,
      areaLimit: selectedTotalArea || null,
      status: selectedStatus || null,
      searchText,
      pageNumber: tableParams.page ? tableParams.page + 1 : 1,
      pageSize: tableParams.rows,
      orderColumn: tableParams.sortField
        ? tableParams.sortField.split(/\.?(?=[A-Z])/).join('_').toUpperCase()
        : '',
      sortOrder: tableParams.sortOrder,
    };
    getServiceBidList(requestBody).then((data)=>{
      setBidListData(data.iacServiceBidList);
      setTotalCount(data.totalCount);
    });
  }, [state]);

  return (
    <div key={state}>
      {/* Header component */}
      <Header />
      <div className={'grid grid-cols-12 content'}>
        <div className={'col-span-2'}></div>
        <div className={'col-span-8'}>
          <section className="pt-10 min-h-screen">
            {/* Search and filter form */}
            <div>
              <h1 className="my-12 font-semibold text-3xl">입찰 공고</h1>
              <div>
                {/* Multi-select dropdown for regions */}
                <div className="grid grid-cols-12 gap-4 mb-10">
                  <div className="col-span-2">
                    <span className="block text-left font-bold">지역</span>
                    <MultiSelect
                      value={selectedRegions}
                      onChange={(e) => setSelectedRegions(e.value)}
                      options={RegionOptions}
                      optionLabel="name"
                      display="chip"
                      placeholder="Select Regions"
                      maxSelectedLabels={10}
                      className="mr-5 border ring-0 focus:border-primary checked:bg-primary w-full rounded-xl"
                      panelHeaderTemplate={panelHeaderTemplate}
                      showSelectAll={false}
                      scrollHeight={'400px'}
                    />
                  </div>

                  {/* Dropdown for status */}
                  <div className="col-span-2">
                    <span className="block text-left font-bold">공고상태</span>
                    <Dropdown
                      value={selectedStatus}
                      onChange={(e) => setSelectedStatus(e.value)}
                      options={StatusOptions}
                      optionLabel="name"
                      placeholder="Select Status"
                      className="border ring-0 focus:ring-2 focus:ring-primary focus:outline-none w-full rounded-xl"
                      showClear
                      scrollHeight={'400px'}
                    />
                  </div>

                  <div className="col-span-8">
                    {/* Search input */}
                    <span className="block text-left font-bold">키워드 검색</span>
                    <div className="grid grid-cols-4 gap-4">
                      <InputText
                        value={searchText}
                        type="search"
                        onChange={(e) => setSearchText(e.target.value)}
                        className="border py-[12px] w-full px-1.5 focus:ring-1 focus:ring-primary focus:outline-none rounded-xl col-span-3"
                        placeholder="키워드를 검색해 주세요..."
                      />
                      {/* Search button */}
                      <button
                        className="border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-xl px-8 h-12"
                        onClick={handleSearchFilterSubmit}
                      >
                        검색하기
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            {/* sort by & show results selection form */}
            <div className="w-full mb-2 mt-5 flex justify-between items-center">
              <div className="">
            <span className="font-medium">
              Total:{" "}
              <span className="text-primary text-[18px]">
                {totalCount}
              </span>
            </span>
              </div>
            </div>

            {/* DataTable component for displaying bid list data */}
            <DataTable
              value={bidListData}
              dataKey="bidSeq"
              resizableColumns
              removableSort
              paginator
              showGridlines
              emptyMessage="등록된 입찰공고가 없습니다."
              className="max-w-full rounded-xl"
              size={'small'}
              onSort={onSort}
              sortMode={'single'}
              sortField={tableParams.sortField}
              sortOrder={tableParams.sortOrder}
              columnResizeMode="expand"
              first={tableParams.first}
              rows={tableParams.rows}
              totalRecords={totalCount}
              rowsPerPageOptions={[10, 20, 50, 100]}
              onPage={onPageChange}
            >
              <Column field="bidSeq" header="번호" sortable/>
              <Column
                field="title"
                header="공고명"
                body={titleBodyTemplate}
                sortable
              />
              <Column field="province" header="지역" sortable/>
              <Column field="totalArea" body={totalAreaBodyTemplate} header="총 연면적 (m²)" sortable/>
              <Column field="createDate" body={createDateBodyTemplate} header="공고 등록일" sortable/>
              <Column field="dueDate" body={dueDateBodyTemplate} header="공고 마감일" sortable/>
              <Column field="status" header="상태"/>
              <Column body={maintenanceBodyTemplate} header="유지보수"/>
              <Column body={performanceBodyTemplate} header="성능점검"/>
            </DataTable>
          </section>
        </div>
        <div className={'col-span-2'}></div>
      </div>

      {/* Footer component */}
      <Footer/>
    </div>
  );
};

export default IACServiceBidList;
