import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {updatePassword} from "../../services/authoriedUserService";
import {useLoading} from "../../components/LoadingContext";
import AlertDialog from "../../components/AlertDialog";

function ChangePassword() {
  const navigate = useNavigate();
  const {setLoading} = useLoading();

  const [oldPassword, setOldPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const passwordOver8 = useRef(null);
  const passwordSpecialChar = useRef(null);
  const passwordNum = useRef(null);
  const passwordUpperCase = useRef(null);
  const passwordLowerCase = useRef(null);
  const passwordDiff = useRef(null);

  const oldPasswordRef = useRef(null);
  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordConfirmValid, setIsPasswordConfirmValid] = useState(false);

  const handleShowPasswordConfirm = async (e: any) => {
    const passwordConfirm = await passwordConfirmRef.current;
    if (passwordConfirm === null) return;
    await setShowPasswordConfirm(!showPasswordConfirm);
    if (!showPasswordConfirm) {
      // @ts-ignore
      passwordConfirm.type = "text";
    } else {
      // @ts-ignore
      passwordConfirm.type = "password";
    }
  };
  const handleShowPassword = async (e: any) => {
    const password = await passwordRef.current;
    if (password === null) return;

    await setShowPassword(!showPassword);
    if (!showPassword) {
      // @ts-ignore
      password.type = "text";
    } else {
      // @ts-ignore
      password.type = "password";
    }
  };

  const handleChangeOldPassword = (e: any) => {
    setOldPassword(e.target.value);
  };
  const handleShowOldPassword = async (e: any) => {
    const password = await oldPasswordRef.current;
    if (password === null) return;

    await setShowOldPassword(!showOldPassword);
    if (!showOldPassword) {
      // @ts-ignore
      password.type = "text";
    } else {
      // @ts-ignore
      password.type = "password";
    }
  };

  const passwordRuleCheck = (event: any) => {
    if (event.target.value.length > 40) {
      showAlert('비밀번호는 40자로 제한되어 있습니다.');
      return;
    }

    // 8자 이상, 숫자 포함, 소문자 포함, 대문자 포함, 특수문자 포함
    // console.log('Here is password ', event.target.value);
    const specialRule = /[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/g; // 특수문자 포함여부
    const numberRule = /[0-9]/g; // 숫자 포함여부
    const upperCaseRule = /[A-Z]/g; // 대문자 포함여부
    const lowerCaseRule = /[a-z]/g; // 소문자 포함여부

    let isPasswordOver8 = false;
    let isPasswordSpecialChar = false;
    let isPasswordNum = false;
    let isPasswordUpperCase = false;
    let isPasswordLowerCase = false;

    // 8자 이상
    if (event.target.value.length >= 8) {
      // @ts-ignore
      passwordOver8.current.className = "text-green-500";
      isPasswordOver8 = true;
    } else {
      // @ts-ignore
      passwordOver8.current.className = "text-red-500";
      isPasswordOver8 = false;
    }

    if (specialRule.test(event.target.value)) {
      // 특수문자가 포함되어 있으면
      // @ts-ignore
      passwordSpecialChar.current.className = "text-green-500";
      isPasswordSpecialChar = true;
    } else {
      // @ts-ignore
      passwordSpecialChar.current.className = "text-red-500";
      isPasswordSpecialChar = false;
    }

    if (numberRule.test(event.target.value)) {
      // 숫자가 포함되어 있으면
      // @ts-ignore
      passwordNum.current.className = "text-green-500";
      isPasswordNum = true;
    } else {
      // @ts-ignore
      passwordNum.current.className = "text-red-500";
      isPasswordNum = false;
    }

    if (upperCaseRule.test(event.target.value)) {
      // 대문자가 포함되어 있으면
      // @ts-ignore
      passwordUpperCase.current.className = "text-green-500";
      isPasswordUpperCase = true;
    } else {
      // @ts-ignore
      passwordUpperCase.current.className = "text-red-500";
      isPasswordUpperCase = false;
    }

    if (lowerCaseRule.test(event.target.value)) {
      // 소문자가 포함되어 있으면
      // @ts-ignore
      passwordLowerCase.current.className = "text-green-500";
      isPasswordLowerCase = true;
    } else {
      // @ts-ignore
      passwordLowerCase.current.className = "text-red-500";
      isPasswordLowerCase = false;
    }

    if (Object.is(passwordConfirm, event.target.value)) {
      // @ts-ignore
      passwordDiff.current.style.setProperty("display", "none");
      setIsPasswordConfirmValid(true);
    } else {
      // @ts-ignore
      passwordDiff.current.style.setProperty("display", "block");
      // @ts-ignore
      passwordDiff.current.className = "text-red-500";
      setIsPasswordConfirmValid(false);
    }

    if (isPasswordOver8
      && isPasswordSpecialChar
      && isPasswordNum
      && isPasswordUpperCase
      && isPasswordLowerCase
    ) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }

    setPassword(event.target.value);
  };

  const passwordConfirmCheck = (event: any) => {
    if (event.target.value.length > 40) {
      showAlert('비밀번호는 40자로 제한되어 있습니다.');
      return;
    }

    if (Object.is(password, event.target.value)) {
      // @ts-ignore
      passwordDiff.current.style.setProperty("display", "none");
      setIsPasswordConfirmValid(true);
    } else {
      // @ts-ignore
      passwordDiff.current.style.setProperty("display", "block");
      // @ts-ignore
      passwordDiff.current.className = "text-red-500";
      setIsPasswordConfirmValid(false);
    }
    setPasswordConfirm(event.target.value);
  };

  const changePassword = () => {
    const param ={
      oldPassword: oldPassword,
      newPassword: password,
      newPasswordConfirmation: passwordConfirm
    };

    setLoading(true);
    updatePassword(param).then((response)=>{
      console.log(response);
      if (response === 'password update success!!') {
        alert('비밀번호 변경이 완료되었습니다.');
        navigate('/myInfo');
      } else {
        showAlert("현재 비밀번호와 일치하지 않습니다. 다시 한번 확인해주세요.");
      }
    }).finally(()=>{
      setLoading(false);
    });
  };

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const showAlert = (message:string) => {
    setAlertMessage(message);
    setAlertVisible(true);
  };
  const hideAlert = () => {
    setAlertVisible(false);
  };

  return (
    <>
      <Header/>
      <div className="content text-left ">
        <div className="login-container max-w-md mx-auto py-32">
          <h1 className="p-2 font-semibold text-center mb-5">
            비밀번호 재설정
          </h1>
          <div className="space-y-5 w-auto md:w-[450px] mx-auto">
            <div className="mb-3">
              <label htmlFor="oldPassword" className="block mb-2 font-bold">
                현재 비밀번호
              </label>
              <div className="relative">
                <input
                  type={showOldPassword ? "text" : "password"}
                  id="oldPassword"
                  className="w-full p-3 border rounded-xl focus:outline-[#3F80EA] focus:ring-1"
                  onChange={handleChangeOldPassword}
                  value={oldPassword}
                  ref={oldPasswordRef}
                />
                <button
                  type="button"
                  className="absolute right-2 top-3.5 text-gray-500"
                  onClick={handleShowOldPassword}
                >
                  {showOldPassword ? (
                    <i className="pi pi-eye text-lg" />
                  ) : (
                    <i className="pi pi-eye-slash text-lg" />
                  )}
                </button>
              </div>
            </div>

            <div className="mb-3 mt-5">
              <label htmlFor="password" className="block mb-2 font-bold">
                변경 비밀번호
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="w-full p-3 border rounded-xl focus:outline-[#3F80EA] focus:ring-1"
                  onChange={passwordRuleCheck}
                  value={password}
                  ref={passwordRef}
                />
                <button
                  type="button"
                  className="absolute right-2 top-3.5 text-gray-500"
                  onClick={handleShowPassword}
                >
                  {showOldPassword ? (
                    <i className="pi pi-eye text-lg" />
                  ) : (
                    <i className="pi pi-eye-slash text-lg" />
                  )}
                </button>
              </div>
              <div className="small">
                <span ref={passwordOver8} className="text-red">
                  8자 이상 |{" "}
                </span>
                <span ref={passwordSpecialChar} className="text-red">
                  특수 문자 |{" "}
                </span>
                <span ref={passwordNum} className="text-red">
                  숫자 |{" "}
                </span>
                <span ref={passwordUpperCase} className="text-red">
                  대문자 |{" "}
                </span>
                <span ref={passwordLowerCase} className="text-red">
                  소문자
                </span>
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="passwordConfirm" className="block mb-2 font-bold">
                비밀번호 확인
              </label>
              <div className="relative">
                <input
                  type={showPasswordConfirm ? "text" : "password"}
                  id="passwordConfirm"
                  className="w-full p-3 border rounded-xl focus:outline-[#3F80EA] focus:ring-1"
                  onChange={passwordConfirmCheck}
                  value={passwordConfirm}
                  ref={passwordConfirmRef}
                />
                <button
                  type="button"
                  className="absolute right-2 top-3.5 text-gray-500"
                  onClick={handleShowPasswordConfirm}
                >
                  {showOldPassword ? (
                    <i className="pi pi-eye text-lg" />
                  ) : (
                    <i className="pi pi-eye-slash text-lg" />
                  )}
                </button>
              </div>
              <div ref={passwordDiff} className="hidden text-red-500 text-sm">비밀번호가 일치하지 않습니다.</div>

            </div>

            <div className="my-6">
              <button
                type="button"
                disabled={!oldPassword || !password || !passwordConfirm || password !== passwordConfirm || !isPasswordConfirmValid || !isPasswordValid}
                onClick={changePassword}
                className="shadow-lg w-full h-14 bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white py-2.5 rounded-xl disabled:bg-gray-500 disabled:from-gray-500 disabled:to-gray-500 disabled:border-none disabled:cursor-not-allowed"
              >
                비밀번호 변경
              </button>
            </div>
          </div>
        </div>
        <AlertDialog
          visible={alertVisible}
          onHide={hideAlert}
          message={alertMessage}
        />
      </div>
      <Footer />
    </>
  );
}

export default ChangePassword;
