import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useContext, useState } from "react";
import DaumPostcodeEmbed from "react-daum-postcode";
import { useNavigate } from "react-router-dom";
import { UserInfoContext } from "../../App";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import warning_badge from "../../images/svg/warning_badge.svg";
import {
  listBuildingInfoDongs,
  saveBuildingInfo,
  saveBuildingInfoList,
} from "../../services/serviceBidService";

interface Building {
  checkbox: boolean;
  buildingId: number;
  buildingName: string;
  address: string;
  totalArea: number;
  dongName: string;
  mainPurposeName: string;
  useApproveDay: string;
  createUser: string;
  businessName: string;
}

function MyBuilding() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  // @ts-ignore
  let { userInfo, logout } = useContext(UserInfoContext);

  const [buildingList, setBuildingList] = useState<Building[]>([]);
  const [selectedBuilding, setSelectedBuilding] = useState<Building[]>([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isBuildingExist, setIsBuildingExist] = useState(true);
  const [address, setAddress] = useState("");
  const [totalArea, setTotalArea] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [bubjungdongCode, setBubjungdongCode] = useState("");

  const totalAreaTemplate = (data: Building) => {
    return data.totalArea.toLocaleString() + " m²";
  };

  const useApproveDayTemplate = (data: Building) => {
    return (
      data.useApproveDay.slice(0, 4) +
      "." +
      data.useApproveDay.slice(4, 6) +
      "." +
      data.useApproveDay.slice(6)
    );
  };

  const getAddressData = (data: any) => {
    setBubjungdongCode(data.bcode);

    const param = {
      bubjungdongCode: data.bcode,
      jibunAddressEnglish: "",
      userId: userInfo.userId,
    };

    if (data.jibunAddressEnglish !== "") {
      param.jibunAddressEnglish = data.jibunAddressEnglish;
      setAddress(data.jibunAddress);
      handleClose();
    } else {
      alert('도로명 주소로는 표제부를 검색할 수 없습니다. 지번 주소를 클릭해주세요.');
      // 이전에 검색했던 주소 및 표제부 목록을 초기화 한다.
      setAddress("");
      setBuildingList([]);
      setBubjungdongCode("");
      handleClose();
      return;
    }

    setLoading(true);
    listBuildingInfoDongs(param).then((result) => {
      if (result.length === 0) {
        alert("건축물대장 데이터가 존재하지 않습니다.");
        setTotalArea("");
        setIsBuildingExist(false);
        return;
      } else {
        setIsBuildingExist(true);
      }
      for (const item of result) {
        item.checkbox = false;
      }
      setBuildingList(result);
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });
  };

  const saveBuilding = () => {
    if (window.confirm("저장하시겠습니까?")) {
      if (isBuildingExist) {
        if (!selectedBuilding) {
          alert("선택한 동이 없습니다.");
          return;
        } else {
          setLoading(true);
          const param = {
            buildingList: selectedBuilding,
            buildingName: buildingName
          }
          saveBuildingInfoList(param).then((result) => {
            if (result.status === 200) {
              alert("저장되었습니다.");
              navigate("/myBuildingList");
            } else {
              alert("오류가 발생했습니다. 관리자에게 문의하세요.");
            }
          }).catch((error)=>{
            console.log(error);
            if (error === '토큰 만료, 로그아웃 처리됨.') {
              alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
              logout();
            }
          }).finally(()=>{
            setLoading(false);
          });
        }
      } else {
        bubjungdongCode.substring(0, 5);
        const param = {
          address: address,
          buildingName: buildingName,
          businessName: userInfo.businessUser.businessName,
          totalArea: totalArea,
          sigunguCode: bubjungdongCode.substring(0, 5),
          bubjungdongCode: bubjungdongCode.substring(5, 10),
          createUser: userInfo.userId,
        };

        setLoading(true);
        saveBuildingInfo(param).then((result) => {
          if (result.status === 200) {
            alert("저장되었습니다.");
            navigate("/myBuildingList");
          } else {
            alert("오류가 발생했습니다. 관리자에게 문의하세요.");
          }
        }).catch((error)=>{
          console.log(error);
          if (error === '토큰 만료, 로그아웃 처리됨.') {
            alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
            logout();
          }
        }).finally(()=>{
          setLoading(false);
        });
      }
    }
  };

  return (
    <div>
      <Header></Header>
      <div className={'grid grid-cols-12 content'}>
        <div className={'col-span-2'}></div>
        <div className={'col-span-8'}>
          <div className="">
            <h1 className="pt-10 my-12 font-semibold text-3xl">새 건물등록</h1>

            <div className="flex w-full items-start gap-x-1 mt-10">
              <img src={warning_badge} alt="warning_badge" className="mt-0.5"/>
              <div>
                <div className="text-start text-[#FF4242] text-sm font-normal">
                  건물의 정보통신설비 유지보수 및 성능점검을 위해서는 건물 정보 및
                  설비현황 정보를 입력해야 합니다.
                </div>
                <div className="text-start text-[#FF4242] text-sm font-normal">
                  주소검색 후 국가교통부가 제공하는 OpenAPI 를 통해서 건축물대장
                  표제부를 검색합니다. 하나의 주소에 동(개별건물)이 여러개인 경우
                  표제부가 여러 건이 조회될 수 있습니다.
                </div>
              </div>
            </div>

            {
              step === 1
                ? <>
                  <div className="p-3 bg-[#F4F4F4] rounded-lg mb-6">
                    <div className="text-start text-base font-semibold mb-2">
                      주소 검색
                    </div>
                    <input
                      type="text"
                      name="address"
                      className="w-full h-10 px-3 py-3 border rounded-lg text-base font-semibold focus:outline-none focus:border-blue-500 transition duration-300"
                      placeholder="주소검색"
                      value={address}
                      readOnly={true}
                      onClick={handleShow}
                    />
                  </div>
                </>
                : <></>
            }

            {isBuildingExist ? (
              step === 1
                ? <>
                  <div>
                    <DataTable
                      value={buildingList}
                      selection={selectedBuilding!}
                      selectionMode={'checkbox'}
                      showGridlines
                      onSelectionChange={(e) => {
                        const selectedBuildings = e.value;
                        // @ts-ignore
                        selectedBuildings?.map((item: any) => {
                          item.createUser = userInfo.userId;
                          item.businessName = userInfo.businessUser.businessName;
                          return item;
                        });

                        setSelectedBuilding(selectedBuildings);
                      }}
                      // dataKey="buildingId"
                      emptyMessage="유지보수·관리 및 성능점검 대상 건물이 없습니다."
                      tableStyle={{minWidth: "50rem"}}
                      rowClassName={(data, options) => "font-semibold border text-sm"}
                      scrollable
                      loading={loading}
                      tableClassName="border-separate"
                    >
                      <Column
                        selectionMode="multiple"
                        headerStyle={{width: "3rem"}}
                        headerClassName=""
                        className=""
                      ></Column>
                      <Column field="buildingName" header="건물명"></Column>
                      <Column field="address" header="주소"></Column>
                      <Column
                        field="totalArea"
                        header="연면적"
                        bodyClassName="text-right"
                        className={'max-w-12'}
                        body={totalAreaTemplate}
                      ></Column>
                      <Column field="dongName" header="동이름"></Column>
                      <Column field="mainPurposeName" header="주용도"></Column>
                      <Column
                        field="useApproveDay"
                        header="사용승인일"
                        body={useApproveDayTemplate}
                        headerClassName=""
                        className=""
                      ></Column>
                    </DataTable>
                  </div>
                  <button
                    onClick={() => {
                      let totalAreaSum = 0;
                      for (let item of selectedBuilding) {
                        totalAreaSum += item.totalArea;
                      }
                      setTotalArea(totalAreaSum.toString());
                      setStep(2);
                    }}
                    disabled={selectedBuilding.length === 0}
                    className="mt-10 mb-20 bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] w-1/2  px-3 py-2.5 disabled:opacity-50 hover:opacity-90 duration-300 text-base font-semibold text-white rounded-xl"
                  >
                    표제부(건축물) 선택완료
                  </button>
                </>
                : <>
                  <div className={"text-left font-size-sm my-10"}>
                    <div className="flex flex-col gap-6 mt-10">
                      <div className="">
                        <div className="font-semibold">주소</div>
                        <input
                          type="text"
                          name="address"
                          value={address}
                          readOnly={true}
                          disabled={true}
                          className="w-full h-10 px-3 py-3 border rounded-lg text-base font-semibold focus:outline-none focus:border-blue-500 transition duration-300"
                        />
                      </div>
                      <div className="">
                        <div className="font-semibold">건물명<span className="text-red-500">*</span></div>
                        <input
                          type="text"
                          name="buildingName"
                          value={buildingName}
                          onChange={(e) => setBuildingName(e.target.value)}
                          className="w-full h-10 px-3 py-3 border rounded-lg text-base font-semibold focus:outline-none focus:border-blue-500 transition duration-300"
                        />
                      </div>
                      <div className="">
                        <div className="font-semibold">
                          연면적(m<sup>2</sup>)
                        </div>
                        <input
                          type="number"
                          name="totalArea"
                          value={totalArea}
                          readOnly={true}
                          disabled={true}
                          onChange={(e) => {
                            setTotalArea(e.target.value)
                          }}
                          className="w-full h-10 px-3 py-3 border rounded-lg text-base font-semibold focus:outline-none focus:border-blue-500 transition duration-300"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={'font-semibold text-start mb-2'}>
                      선택한 표제부 목록
                    </div>
                    <DataTable
                      value={selectedBuilding}
                      // dataKey="buildingId"
                      emptyMessage="선택한 건물이 없습니다."
                      tableStyle={{minWidth: "50rem"}}
                      rowClassName={(data, options) => "font-semibold border text-sm"}
                      scrollable
                      showGridlines
                      loading={loading}
                      tableClassName="border-separate"
                    >
                      <Column field="buildingName" header="건물명"></Column>
                      <Column field="address" header="주소"></Column>
                      <Column
                        field="totalArea"
                        header="연면적"
                        bodyClassName={'text-right'}
                        body={totalAreaTemplate}
                      ></Column>
                      <Column field="dongName" header="동이름"></Column>
                      <Column field="mainPurposeName" header="주용도"></Column>
                      <Column
                        field="useApproveDay"
                        header="사용승인일"
                        body={useApproveDayTemplate}
                        headerClassName=""
                        className=""
                      ></Column>
                    </DataTable>
                  </div>
                  <div className={'flex gap-6'}>
                    <button
                      onClick={() => {
                        setStep(1);
                        setSelectedBuilding([]); // 선택한 건물목록을 초기화
                      }}
                      className="mt-10 mb-20 border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-xl w-1/2"
                    >다시검색
                    </button>

                    <button
                      disabled={buildingName === ""}
                      onClick={saveBuilding}
                      className="mt-10 mb-20 bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] w-1/2  px-3 py-2.5 disabled:opacity-50 hover:opacity-90 duration-300 text-base font-semibold text-white rounded-xl"
                    >
                      저장하기
                    </button>
                  </div>
                </>
            ) : (
              <>
                <div className={"text-left font-size-sm my-10"}>
                  <p className={'text-[#FF4242]'}>
                    국가교통부 건축물대장 표제부 검색 결과가 없습니다. 건물 정보를
                    직접 입력해야 합니다.
                  </p>
                  <div className="flex flex-col gap-6 mt-10">
                    <div className="">
                      <div className="font-semibold">주소</div>
                      <input
                        type="text"
                        name="address"
                        value={address}
                        readOnly={true}
                        disabled={true}
                        className="w-full h-10 px-3 py-3 border rounded-lg text-base font-semibold focus:outline-none focus:border-blue-500 transition duration-300"
                      />
                    </div>
                    <div className="">
                      <div className="font-semibold">건물명</div>
                      <input
                        type="text"
                        name="buildingName"
                        value={buildingName}
                        onChange={(e) => setBuildingName(e.target.value)}
                        className="w-full h-10 px-3 py-3 border rounded-lg text-base font-semibold focus:outline-none focus:border-blue-500 transition duration-300"
                      />
                    </div>
                    <div className="">
                      <div className="font-semibold">
                        연면적(m<sup>2</sup>)
                      </div>
                      <input
                        type="number"
                        name="totalArea"
                        value={totalArea}
                        onChange={(e) => setTotalArea(e.target.value)}
                        className="w-full h-10 px-3 py-3 border rounded-lg text-base font-semibold focus:outline-none focus:border-blue-500 transition duration-300"
                      />
                    </div>
                  </div>
                  <div className={'flex justify-center'}>
                    <button
                      onClick={saveBuilding}
                      className="mt-12 mb-20 bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] w-1/2  px-3 py-2.5 disabled:opacity-50 hover:opacity-90 duration-300 text-base font-semibold text-white rounded-xl"
                    >
                      저장하기
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={'col-span-2'}></div>
      </div>

      <Dialog
        header="주소 검색"
        visible={show}
        style={{width: "50vw"}}
        onHide={handleClose}
      >
        <DaumPostcodeEmbed
          onComplete={getAddressData}
          hideMapBtn={true}
          hideEngBtn={true}
          shorthand={false}
          autoMapping={false}
        />
      </Dialog>

      <Footer></Footer>
    </div>
  );
}

export default MyBuilding;
