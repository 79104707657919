import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import { UserInfoContext } from "../../App";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {
  getMyBuildingList,
  getOneIACQuotation,
} from "../../services/serviceBidService";
import {getUser} from "../../services/authService";
import {KRWon} from "../../services/utils/formatUtil";
import {Accordion, AccordionTab} from "primereact/accordion";
import warning_badge_blue from "../../images/svg/warning_badge_blue.svg";
import {downloadBUFile, getBusinessUserFiles} from "../../services/authoriedUserService";

interface Quotation {
  seq: number,
  status: string,
  userId: string, // 견적사
  price: number,
  createUser: string,
}
interface Building {
  buildingId: number;
  buildingName: string;
  address: string;
  totalArea: number;
  quotationStatus: string,
}
interface Quotationer {
  userId: string,
  name: string,
  phone: string,
  email: string
}

function QuotationDetail() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [quotation, setQuotation] = useState<Quotation>();
  const [buildingList, setBuildingList] = useState<Building[]>([]);
  const [quotationer, setQuotationer] = useState<Quotationer>();
  const { quotationSeq } = useParams();

  const totalAreaTemplate = (data: Building) => {
    return data.totalArea.toLocaleString() + "m²";
  };
  const quotationPriceSample = [
    {area: "1,000m²이상 ~ 3,000m²미만", price: 1000000}
    , {area: "3,000m²이상 ~ 5,000m²미만", price: 1500000}
    , {area: "5,000m²이상 ~ 10,000m²미만", price: 2000000}
    , {area: "10,000m²이상 ~ 20,000m²미만", price: 2500000}
    , {area: "20,000m²이상 ~ 30,000m²미만", price: 3000000}
    , {area: "30,000m²이상", price: 3500000}
  ];
  const sumTotalArea = () => {
    let sum = 0;
    if (buildingList.length > 0) {
      buildingList.forEach((item)=>{
        sum += item.totalArea;
      })
    }
    return sum;
  };

  const [bankAccFile, setBankAccFile] = useState({
    userId: "",
    fileName: "",
    fileUrl: "",
    fileSize: "",
    seq: 0,
    fileType: "",
    fileTypeName: "",
  });

  // @ts-ignore
  let { userInfo, logout } = useContext(UserInfoContext);

  const downloadFile = (file: any) => {
    console.log(file);
    downloadBUFile(file.seq, file.fileName).then();
  };

  useEffect(() => {
    const param = {
      userId: userInfo.userId,
      quotationSeq: quotationSeq
    }
    setLoading(true);
    getOneIACQuotation(param).then((result) => {
      if (!result) {
        alert('견적 정보를 조회할 수 없습니다.');
        navigate('/myBuildingList');
      }
      setQuotation(result);
      getUser(result?.userId).then((response)=>{
        setQuotationer(response);
      });

    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });

    getMyBuildingList(param).then((result)=>{
      setBuildingList(result);
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    });

    getBusinessUserFiles(userInfo.userId).then((result)=>{
      for (const file of result) {
        if (file.fileType === 'BankAccFile') {
          setBankAccFile(file);
        }
      }
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    });

  }, [quotationSeq]);

  return (
    <div>
      <Header></Header>
      <div className={'grid grid-cols-12 content'}>
        <div className={'col-span-2'}></div>
        <div className={'col-span-8'}>
          <div className="pb-20">
            <h1 className="pt-10 my-12 font-semibold text-3xl">견적 상세</h1>
            <div>
              <div className="text-lg text-start font-semibold pt-10 pb-3">
                견적 정보
              </div>

              <div className="grid grid-cols-2 gap-x-10 gap-y-3">
                <div className="flex w-full gap-x-5">
                  <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                    견적번호
                  </div>
                  <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                    {quotation?.seq}
                  </div>
                </div>

                <div>
                  <div className="flex w-full gap-x-5">
                    <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                      견적 상태
                    </div>
                    <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                      {quotation?.status}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Accordion className={'mt-10'}>
              <AccordionTab header={'견적비'} className={'text-start'}>
                <div className={'grid grid-cols-2 gap-4'}>
                  <div>
                    <div className="flex w-full gap-x-5">
                      <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white text-center">
                        견적비
                      </div>
                      <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                        {
                          quotation
                            ? KRWon.format(quotation?.price)
                            : 0
                        }
                      </div>
                    </div>
                  </div>

                  <div className={''}>
                    <Accordion>
                      <AccordionTab header="견적비 조견표 확인">
                        <DataTable
                          value={quotationPriceSample}
                          rowClassName={(data, options) => "border text-sm"}
                          scrollable
                          resizableColumns
                          loading={loading}
                          size={'small'}
                          columnResizeMode="expand"
                          tableClassName="border-separate"
                        >
                          <Column
                            field="area"
                            header="면적"
                            style={{minWidth: "12rem"}}
                            headerClassName=""
                            className=""
                          ></Column>
                          <Column
                            field="price"
                            header="견적비용"
                            style={{minWidth: "12rem"}}
                            headerClassName=""
                            className=""
                            body={(item) => {
                              return KRWon.format(item.price)
                            }}
                          ></Column>
                        </DataTable>
                      </AccordionTab>
                    </Accordion>
                  </div>
                </div>

                <div className={'mt-6'}>
                  <div className={'text-lg font-semibold text-start'}>무통장 입금 계좌 안내</div>
                  <div
                    className={'mt-3 grid grid-cols-2 border border-[#F5F5F5] rounded-xl gap-3 p-6 text-start bg-white'}>
                    <div className={'grid grid-cols-3'}>
                      <span className={''}>입금하실 은행</span>
                      <span className={'ms-4 text-blue-500 font-semibold col-span-2'}>신한은행</span>
                    </div>
                    <div className={'grid grid-cols-3'}>
                      <span>예금주명</span>
                      <span className={'ms-4 text-blue-500 font-semibold col-span-2'}>베타점검</span>
                    </div>
                    <div className={'grid grid-cols-3'}>
                      <span>계좌번호</span>
                      <span className={'ms-4 text-blue-500 font-semibold col-span-2'}>1785264200589</span>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="flex items-center gap-6 pt-10 pb-3">
                    <div className="text-lg text-start font-semibold ">
                      등록된 계좌 정보
                    </div>
                    <div className="flex gap-x-1">
                      <img
                        src={warning_badge_blue}
                        alt="warning_badge_blue"
                        className="mt-0.5"
                      />
                      <div className="text-start text-[#757575] text-sm font-normal">
                        가입시 등록한 정보로 입금이 확인됩니다. 등록된 통장과
                        예금주명을 사용하여 입금 부탁드립니다.
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-3 bg-white p-6 rounded-xl border border-[#F5F5F5]">
                    <div className="flex w-full gap-x-3 text-start">
                      <div className="w-20 p-3 text-sm text-end font-thin text-[#9E9E9E]">
                        예금주명
                      </div>
                      <div className="w-3/4 text-start text-base font-semibold p-3 border border-[#F5F5F5] rounded-lg">
                        {userInfo.businessUser?.bankAccOwner}
                      </div>
                    </div>

                    <div className="flex w-full gap-x-3 text-start">
                      <div className="w-20 p-3 text-sm text-end font-thin text-[#9E9E9E]">
                        계좌번호
                      </div>
                      <div className="w-3/4 text-start text-base font-semibold p-3 border border-[#F5F5F5] rounded-lg">
                        {userInfo.businessUser?.bankAccNumber}
                      </div>
                    </div>

                    <div className="flex w-full gap-x-3 text-start">
                      <div className="w-20 p-3 text-sm text-end font-thin text-[#9E9E9E]">
                        은행
                      </div>
                      <div className="w-3/4 text-start text-base font-semibold p-3 border border-[#F5F5F5] rounded-lg">
                        {userInfo.businessUser?.bankName}
                      </div>
                    </div>

                    <div className="flex w-full gap-x-3 text-start">
                      <div className="w-20 p-3 text-sm text-end font-thin text-[#9E9E9E]">
                        통장사본
                      </div>
                      <div className="w-3/4 text-start text-base font-semibold p-3">
                        <button
                          onClick={() => {
                            downloadFile(bankAccFile);
                          }}
                          type="button"
                          className="px-3 bg-white rounded-2xl border border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white hover:border-transparent font-semibold"
                        >
                          파일 다운로드
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionTab>
            </Accordion>

            <div>
              <div className="text-lg text-start font-semibold pt-10 pb-3">
                견적사 정보
              </div>

              <div className="grid grid-cols-2 grid-rows-2 gap-x-10 gap-y-3">
                <div className="flex w-full gap-x-5">
                  <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                    견적사 ID
                  </div>
                  <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                    {quotationer?.userId}
                  </div>
                </div>

                <div>
                  <div className="flex w-full gap-x-5">
                    <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                      이름
                    </div>
                    <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                      {quotationer?.name}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="flex w-full gap-x-5">
                    <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                      전화번호
                    </div>
                    <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                      {quotationer?.phone}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex w-full gap-x-5">
                    <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                      이메일
                    </div>
                    <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                      {quotationer?.email}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="text-lg text-start pt-10 pb-3 flex justify-between">
                <span className={'font-semibold'}>건물 목록</span>
                <span>
              총 연면적:
              <span className={'font-semibold mx-2'}>
                {sumTotalArea() + "m²"}
              </span>
            </span>
              </div>
              <DataTable
                value={buildingList}
                dataKey="buildingId"
                emptyMessage="대상 건물이 없습니다."
                tableStyle={{minWidth: "50rem"}}
                rowClassName={(data, options) => "border text-sm"}
                scrollable
                resizableColumns
                loading={loading}
                columnResizeMode="expand"
                tableClassName="border-separate"
              >
                <Column
                  field="buildingId"
                  header="건물ID"
                  sortable
                  style={{minWidth: "12rem"}}
                  headerClassName=""
                  className=""
                ></Column>
                <Column
                  field="buildingName"
                  sortable
                  header="건물명"
                  headerClassName=""
                  className=""
                ></Column>
                <Column
                  field="address"
                  sortable
                  header="주소"
                  body={(rowData) => {
                    return <span className={'text-blue-600 cursor-pointer'} onClick={() => {
                      navigate("/buildingDetail/" + rowData.buildingId);
                    }}>{rowData.address}</span>
                  }}
                >
                </Column>
                <Column
                  field="totalArea"
                  header="연면적"
                  sortable
                  body={totalAreaTemplate}
                  headerClassName=""
                  className=""
                ></Column>
                <Column
                  field="quotationStatus"
                  header="견적 상태"
                  sortable
                  headerClassName=""
                  className=""
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
        <div className={'col-span-2'}></div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default QuotationDetail;
