export interface Option {
  name: string;
  value: string;
}

export const RegionOptions: Option[] = [
  { name: "서울특별시", value: "서울특별시" },
  { name: "경기도", value: "경기도" },
  { name: "경상남도", value: "경상남도" },
  { name: "경상북도", value: "경상북도" },
  { name: "전라남도", value: "전라남도" },
  { name: "전북특별자치도", value: "전북특별자치도" },
  { name: "충청북도", value: "충청북도" },
  { name: "충청남도", value: "충청남도" },
  { name: "강원특별자치도", value: "강원특별자치도" },
  { name: "인천광역시", value: "인천광역시" },
  { name: "광주광역시", value: "광주광역시" },
  { name: "대구광역시", value: "대구광역시" },
  { name: "대전광역시", value: "대전광역시" },
  { name: "부산광역시", value: "부산광역시" },
  { name: "울산광역시", value: "울산광역시" },
  { name: "세종특별자치시", value: "세종특별자치시" },
  { name: "제주특별자치도", value: "제주특별자치도" },
];

export const RangeOptions: Option[] = [
  { name: "전체", value: "total" },
  { name: "3000m² 미만", value: "3000" },
  { name: "5000m² 미만", value: "5000" },
  { name: "10000m² 미만", value: "10000" },
  { name: "20000m² 미만", value: "20000" },
  { name: "30000m² 미만", value: "30000" },
  { name: "30000m² 이상", value: "over30000" },
];

export const StatusOptions: Option[] = [
  { name: "입찰발주", value: "ANNOUNCED" },
  { name: "투찰마감", value: "ANNOUNCE_ENDED" },
  { name: "협상중", value: "SELECTING" },
  { name: "낙찰", value: "SELECTED" },
  { name: "종료", value: "CLOSED" },
];
