import React from "react";

function CommonTermsOfUse() {
  return (
    <div className="content spacing text-left terms-of-use-text">
      <h3 className="text-2xl">이용약관</h3>

      <h5 className="mt-3 mb-2 text-xl">제 1 조 목적</h5>
      <p className="text-base">
        이 약관은 베타점검(이하“회사”)이 관리ㆍ운영하는 베타점검
        전자입찰시스템 이용에 관한 사항을 규정함을 목적으로 합니다.
      </p>
      <h5 className="mt-3 mb-2 text-xl">제 2 조 서비스의 내용 및 제공</h5>
      <ol data-list-style-type="ko">
        <li>
          회사는 정보통신설비 유지보수 관리 의무 규정(제8조) 및 정보통신설비
          성능점검 의무 규정(제10조)에 의거하여 매년 관리주체가 유지보수관리
          및 성능점검 위탁 용역 업체를 구매하기 위한 전자입찰 서비스를
          제공하고 있습니다.
          <ol>
            <li>
              점검대상 정보통신설비에 대하여 제6조 각 호에 따른 정보통신설비
              유지보수·관리 및 성능점검 대상 현황표 및 정보통신설비 성능점검
              시 검토사항 등을 참고하여 유지보수 관리 및 성능점검을
              수행합니다.
            </li>
          </ol>
        </li>
        <li>
          회사에서 제공하는 전자 입찰 서비스는 다음과 같습니다.
          <ol>
            <li>
              회사에서 거래되거나 거래될 수 있는 용역에 대한 정보의 제공
            </li>
            <li>용역의 계약 체결 업무</li>
          </ol>
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 3 조 용어의 정의</h5>
      <ol data-list-style-type="ko">
        <li>
          “회사”란 베타점검이 회원들 간의 용역을 판매하고 관련 부가서비스를
          제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 설정한 가상의
          거래소를 말하며, 아울러 그러한 거래소를 운영하는 사업자의 의미로도
          사용됩니다.
        </li>
        <li>
          거래소에서 거래되는 “발주”라 함은 회사를 통하여 용역의 구매행위를
          말하고, “투찰”이라 함은 회사를 통하여 용역의 판매행위를 말합니다.
        </li>
        <li>
          “회원”이란 회사가 정한 절차에 따라 소정 양식을 제출하고 회사의
          회원으로 등록함으로써 회사와 회원 사이에 서비스이용계약을 체결한
          자를 말합니다.
        </li>
        <li>
          회사의 회원으로 가입을 희망하는 자는 본 약관에 동의한다는 의사를
          표시하고, 회사가 정한 가입신청서 양식에 따라 회원정보 등을 기입하면
          준회원으로 가입이 됩니다.
          <ol>
            <li>
              "준회원"이란 발주 및 투찰 행위는 할 수 없으며, 회사에서 제공하는
              정보만을 검색할 수 있습니다.
            </li>
            <li>
              준회원이 발주 및 투찰 행위를 원할 경우, 일반회원으로 추가
              신청해야 제 5조의 해당 서류를 제출함으로써 가입을 신청할 수
              있습니다.
            </li>
            <li>
              일반회원은 "개인회원" 및 "기업회원"으로 나뉘며, “개인회원”이라
              함은 회사를 통하여 용역의 구매 및 판매행위를 하는 개인
              사업자이며, “기업회원”이라 함은 회사를 통하여 용역의 구매 및
              판매행위를 하는 법인 사업자를 말합니다.
            </li>
            <li>
              회사를 통해 구매행위를 하는 자를 발주회원, 판매행위를 하는 자를
              투찰회원이라고 말합니다.
            </li>
            <li>
              투찰회원의 경우 추가로 제 5조의 해당 서류를 제출하여야 합니다.
            </li>
          </ol>
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 4 조 약관의 명시 및 변경</h5>
      <ol data-list-style-type="ko">
        <li>
          본 약관은 회사 서비스화면(www.beta-check.com)에 게시되며, 회사와
          회원 간의 서비스 이용계약의 일부로 됩니다.
        </li>
        <li>
          회사는 필요한 경우 약관의 규제에 관한 법률, 전자거래기본법,
          전자서명법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 방문판매
          등에 관한 법률, 소비자보호법 등을 위배하지 않는 범위에서 본 약관을
          개정할 수 있습니다. 개정약관은 적용일자 및 개정사유를 밝혀 기존
          약관과 함께 회사 서비스 화면에 적용일 7일 전부터 게시함으로써 그
          적용일에 효력을 발생합니다.
        </li>
        <li>
          개정약관에 이의가 있는 회원은 회사에 의견을 제출할 수 있으나,
          개정약관을 적용받지 않기 위해서는 개정약관의 적용일 이전까지
          회사와의 서비스 이용계약을 해지하여야 합니다.
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 5 조 회원가입</h5>
      <ol data-list-style-type="ko">
        <li>
          회사의 투찰회원으로 가입을 희망하는 자는 최초 가입 시, 혹은 준회원
          상태에서 다음과 같은 서류를 제출해야 합니다.
          <ol>
            <li>
              개인회원(개인 사업자)인 경우 (일반)사업자등록증 사본,
              정보통신기술자 특급 자격증 사본
            </li>
            <li>
              기업회원(법인 사업자)인 경우 (법인)사업자등록증 사본,
              공사업등록증 사본
            </li>
            <li>
              개인 및 기업은 회원은 하나의 계정으로만 생성 및 활동 할 수
              있습니다.
            </li>
            <li>회사는 필요에 따라 추가서류를 요구할 수 있습니다.</li>
          </ol>
        </li>
        <li>
          회사는 회원가입을 신청한 자가 다음 각 호에 해당하지 않는 한 회원으로
          등록합니다.
          <ol>
            <li>
              가입 신청자가 본 약관 제11조 나.항 각 호에 의거하여 이전에
              회원자격을 상실한 적이 있는 경우. 다만, 회원자격 상실 후 1년이
              경과한 자로서 회사의 회원 재가입 승낙을 받은 자는 예외로 합니다.
            </li>
            <li>등록내용에 허위, 기재누락 또는 오기가 있는 경우.</li>
            <li>
              기타(?, 정지 or 좀더 좋은 의미?) 회원으로 변경되는 경우는 회사의
              업무성격상 적절하지 못하다고 판단되거나 기술상 현저한 지장이
              있다고 판단되는 경우.
            </li>
          </ol>
        </li>
        <li>
          개인/기업회원은 준회원 상태 혹은 최초 가입시 회사에 제출한 서류에
          대하여 승인 통보를 받은 시점에서 일반 투찰 회원자격을 부여 받습니다.
        </li>
        <li>
          회원은 등록한 회원정보 등에 변경이 있는 경우 즉시 우편 또는 기타
          방법으로 회사에 관련서류를 제출하고, 등록정보를 수정하여야 합니다.
          회원이 이러한 변경사항을 수정등록 하지 아니하여 발생한 손해에 대한
          책임은 당해 회원에게 있으며, 회사는 이에 대하여 전혀 책임을 지지
          않습니다.
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 6 조 회원정보의 변경</h5>
      <ol data-list-style-type="ko">
        <li>
          회원은 회원가입 시 등록사항에 변경이 있는 경우, 즉시 회원정보 관리
          화면에서 변경 또는 전자우편을 이용 회사에 그 변경사항을 알려야
          합니다.
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 7 조 게시물의 관리</h5>
      <ol data-list-style-type="ko">
        <li>
          회원의 게시물이 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하
          ‘정보통신망법’)및 저작권법 등 관련 법령에 위반되는 내용을 포함하는
          경우, 권리자는 회사에 관련 법령이 정한 절차에 따라 해당 게시물의
          게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련 법령에 따라
          조치를 취합니다.
        </li>
        <li>
          회사는 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가
          있거나 기타 회사의 정책 및 관련 법령에 위반되는 경우에는 관련 법령에
          따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.
        </li>
        <li>
          위와 관련된 세부 절차는 정보통신망법 및 저작권법이 규정한 범위
          내에서 회사가 정한 권리침해 신고 절차에 따릅니다.
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-2xl">
        제 8 조 서비스 이용의 제한 및 중지
      </h5>
      <ol data-list-style-type="ko">
        <li>
          회사는 아래 각 호의 어느 사항에도 해당하는 사유가 발생한 경우에는
          회원의 서비스 이용을 제한하거나 중지시킬 수 있습니다.
          <ol>
            <li>회원이 제9조의 의무를 위반한 경우</li>
            <li>회원이 제9조 나항의 의무를 위반한 경우</li>
            <li>
              전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를
              중지했을 경우
            </li>
            <li>
              국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로
              서비스 이용에 지장이 있는 때
            </li>
          </ol>
        </li>

        <li>
          회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는
          그 사유 및 제한기간등을 회원에게 알려야 합니다.
        </li>
        <li>
          제15조 나항에 의해 회사가 회원과의 계약을 해지하고 탈퇴시키기로
          결정한 경우 회사는 회원의 탈퇴 처리 전에 이를 통지하고, 회원은
          회사의 통지를 받은 날로부터 30일 이내에 이에 대한 항변의 기회를
          가집니다.
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 9 조 회사의 의무</h5>
      <ol data-list-style-type="ko">
        <li>
          회사는 회사의 서비스 제공 및 보안과 관련된 설비를 지속적이고
          안정적인 서비스 제공에 적합하도록 유지, 점검 또는 복구 등의 조치를
          성실히 이행하여야 합니다.
        </li>
        <li>
          회사는 회원이 수신 동의를 하지 않은 영리 목적의 광고성 전자우편,
          문자메시지 등을 발송하지 아니합니다.
        </li>
        <li>
          회사는 서비스의 제공과 관련하여 알게 된 회원의 개인정보를 본인의
          승낙 없이 제3자에게 누설, 배포하지 않고, 이를 보호하기 위하여
          노력합니다. 회원의 개인정보보호에 관한 기타의 사항은 정보통신망법 및
          회사가 별도로 정한 “개인정보관리지침”에 따릅니다.
        </li>
        <li>
          회사가 제3자와의 서비스 제공 계약 등을 체결하여 회원에게 서비스를
          제공하는 경우 회사는 각 개별서비스에서 서비스의 제공을 위하여
          제3자에게 제공되는 회원의 구체적인 회원정보를 명시하고 회원의
          개별적이고 명시적인 동의를 받은 후 동의의 범위 내에서 해당 서비스의
          제공 기간 동안에 한하여 회원의 개인정보를 제3자와 공유하는 등 관련
          법령을 준수합니다.
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 10 조 회원의 의무</h5>
      <ol data-list-style-type="ko">
        <li>
          사용자 ID와 비밀번호에 관한 모든 관리책임은 당해 회원에게 있습니다.
          <ol>
            <li>
              회원은 사용자 ID 및 비밀번호를 타인에게 양도하거나 사용, 승낙할
              수 없습니다. 회사는 회원에 의한 사용자ID 또는 비밀번호의 양도,
              유출, 사용승낙으로 인한 어떠한 손실이나 손해에 대해서도 책임을
              지지 않습니다.
            </li>
          </ol>
        </li>
        <li>
          회원은 아래 각각 어느 호에도 해당하는 행위를 하여서는 아니 됩니다.
          <ol>
            <li>회원 가입 신청 또는 변경 시 허위 내용을 등록하는 행위</li>
            <li>
              회사가 제공하는 서비스에 허위 내용을 등록하거나 게시하는 행위
            </li>
            <li>
              회사의 서비스에 게시된 정보를 변경하거나 서비스를 이용하여 얻은
              정보를 회사의 사전 승낙 없이 영리 또는 비영리의 목적으로 복제,
              출판, 방송 등에 사용하거나 제3자에게 제공하는 행위
            </li>
            <li>
              회사가 제공하는 서비스를 이용하여 제3자에게 본인을 홍보할 기회를
              제공 하거나 제3자의 홍보를 대행하는 등의 방법으로 금전을
              수수하거나 서비스를 이용할 권리를 양도하고 이를 대가로 금전을
              수수하는 행위
            </li>
            <li>
              회사 기타 제3자에 대한 허위의 사실을 게재하거나 지적재산권을
              침해하는 등 회사나 제3자의 권리를 침해하는 행위
            </li>
            <li>
              다른 회원의 ID 및 비밀번호를 도용하여 부당하게 서비스를 이용하는
              행위
            </li>
            <li>
              정보통신망법 등 관련 법령에 의하여 그 전송 또는 게시가 금지되는
              정보(컴퓨터 프로그램 등)를 전송하거나 게시하는 행위
            </li>
            <li>
              청소년보호법에서 규정하는 청소년유해매체물을 게시하는 행위
            </li>
            <li>
              공공질서 또는 미풍양속에 위배되는 내용의 정보, 문장, 도형, 음성
              등을 유포하는 행위
            </li>
            <li>
              회사의 직원이나 서비스의 관리자를 가장하거나 사칭하여 또는
              타인의 명의를 모용하여 글을 게시하거나 메일을 발송하는 행위
            </li>
            <li>
              컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을
              방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른
              컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하는 행위
            </li>
            <li>
              스토킹(stalking), 욕설, 채팅글 도배 등 다른 회원의 서비스 이용을
              방해하는 행위
            </li>
            <li>
              다른 회원의 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위
            </li>
            <li>
              불특정 다수의 회원을 대상으로 하여 광고 또는 선전을 게시하는
              행위
            </li>
          </ol>
        </li>
        <li>
          회사는 회원이 제가항 및 제나항을 위배하는 경우 해당 게시물 등을 삭제
          또는 임시 삭제할 수 있고 서비스의 이용을 제한하거나 일방적으로 본
          계약을 해지할 수 있습니다.
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 11 조 회원탈퇴 및 자격의 상실</h5>
      <ol data-list-style-type="ko">
        <li>
          회원은 회사에 대하여 탈퇴를 요청할 수 있으며, 회사는 즉시 회원탈퇴를
          처리합니다. 이 경우, 회원은 전자우편 또는 기타 방법으로
          회원탈퇴신청서를 작성하여 보내주셔야 하며, 사용자ID 및 비밀번호를
          밝혀야 합니다. 다만, 회원탈퇴는 이미 성립된 거래에 대해서는 영향이
          없습니다.
          <ol>
            <li>
              회원 탈퇴가 이루어짐과 동시에 서비스 이용과 관련된 모든 정보는
              삭제됩니다. 이로 인해 발생하는 불이익에 대한 책임은 회원
              본인에게 있습니다.
            </li>
          </ol>
        </li>
        <li>
          회원이 다음 각 호의 사유에 해당하는 경우 회사는 당해 회원의
          회원자격을 제한 또는 상실시킬 수 있습니다. 다만, 이 경우 회사는 당해
          회원에게 회원자격상실의 사유를 밝혀 통지하고 그에 대한 의견진술의
          기회를 부여합니다. 회사가 회원자격을 상실시키는 경우에는 그
          회원등록을 말소합니다.
          <ol>
            <li>
              가입신청서의 기재내용의 허위등록 및 위조서류의 등록이 확인된
              경우.
            </li>
            <li>
              회사 서비스를 통해 회원 스스로 결정한 의사결정에 대하여 정당한
              사유 없이 책임지지 아니한 경우.
            </li>
            <li>회사의 서비스 운영을 방해한 경우.</li>
            <li>
              회사를 이용하여 법령, 본 약관 또는 공서양속에 반하는 행위를 하는
              경우.
            </li>
            <li>
              회사를 통하여 획득한 회원사 및 회사의 정보 및 자료 등을 회사
              업무외의 목적으로 이용하거나 부당하게 제3자에게 유출한 경우.
            </li>
            <li>
              실질적으로 회사를 운영하지 아니하고 서류상으로만 존재하는 회사로
              확인되는 경우.
            </li>
            <li>
              독점규제 및 공정거래에 관한 법률 제19조 제1항 제7호, 제8호를
              위반한 경우.
            </li>
            <li>
              동일한 입찰건에 대하여 동일 PC에서 2개 이상의 업체가 입찰서를
              제출한 경우.
            </li>
            <li>
              정당한 사유없이 낙찰수수료를 납부일로부터 1개월이 경과한 후에도
              납부하지 아니한 경우.
            </li>
            <li>
              운영자 또는 제3자의 저작권 등 지적재산권의 침해를 하는 경우.
            </li>
            <li>
              관계법령에 의하여 영업정지 등의 제재처분을 받은 사실이 확인된
              경우.
            </li>
            <li>
              기타 전자거래질서의 확립을 위하여 회원으로서의 자격을 지속시키는
              것이 부적절하다고 판단되는 경우.
            </li>
          </ol>
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 12 조 발주</h5>
      <ol data-list-style-type="ko">
        <li>
          회사 서비스를 이용하여 용역을 구매하고자 하는 회원은 회사가 제공하는
          등록양식에 따라 판매등록을 해야 합니다.
        </li>
        <li>
          발주요청 시 허위등록 또는 오기로 인한 책임은 전적으로 투찰회원이
          부담합니다.
        </li>
        <li>
          발주 요청된 용역에 대한 발주취소는 회사가 정한 절차에 따라야 합니다.
        </li>
        <li>
          발주회원이 정당한 사유 없이 판매를 거부하거나, 회사가 발주한 물품
          공사 용역의 제공을 거부하거나 지연하는 경우 및 정당한 반품 등을
          거부하는 경우, 회사는 약관 제11조 나.항에 의거하여 서비스 신뢰도
          또는 전자상거래질서의 훼손 등을 이유로 당해 발주회원의 회원자격을
          상실시킬 수 있습니다. 발주회원의 위와 같은 행위로 인하여 회사가
          물적·정신적 손해를 입은 경우 회사는 당해 발주회원에게 제 14조에
          해당하는 손해 배상을 청구할 수 있습니다.
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 13 조 공고</h5>
      <ol data-list-style-type="ko">
        <li>
          발주회원은 설정한 공고기간 내에 게시할 수 있으며, 공기기간 이 후
          투찰회원이 투찰한 정보에 의하여 계약을 진행할 수 있습니다.
        </li>
        <li>
          발주회원은 공고가 종료 된 시점 부터 계약금 납부 후 해당 용역에
          투찰한 투찰 회원 리스트 및 개별 투잘회원이 투찰 금액을 확인 할 수
          있습니다.
          <ol>
            <li>
              단독 응찰일 경우, 발주회원이 계약금을 납부 한 후 입찰을 진행하지
              않으면(적당한 투찰자가 없는 경우), 계약금의 90%가(10%는 회사에
              납부) 환불되며, 재 공고를 진행 할 수 있습니다.
            </li>
          </ol>
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 14 조 발주회원의 청약철회 효과</h5>
      <ol data-list-style-type="ko">
        <li>
          발주 회원이 투찰 금액을 확인 하기 위해 계약금 나부 후 계약 혹은
          재공고를 진행하지 않을 경우 계약금을 투잘자에게 분배 됩니다.
        </li>
        <li>
          발주 회원이 투찰 금액 확인 전(게약금 납부 전) 게약 혹은 재공고를
          진행하지 않을 경우 서비스 활동에 제약이 발생됩니다.
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 15 조 투찰회원의 청약철회 효과</h5>
      <ol data-list-style-type="ko">
        <li>
          우선 협상 대상자가 된 투찰회원이 발주자와의 계약 시점에서 계약을
          철회 할 때마다 수수료율이 1%씩 상향 조정됩니다(최초 수수료율 10%).
          이 경우 발주회원은 재발주하거나, 나머지 투찰 회원에서 우선 협상
          대상자를 선정할 수 있습니다. 재발주시 발주회원에게는 패널티는
          없습니다.
          <ol>
            <li>
              공고 마감일 기준 비영업 10일 이내 투찰회원이 어떠한 계약 활동을
              하지 않으면, 철회로 간주 합니다.
            </li>
            <li>
              수수료율이 높은 투찰회원은 회사에 일정 비용을 납부하여
              수수료율을 낮출 수 있습니다.
            </li>
          </ol>
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 16 조 이용 요금의 납입</h5>
      <ol data-list-style-type="ko">
        <li>
          회원은 회사가 제공하는 서비스 중 유료서비스를 이용하는 경우 이용
          대금을 납부한 후 서비스를 이용하는 것을 원칙으로 합니다.
        </li>
        <li>
          회사가 제공하는 유료서비스에 대한 이용 요금의 결제 방법은 회사가
          정하는 바에 따르며, 각 유료서비스마다 결제 방법의 차이가 있을 수
          있습니다.
        </li>
        <li>
          회사는 결제의 이행을 위하여 반드시 필요한 회원의 개인정보를
          추가적으로 요구할 수 있으며, 회원은 회사가 요구하는 개인정보를
          정확하게 제공하여야 합니다. 회사는 회원이 허위로 또는 부정확하게
          제공한 개인정보로 인하여 회원에게 발생하는 손해에 대하여 회사의
          고의？과실이 없는 한 회원의 손해를 배상할 책임을 부담하지 않습니다.
        </li>
        <li>
          회사는 유료서비스에 대하여 공지사항에 게시하거나 해당 서비스
          이용안내에서 제시하는 바에 따릅니다. 회사가 제공하는 서비스는 그
          이용대금을 별도 게시하거나 제시하지 않는 한 무료로 합니다.
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">
        제 17 조 이용 요금의 환불 및 이의제기
      </h5>
      <ol data-list-style-type="ko">
        <li>
          회원이 과오 납입한 요금에 대하여는 회사는 그 요금을 환불하여야
          합니다.
        </li>
        <li>
          회원의 이용요금을 환불하는 경우 각 유료 서비스 환불절차를 따릅니다.
        </li>
        <li>
          회원은 이용요금에 대하여 이의를 제기할 수 있습니다. 단, 각 유료
          서비스의 철회 가능 기일 이내 제기하여야 합니다.
        </li>
        <li>
          회사가 제공하는 유료 서비스 이용자가 제9조 나항을 위반하여 서비스
          이용이 제한되었을 경우 환불을 요청 할 수 있으며, 환불은 유료 서비스
          환불절차를 따릅니다.
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 18 조 낙찰수수료</h5>
      <ol data-list-style-type="ko">
        <li>
          회사는 투찰회원이 회사를 통하여 낙찰 및 계약이 체결되었을 시에
          계약체결일로부터 7일 이내에 낙찰 금액의 10%(낙찰수수료) 낙찰수수료를
          회사에 납부 해야합니다.
        </li>
        <li>
          낙찰수수료는 낙찰금액을 기준으로 하며, 낙찰금액과 실제 계약금액이
          다를 시에는 투찰회원은 계약일로부터 5일 이내에 실제 계약금액을
          증명할 수 있는 서류를 회사에 제출하여 낙찰수수료가 실제 계약금액을
          기준으로 낙찰수수료를 청구될 수 있도록 하여야합니다. 기간 내 서류
          미제출시 kg2g는 낙찰금액을 기준으로 낙찰수수료를 청구하며,
          투찰회원은 이에 이의를 제기할 수 없습니다.
        </li>
        <li>
          단가입찰의 경우 회사는 단가에 예상수량을 곱한 금액인 총액을 기준으로
          낙찰수수료 계약서에 명시되어 있는 수수료율에 따라 낙찰수수료를
          투찰회원에게 청구합니다.
        </li>
        <li>
          낙찰수수료의 납부기한은 전자적 방식에 의한 세금계산서 청구일로부터
          2주 이내로 합니다.
        </li>
        <li>
          기타 약관에 명시되어 있지 않은 사항은 낙찰수수료 계약서의 내용에
          따릅니다.
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 19 조 이용계약의 해지</h5>
      <ol data-list-style-type="ko">
        <li>
          회원이 서비스 이용계약을 해지하고자 하는 때에는 언제든지
          회원정보관리에서 회사가 정한 절차에 따라 회원의 ID를 삭제하고 탈퇴할
          수 있습니다.
        </li>
        <li>
          회원이 제9조의 규정을 위반한 경우 회사는 30일 이내의 기간을 정하여
          시정을 요청하고 회원이 기간 내에 시정하지 않는 경우 서비스 이용을
          제한할 수 있습니다.
        </li>
        <li>
          회원이 서비스를 이용하는 도중, 연속하여 1년 동안 서비스를 이용하기
          위해 회사의 서비스에 log-in한 기록이 없는 경우 회사는 회원의
          회원자격을 상실시킬 수 있습니다.
        </li>
        <li>
          유료서비스 이용계약의 해지는 회원의 서비스 해지신청 및 회사의 승낙에
          의해 성립합니다. 회사가 승낙한 시점에 해지의 효과는 발생하며 환불할
          금액이 있는 경우 환불도 이루어지게 됩니다. 환불에 대해서는 제19조의
          규정을 따릅니다.
        </li>
        <li>
          이 이용 계약이 해지된 경우 회원의 “게시물 등” 중 본인 계정에 등록된
          ‘게시물 등’ 일체는 삭제됩니다만 다른 회원에 의해 스크랩 되어
          게시되거나 공용 게시판에 등록된 ‘게시물 등’은 삭제되지 않습니다.
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 20 조 개인정보의 보호</h5>
      <ol data-list-style-type="ko">
        <li>
          회원의 개인정보는 서비스의 원활한 제공을 위하여 회원이 동의한 목적과
          범위 내에서만 이용됩니다. 관련 법령에 의하거나 회원이 별도로
          동의하지 아니하는 한 회사가 회원의 개인정보를 제3자에게 제공하는
          일은 결코 없으며 회사가 회원의 개인정보를 안전하게 처리하기 위하여
          기울이는 노력이나 기타 자세한 사항은 “개인정보처리방침”을 참고하여
          주십시오.
        </li>
        <li></li>
        <li></li>
        <li></li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 21 조 손해 배상 등</h5>
      <ol data-list-style-type="ko">
        <li>
          회사는 관련 법령상 허용되는 한도 내에서 서비스와 관련하여 본 약관에
          명시되지 않은 어떠한 구체적인 사항에 대한 약정이나 보증을 하지
          않습니다. 또한, 회사는 컨텐츠 제공자가 제공하거나 이용자가 작성하는
          등의 방법으로 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에
          대해서는 보증을 하지 않으며, 회사의 과실 없이 발생된 이용자의 손해에
          대하여는 책임을 부담하지 아니합니다.
        </li>
        <li>
          회사는 회사의 과실로 인하여 이용자가 손해를 입게 될 경우 본 약관 및
          관련 법령에 따라 이용자의 손해를 배상하겠습니다.
        </li>
        <li>
          회사는 회사의 과실이 없는 한 이용자 상호간 또는 이용자와 제3자
          상호간에 회사가 제공한 서비스를 매개로 발생한 분쟁에 대해서는 개입할
          의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 22 조 규정의 준용</h5>
      <ol data-list-style-type="ko">
        <li>
          본 약관에 명시되지 않은 사항에 대해서는 관련법령에 의하고, 법에
          명시되지 않은 부분에 대하여는 관습에 의합니다.
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">
        제 23 조 데이터 및 기타자료의 수집 및 사용
      </h5>
      <ol data-list-style-type="ko">
        <li>
          회사는 서비스와 관련하여 서비스의 품질개선 및 안전한 서비스 제공을
          위한 목적으로 회원의 단말기 정보 등을 수집하여 사용할 수 있습니다.
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 24 조 회사의 면책</h5>
      <ol data-list-style-type="ko">
        <li>
          회사는 천재지변 또는 이에 준하는 불가항력의 상황으로 인하여 서비스를
          제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.
        </li>
        <li>
          회사는 인터넷 이용자 또는 회원의 귀책사유로 인한 서비스 이용 장애에
          대해서는 책임을 지지 않습니다.
        </li>
        <li>
          회사는 회원간에 자발적으로 이루어진 정보의 교류, 서비스 교류 등으로
          인하여 발생한 손해에 대해서는 일체의 책임을 지지 않습니다.
        </li>
        <li>
          기타 회원이 게재한 정보, 자료, 사실의 정확성 등을 신뢰함으로써 입은
          손해에 대해서 회사는 책임지지 않습니다.
        </li>
        <li>
          "정보통신설비 유지관리 및 성능 점검 대상"의 점검 결과는 정확하지
          않을 수 있습니다.
        </li>
      </ol>
    </div>
  );
}

export default CommonTermsOfUse;
