import React, { useState } from "react";
import {findUserIdByEmail} from "../../services/authService";
import AlertDialog from "../../components/AlertDialog";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { validateEmailFormat } from "../../services/utils/formatUtil";
import {useLoading} from "../../components/LoadingContext";
import {useNavigate} from "react-router-dom";


function FindId() {
  const navigate = useNavigate();
  const {setLoading} = useLoading();
  const [email, setEmail] = useState("");
  const handleChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const sendUserId = () => {
    if (!validateEmailFormat(email)) {
      showAlert("이메일 형식이 맞지 않습니다.");
      return;
    }
    if (window.confirm("진행하시겠습니까?")) {
      setLoading(true);
      findUserIdByEmail(email, 'BETA-CHECK').then((response:any)=>{
        if (response === "User found email sent.") {
          alert("이메일로 사용자ID를 전송하였습니다.");
          navigate('/userSignIn');
        } else {
          showAlert("입력하신 이메일 정보와 일치하는 회원이 없습니다.");
        }
      }).catch(()=>{
        showAlert('오류가 발생하였습니다. 관리자에게 문의해주세요.');
      }).finally(()=>{
        setLoading(false);
      });
    }
  };

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const showAlert = (message:string) => {
    setAlertMessage(message);
    setAlertVisible(true);
  };
  const hideAlert = () => {
    setAlertVisible(false);
  };

  return (
    <>
      <Header />
      <div className="content text-left min-h-screen md:pt-32">
        <div className="login-container max-w-md mx-auto p-4">
          <h1 className="p-2 font-semibold text-center mb-5">
            아이디 찾기
          </h1>
          <form className="space-y-5 w-auto md:w-[450px] mx-auto">
            <div className="mb-3">
              <label htmlFor="email" className="block mb-2 font-bold">
                이메일<span className="text-red"> *</span>
              </label>
              <input
                type="email"
                id="email"
                className="w-full p-3 border rounded-xl focus:outline-[#3F80EA] focus:ring-1"
                name="email"
                value={email}
                onChange={handleChangeEmail}
              />
            </div>
            <div className="mb-3">
              <button
                type="button"
                className="w-full bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white py-2.5 rounded-xl disabled:bg-gray-500 disabled:from-gray-500 disabled:to-gray-500 disabled:border-none disabled:cursor-not-allowed"
                disabled={!email}
                onClick={sendUserId}
              >
                아이디 찾기
              </button>
            </div>
          </form>
        </div>
      </div>
      <AlertDialog
        visible={alertVisible}
        onHide={hideAlert}
        message={alertMessage}
      />
      <Footer />
    </>
  );
}

export default FindId;
