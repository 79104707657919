import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgotPassword, resetPassword } from "../../services/authService";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import {useLoading} from "../../components/LoadingContext";
import {validateEmailFormat} from "../../services/utils/formatUtil";
import AlertDialog from "../../components/AlertDialog";

function FindPassword() {
  const navigate = useNavigate();
  const {setLoading} = useLoading();

  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordConfirmValid, setIsPasswordConfirmValid] = useState(false);

  const [isIdValid, setIsIdValid] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");

  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);
  const confirmationCodeRef = useRef(null);

  const passwordOver8 = useRef(null);
  const passwordSpecialChar = useRef(null);
  const passwordNum = useRef(null);
  const passwordUpperCase = useRef(null);
  const passwordLowerCase = useRef(null);
  const passwordDiff = useRef(null);

  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);

  const handleChangeUserId = (e: any) => {
    setUserId(e.target.value);
  };
  const handleChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };
  const handleChangeConfirmationCode = (e: any) => {
    setConfirmationCode(e.target.value);
  };

  const handleShowPassword = async (e: any) => {
    const password = await passwordRef.current;
    if (password === null) return;

    await setShowPassword(!showPassword);
    if (!showPassword) {
      // @ts-ignore
      password.type = "text";
    } else {
      // @ts-ignore
      password.type = "password";
    }
  };

  const getPasswordConfirmationCode = () => {
    const param = {
      userId: userId,
      email: email,
    };
    if ( validateEmailFormat(email) ) {
      setLoading(true);
      forgotPassword(param).then((response) => {
        if (response.status === "200") {
          setIsIdValid(true);
        } else {
          setVisible(true);
        }
      }).finally(()=>{
        setLoading(false);
      });
    } else {
      alert('이메일 형식이 맞지 않습니다.');
    }
  };

  const resetPasswordByConfirmationCode = () => {
    const param = {
      userId: userId,
      password: password,
      confirmationCode: confirmationCode,
      siteName: "BETA-CHECK"
    };

    setLoading(true);
    resetPassword(param).then((response) => {
      if (response.status === "200") {
        setVisible2(true);
      } else {
        showAlert('확인코드가 일치하지 않습니다. 다시 한번 확인해주세요.');
      }
    }).finally(()=>{
      setLoading(false);
    });
  };

  const passwordRuleCheck = (event: any) => {
    if (event.target.value.length > 40) {
      showAlert('비밀번호는 40자로 제한되어 있습니다.');
      return;
    }

    // 8자 이상, 숫자 포함, 소문자 포함, 대문자 포함, 특수문자 포함
    // console.log('Here is password ', event.target.value);
    const specialRule = /[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/g; // 특수문자 포함여부
    const numberRule = /[0-9]/g; // 숫자 포함여부
    const upperCaseRule = /[A-Z]/g; // 대문자 포함여부
    const lowerCaseRule = /[a-z]/g; // 소문자 포함여부

    let isPasswordOver8 = false;
    let isPasswordSpecialChar = false;
    let isPasswordNum = false;
    let isPasswordUpperCase = false;
    let isPasswordLowerCase = false;

    // 8자 이상
    if (event.target.value.length >= 8) {
      // @ts-ignore
      passwordOver8.current.className = "text-green-500";
      isPasswordOver8 = true;
    } else {
      // @ts-ignore
      passwordOver8.current.className = "text-red-500";
      isPasswordOver8 = false;
    }

    if (specialRule.test(event.target.value)) {
      // 특수문자가 포함되어 있으면
      // @ts-ignore
      passwordSpecialChar.current.className = "text-green-500";
      isPasswordSpecialChar = true;
    } else {
      // @ts-ignore
      passwordSpecialChar.current.className = "text-red-500";
      isPasswordSpecialChar = false;
    }

    if (numberRule.test(event.target.value)) {
      // 숫자가 포함되어 있으면
      // @ts-ignore
      passwordNum.current.className = "text-green-500";
      isPasswordNum = true;
    } else {
      // @ts-ignore
      passwordNum.current.className = "text-red-500";
      isPasswordNum = false;
    }

    if (upperCaseRule.test(event.target.value)) {
      // 대문자가 포함되어 있으면
      // @ts-ignore
      passwordUpperCase.current.className = "text-green-500";
      isPasswordUpperCase = true;
    } else {
      // @ts-ignore
      passwordUpperCase.current.className = "text-red-500";
      isPasswordUpperCase = false;
    }

    if (lowerCaseRule.test(event.target.value)) {
      // 소문자가 포함되어 있으면
      // @ts-ignore
      passwordLowerCase.current.className = "text-green-500";
      isPasswordLowerCase = true;
    } else {
      // @ts-ignore
      passwordLowerCase.current.className = "text-red-500";
      isPasswordLowerCase = false;
    }

    if (Object.is(passwordConfirm, event.target.value)) {
      // @ts-ignore
      passwordDiff.current.style.setProperty("display", "none");
      setIsPasswordConfirmValid(true);
    } else {
      // @ts-ignore
      passwordDiff.current.style.setProperty("display", "block");
      // @ts-ignore
      passwordDiff.current.className = "text-red-500";
      setIsPasswordConfirmValid(false);
    }

    if (isPasswordOver8
      && isPasswordSpecialChar
      && isPasswordNum
      && isPasswordUpperCase
      && isPasswordLowerCase
    ) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }

    setPassword(event.target.value);
  };

  const passwordConfirmCheck = (event: any) => {
    if (event.target.value.length > 40) {
      showAlert('비밀번호는 40자로 제한되어 있습니다.');
      return;
    }

    if (Object.is(password, event.target.value)) {
      // @ts-ignore
      passwordDiff.current.style.setProperty("display", "none");
      setIsPasswordConfirmValid(true);
    } else {
      // @ts-ignore
      passwordDiff.current.style.setProperty("display", "block");
      // @ts-ignore
      passwordDiff.current.className = "text-red-500";
      setIsPasswordConfirmValid(false);
    }
    setPasswordConfirm(event.target.value);
  };
  const handleShowPasswordConfirm = async (e: any) => {
    const passwordConfirm = await passwordConfirmRef.current;
    if (passwordConfirm === null) return;
    await setShowPasswordConfirm(!showPasswordConfirm);
    if (!showPasswordConfirm) {
      // @ts-ignore
      passwordConfirm.type = "text";
    } else {
      // @ts-ignore
      passwordConfirm.type = "password";
    }
  };

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const showAlert = (message:string) => {
    setAlertMessage(message);
    setAlertVisible(true);
  };
  const hideAlert = () => {
    setAlertVisible(false);
  };

  return (
    <>
      <Header />
      <div className="content text-left min-h-screen">
        <div className="login-container max-w-md mx-auto py-24">
          <h1 className="p-2 font-semibold text-center mb-5">
            비밀번호 재설정
          </h1>
          {isIdValid ? (
            <form className="space-y-5 w-auto md:w-[450px] mx-auto">
              <div>
                <label
                  htmlFor="confirmationCode"
                  className="block mb-2 font-bold"
                >
                  확인 코드
                </label>
                <input
                  type="text"
                  id="confirmationCode"
                  className="w-full p-3 border rounded-xl focus:outline-[#3F80EA] focus:ring-1"
                  onChange={handleChangeConfirmationCode}
                  value={confirmationCode}
                  ref={confirmationCodeRef}
                />
              </div>

              <div>
                <label htmlFor="password" className="block mb-2 font-bold">
                  변경 비밀번호
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    className="w-full p-3 border rounded-xl focus:outline-[#3F80EA] focus:ring-1"
                    onChange={passwordRuleCheck}
                    value={password}
                    ref={passwordRef}
                  />
                  <button
                    type="button"
                    className="absolute right-2 top-3.5 text-gray-500"
                    onClick={handleShowPassword}
                  >
                    {showPassword ? (
                      <i className="pi pi-eye text-lg" />
                    ) : (
                      <i className="pi pi-eye-slash text-lg" />
                    )}
                  </button>
                </div>
                <div className="text-sm mt-1 space-x-2">
                  <span ref={passwordOver8} className="text-red">
                    8자 이상
                  </span>
                  <span ref={passwordSpecialChar} className="text-red">
                    특수 문자
                  </span>
                  <span ref={passwordNum} className="text-red">
                    숫자
                  </span>
                  <span ref={passwordUpperCase} className="text-red">
                    대문자
                  </span>
                  <span ref={passwordLowerCase} className="text-red">
                    소문자
                  </span>
                </div>
              </div>

              <div>
                <label
                  htmlFor="passwordConfirm"
                  className="block mb-2 font-bold"
                >
                  비밀번호 확인
                </label>
                <div className="relative">
                  <input
                    type={showPasswordConfirm ? "text" : "password"}
                    id="passwordConfirm"
                    className="w-full p-3 border rounded-xl focus:outline-[#3F80EA] focus:ring-1"
                    onChange={passwordConfirmCheck}
                    value={passwordConfirm}
                    ref={passwordConfirmRef}
                  />
                  <button
                    type="button"
                    className="absolute right-2 top-3 text-gray-500"
                    onClick={handleShowPasswordConfirm}
                  >
                    {showPasswordConfirm ? (
                      <i className="pi pi-eye text-lg" />
                    ) : (
                      <i className="pi pi-eye-slash text-lg" />
                    )}
                  </button>
                </div>
                <div ref={passwordDiff} className="hidden text-red text-sm mt-1">
                  비밀번호가 일치하지 않습니다.
                </div>
              </div>
              <button
                type="button"
                disabled={!password || !passwordConfirm || password !== passwordConfirm || !isPasswordConfirmValid || !isPasswordValid}
                className="w-full bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white py-2.5 rounded-xl disabled:bg-gray-500 disabled:from-gray-500 disabled:to-gray-500 disabled:border-none disabled:cursor-not-allowed"
                onClick={resetPasswordByConfirmationCode}
              >
                비밀번호 변경
              </button>
            </form>
          ) : (
            <form className="space-y-5 w-auto md:max-w-[450px] mx-auto">
              <div className="flex justify-center">
                <IconField iconPosition="left">
                  <InputIcon className="pi pi-user text-lg" />
                  <InputText
                    v-model="value1"
                    className="w-auto md:w-[450px] h-fit md:h-[56px] text-lg focus:border focus:border-primary rounded-xl hover:border-primary focus:ring-0 border-0 shadow-md"
                    name="userId"
                    id="userId"
                    value={userId}
                    onChange={handleChangeUserId}
                    placeholder="아이디를 입력해 주세요."
                  />
                </IconField>
              </div>

              <div className="flex justify-center">
                <IconField iconPosition="left">
                  <InputIcon className="pi pi-envelope text-lg" />
                  <InputText
                    v-model="value1"
                    className="w-auto md:w-[450px] h-fit md:h-[56px] text-lg focus:border focus:border-primary rounded-xl hover:border-primary focus:ring-0 border-0 shadow-md"
                    name="email"
                    id="email"
                    keyfilter={"email"}
                    value={email}
                    onChange={handleChangeEmail}
                    placeholder="이메일을 입력해주세요."
                  />
                </IconField>
              </div>

              <button
                type="button"
                className="w-full py-2.5 text-white rounded-xl bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA]"
                onClick={getPasswordConfirmationCode}
              >
                다음
              </button>
            </form>
          )}
        </div>
      </div>
      <Footer />

      <AlertDialog
        visible={alertVisible}
        onHide={hideAlert}
        message={alertMessage}
      />

      {/* alert dialog */}
      <Dialog
        visible={visible}
        style={{ width: "30vw" }}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div className="my-2">
          <div className="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="size-24 my-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
              />
            </svg>
          </div>

          <h3 className="text-2xl font-bold text-center my-4">
            입력하신 정보와 일치하는 회원이 없습니다. <br /> 확인 후 다시
            시도해주세요.
          </h3>

          <div className="px-6 mt-8">
            <button
              type="button"
              onClick={() => setVisible(false)}
              className="w-full hover:opacity-90 transition duration-300 ease-in-out bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white py-2.5 rounded-xl"
            >
              확인
            </button>
          </div>
        </div>
      </Dialog>

      {/* confirm dialog */}
      <Dialog
        visible={visible2}
        style={{ width: "30vw" }}
        onHide={() => {
          if (!visible2) return;
          setVisible2(false);
        }}
      >
        <div className="my-2">
          <h3 className="text-2xl font-bold text-center my-4">
            비밀번호 변경이 완료되었습니다. <br /> 변경된 비밀번호로
            재로그인해주세요.
          </h3>

          <div className="px-6 mt-8">
            <button
              type="button"
              onClick={() => navigate("/userSignIn")}
              className="w-full hover:opacity-90 transition duration-300 ease-in-out bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white py-2.5 rounded-xl"
            >
              확인
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default FindPassword;
