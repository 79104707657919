import React, { useCallback, useRef, useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {
  saveAdditionalRegistration,
} from "../../services/businessUserService";
import { Dialog } from "primereact/dialog";
import "../../styles/personReg.css";
import { Button } from "primereact/button";
import ParticipantTermsOfUse from "../ParticipantTermsOfUse";
import Header from "../Header";
import Footer from "../Footer";
import AlertDialog from "../AlertDialog";
import ConfirmDialog from "../ConfirmDialog";
import {useLoading} from "../LoadingContext";

function AdditionalRegistration(props:any) {
  const navigate = useNavigate();
  const location = useLocation();
  const {setLoading} = useLoading();

  const { userId } = location.state || {};
  const [agreementYn, setAgreementYn] = useState(false);

  const cameraRef = useRef<HTMLInputElement | null>(null);
  const cameraRef2 = useRef<HTMLInputElement | null>(null);
  const cameraRef3 = useRef<HTMLInputElement | null>(null);

  const [showImage, setShowImage] = useState("");
  const [submitImage, setSubmitImage] = useState<File | undefined>(undefined);
  const [showImage2, setShowImage2] = useState("");
  const [submitImage2, setSubmitImage2] = useState<File | undefined>(undefined);
  const [showImage3, setShowImage3] = useState("");
  const [submitImage3, setSubmitImage3] = useState<File | undefined>(undefined);

  const [visible, setVisible] = useState<boolean>(false);
  const [visible5, setVisible5] = useState<boolean>(false);

  const validateForm = () => {
    // 약관동의 여부
    if (!agreementYn) {
      showAlert('약관 동의를 해야합니다.');
      return false;
    }
    if (!submitImage && !submitImage2 && !submitImage3) {
      showAlert("파일이 없습니다.");
      return false;
    }
    if (userId === '') {
      showAlert('오류가 발생했습니다. 정상 로그인 후 다시 시도해주세요.');
      return false;
    }

    return true;
  };

  const registerBusinessUser = () => {
    if (!validateForm()) {
      return;
    }
    showConfirm("추가인증을 진행하시겠습니까?");
  };

  const onUploadImageButtonClicked = useCallback(() => {
    if (!cameraRef.current) {
      return;
    }
    cameraRef.current.click();
  }, []);
  const onUploadImageButtonClicked2 = useCallback(() => {
    if (!cameraRef2.current) {
      return;
    }
    cameraRef2.current.click();
  }, []);
  const onUploadImageButtonClicked3 = useCallback(() => {
    if (!cameraRef3.current) {
      return;
    }
    cameraRef3.current.click();
  }, []);

  const onUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const selectedFile = e.target.files[0];

    // 파일사이즈 5MB 제한
    const maxSize = 5 * 1024 * 1024;

    if (selectedFile.size > maxSize) {
      alert("파일 크기는 5MB 미만만 업로드가 가능합니다.");
      return;
    }

    // 파일 이름을 state에 저장
    setShowImage(selectedFile.name);

    // 단일 파일을 state에 저장
    setSubmitImage(selectedFile);
  };

  const onUploadImage2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const selectedFile = e.target.files[0];

    // 파일사이즈 5MB 제한
    const maxSize = 5 * 1024 * 1024;

    if (selectedFile.size > maxSize) {
      alert("파일 크기는 5MB 미만만 업로드가 가능합니다.");
      return;
    }

    // 파일 이름을 state에 저장
    setShowImage2(selectedFile.name);

    // 단일 파일을 state에 저장
    setSubmitImage2(selectedFile);
  };

  const onUploadImage3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const selectedFile = e.target.files[0];

    // 파일사이즈 5MB 제한
    const maxSize = 5 * 1024 * 1024;

    if (selectedFile.size > maxSize) {
      alert("파일 크기는 5MB 미만만 업로드가 가능합니다.");
      return;
    }

    // 파일 이름을 state에 저장
    setShowImage3(selectedFile.name);

    // 단일 파일을 state에 저장
    setSubmitImage3(selectedFile);
  };

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const showAlert = (message:string) => {
    setAlertMessage(message);
    setAlertVisible(true);
  };
  const hideAlert = () => {
    setAlertVisible(false);
  };
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const showConfirm = (message:string) => {
    setConfirmMessage(message);
    setConfirmVisible(true);
  };
  const hideConfirm = () => {
    setConfirmVisible(false);
  };
  const handleConfirm = () => {
    // 확인이 선택되었을 때 수행할 동작
    const param = {
      userId: userId,
      iacCertFile: submitImage,
      engineeringCertFile: submitImage2,
      iacEngineerCertFile: submitImage3,
      siteName: "BETA-CHECK",
      termList: ["PARTICIPANT"],
    };
    setLoading(true);
    saveAdditionalRegistration(param).then((signUpResponse) => {
      console.log(signUpResponse.status);
      console.log(signUpResponse.message);
      if (signUpResponse.status === "SUCCESS") {
        setVisible5(true);
      }
    }).finally(()=>{
      setLoading(false);
    });
  };

  return (
    <>
      <Header/>
      <h1 className="pt-10 my-12 font-bold product-title App">추가 인증 요청하기</h1>
      <div className={'text-left'}>
        <div className={'grid grid-cols-1 lg:grid-cols-3'}>
          <div className={'h-96 mx-4 border rounded-xl shadow-lg p-4 mb-4'}>
            <ul>
              <li>역할회원은 게시된 입찰 공고 투찰에 참여할 수 있는 기업회원</li>
              <li>~~~법 ~~에 따라 건물 내 정보통신설비 유지보수 조건은 다음과 같음</li>
              <li>(인용)</li>
              <li>성능점검 조건은 다음과 같음</li>
              <li>(인용)</li>
              <li>이러한 조건에 따라 필요한 증명서류를 추가 인증해야 활동할 수 있음</li>
              <li>관리자가 요청된 내용 검토하여 승인</li>
              <li>승인 완료 후 이메일로 알림 전송</li>
              <li>마이페이지에서 추후에 추가 인증 요청을 할 수 있음</li>

            </ul>
          </div>
          <section className="space-y-4 pb-16 mx-4 lg:mx-0">
            <div className="mb-3 p-4 border rounded-xl shadow-lg">
              <h2 className="mb-2 font-semibold">약관동의</h2>

              <hr/>
              {/* first checkbox  */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <div className="inline-flex items-center">
                    <label
                      className="relative flex items-center mt-4 rounded-full cursor-pointer"
                      htmlFor="agreement"
                    >
                      <input
                        type="checkbox"
                        className="peer relative appearance-none w-5 h-5 border rounded-md border-blue-gray-200 cursor-pointer transition-all before:content[''] before:block before:bg-blue-gray-500 before:w-12 before:h-12 before:rounded-full before:absolute before:top-2/4 before:left-2/4 before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 hover:before:opacity-10 before:transition-opacity"
                        id="agreement"
                        checked={agreementYn}
                        onChange={(event) => setAgreementYn(event.target.checked)}
                      />

                      <span
                        className="absolute text-gray-500 transition-opacity opacity-100 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-circle-check"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="gray"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"/>
                        <path d="M9 12l2 2l4 -4"/>
                      </svg>
                    </span>

                      <span
                        className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-circle-check"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="green"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"/>
                        <path d="M9 12l2 2l4 -4"/>
                      </svg>
                    </span>
                    </label>
                  </div>

                  <h3 className="mt-3">(필수) 투찰회원 약관</h3>
                </div>

                <div className="mt-3">
                  <a
                    onClick={() => setVisible(true)}
                    className="underline cursor-pointer"
                  >
                    내용보기
                  </a>
                </div>
              </div>
            </div>

            {/* 4th portion  */}
            <div className="mb-3 p-4 border rounded-xl shadow-lg">
              <h2 className="mb-2 font-semibold">추가 파일 등록</h2>

              <hr/>

              <div className="mb-3 gap-3">
                <div className={'text-red-500 text-sm'}>
                  <span>
                    <i className={'pi pi-info-circle m-1'} style={{fontSize: '1rem'}}></i>
                    5MB 미만의 .pdf .png .jpg .jpeg 확장자 파일만 업로드 가능합니다.
                  </span>
                </div>

                <div
                  className={'h-16 mt-3 bg-opacity-20 ps-4 grid grid-cols-3 gap-3 content-center rounded-md'}
                  style={{
                    backgroundColor: submitImage ? '#2AC9A444' : '#E0E0E0'
                  }}
                >
                  <div className={'col-span-2'}>
                    <span className={'w-full'}>
                      {
                        submitImage
                          ? <i className={'pi pi-check me-2'} style={{color: '#61D737'}}></i>
                          : <></>
                      }
                      정보통신공사업증
                    </span>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={onUploadImageButtonClicked}
                      className=" px-3 bg-white rounded-2xl border border-[#2AC9A4] text-[#2AC9A4] hover:bg-[#2AC9A4] hover:text-white hover:border-transparent font-semibold"
                    >
                      업로드
                      <i className="ps-3 pi pi-upload" style={{fontSize: '1rem'}}></i>
                    </button>
                    <input
                      type="file"
                      ref={cameraRef}
                      onChange={onUploadImage}
                      accept=".pdf, image/*"
                      className="hidden"
                    />
                  </div>
                </div>
              </div>
              <div className="border px-2 py-2 rounded-md">
                <div
                  className="flex flex-row-reverse items-center justify-end mb-1 gap-3"
                >
                  {
                    submitImage
                      ? <>
                        <span className={'ms-4'}>{submitImage?.name}</span>
                      </>
                      : <></>
                  }
                </div>
              </div>

              <div className="my-3 gap-3 justify-between">
                <div
                  className="h-16 mt-3 bg-opacity-20 ps-4 grid grid-cols-3 gap-3 content-center rounded-md"
                  style={{backgroundColor: submitImage2 ? '#2AC9A444' : '#E0E0E0'}}
                >
                  <div className={'col-span-2'}>
                    <span>
                      {
                        submitImage2
                          ? <i className={'pi pi-check me-2'} style={{color: '#61D737'}}></i>
                          : <></>
                      }
                      엔지니어링사업자 신고증
                    </span>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={onUploadImageButtonClicked2}
                      className="px-3 bg-white rounded-2xl border border-[#2AC9A4] text-[#2AC9A4] hover:bg-[#2AC9A4] hover:text-white hover:border-transparent font-semibold"
                    >
                      업로드
                      <i className="ps-3 pi pi-upload" style={{fontSize: '1rem'}}></i>
                    </button>
                    <input
                      type="file"
                      ref={cameraRef2}
                      onChange={onUploadImage2}
                      accept=".pdf, image/*"
                      className="hidden"
                    />
                  </div>
                </div>
              </div>
              <div className="border px-2 py-2 rounded-md">
                <div
                  className="flex flex-row-reverse items-center justify-end mb-1 gap-3"
                >
                  {
                    submitImage2
                      ? <>
                        <span className={'ms-4'}>{submitImage2?.name}</span>
                      </>
                      : <></>
                  }
                </div>
              </div>

              <div className="my-3 gap-3 justify-between">
                <div
                  className="h-16 mt-3 bg-opacity-20 ps-4 grid grid-cols-3 gap-3 content-center rounded-md"
                  style={{backgroundColor: submitImage3 ? '#2AC9A444' : '#E0E0E0'}}
                >
                  <div className={'col-span-2'}>
                    <span>
                      {
                        submitImage3
                          ? <i className={'pi pi-check me-2'} style={{color: '#61D737'}}></i>
                          : <></>
                      }
                      정보통신기술사 자격증
                    </span>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={onUploadImageButtonClicked3}
                      className="px-3 bg-white rounded-2xl border border-[#2AC9A4] text-[#2AC9A4] hover:bg-[#2AC9A4] hover:text-white hover:border-transparent font-semibold"
                    >
                      업로드
                      <i className="ps-3 pi pi-upload" style={{fontSize: '1rem'}}></i>
                    </button>
                    <input
                      type="file"
                      ref={cameraRef3}
                      onChange={onUploadImage3}
                      accept=".pdf, image/*"
                      className="hidden"
                    />
                  </div>
                </div>
              </div>
              <div className="border px-2 py-2 rounded-md">
                <div
                  className="flex flex-row-reverse items-center justify-end mb-1 gap-3"
                >
                  {
                    submitImage3
                      ? <>
                        <span className={'ms-4'}>{submitImage3?.name}</span>
                      </>
                      : <></>
                  }
                </div>
              </div>

            </div>

            <div className="mt-8 flex justify-between gap-6">
              <button
                type="button"
                onClick={registerBusinessUser}
                className="shadow-lg w-full hover:opacity-90 transition duration-300 ease-in-out bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white py-2.5 rounded-xl"
              >
                추가인증 요청하기
              </button>
            </div>
            <div className="mt-8 flex justify-between gap-6">
              <button
                type="button"
                onClick={() => navigate('/')}
                className="w-full hover:opacity-90 transition duration-300 ease-in-out bg-white text-primary py-2.5 rounded-xl border border-primary shadow-lg"
              >
                나중에 요청하기
              </button>
            </div>

          </section>
          {/*<div className={'grid grid-cols-subgrid gap-4'}>*/}
          {/*  <div className={'h-96 mx-4 border rounded-xl shadow-lg p-4'}>7</div>*/}
          {/*</div>*/}
        </div>
      </div>
      <Footer/>

      {/* 1st dialog */}
      <Dialog
        header="투찰회원 약관"
        headerClassName="bg-primary text-white"
        visible={visible}
        style={{width: "95vw"}}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div className="my-4">
          <ParticipantTermsOfUse/>
        </div>
      </Dialog>

      {/* registration complete dialog */}
      <Dialog
        visible={visible5}
        modal
        onHide={() => {
          if (!visible5) return;
          setVisible5(false);
        }}
        content={({hide}) => (
          <div className="p-10 gap-4 bg-white rounded-md">
            <div className="space-y-16">
              <h3 className="text-4xl text-center font-semibold">
                추가 인증 요청되었습니다.
              </h3>

              <Button
                onClick={(e) => {
                  navigate("/");
                  hide(e);
                }}
                className="w-full hover:opacity-90 transition duration-300 ease-in-out active:bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white py-2.5 rounded-xl"
                label="확인"
              />
            </div>
          </div>
        )}
      ></Dialog>

      <AlertDialog
        visible={alertVisible}
        onHide={hideAlert}
        message={alertMessage}
      />
      <ConfirmDialog
        visible={confirmVisible}
        onHide={hideConfirm}
        message={confirmMessage}
        onConfirm={handleConfirm}
      />
    </>
  );
}

export default AdditionalRegistration;
