import {Navigate} from "react-router-dom";
import React from "react";


interface AuthorizationProps {
    redirectTo: string;
    userInfo: object | null;
    children: React.ReactNode;
    pathname: string | null;
}

const Authorization = ({
                           redirectTo,
                           userInfo,
                           children,
                          pathname
                       }: AuthorizationProps) => {
    if (userInfo) {
      return <>{children}</>;
    } else {
      alert('접근하려면 로그인이 필요합니다. 로그인 페이지로 이동합니다.');
      return <Navigate to={redirectTo} state={pathname}/>;
    }
};

export default Authorization;