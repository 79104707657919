import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { Badge } from "primereact/badge";
import { Dialog } from "primereact/dialog";
import {
  getSummaryIACInfra,
  getBidListDetail, getBidFiles, downloadBidFile,
} from "../../services/serviceBidService";
import Footer from "../../components/Footer";
import {DataTable, DataTableExpandedRows, DataTableValueArray} from "primereact/datatable";
import {Column} from "primereact/column";
import warning_badge from "../../images/svg/warning_badge.svg";
import {KRWon} from "../../services/utils/formatUtil";
import {Accordion, AccordionTab} from "primereact/accordion";

type DocumentRequirement = {
  documentReqName: string;
};

type BidListDetailType = {
  title: string;
  status: string;
  createDate: string;
  dueDate: string;
  province: string;
  totalArea: number;
  performanceYn: string;
  maintenanceYn:string;
  documentRequirementsList: DocumentRequirement[];
  description: string;
  startDate: string;
  endDate: string;
};
interface Building {
  buildingId: number;
  buildingName: string;
  address: string;
  totalArea: number;
  quotationStatus: string;
  quotationSeq: number;
  infraSummary: InfraQuantitySummary[];
}
interface InfraQuantitySummary {
  infraTypeSeq: number;
  infraTypeName: string;
  quantity: number;
  maintenancePrice: number;
  performancePrice: number;
}
interface StandardPriceTotal {
  maintenancePrice: number | bigint;
  performancePrice: number | bigint;
  wholePrice: number | bigint;
}
interface BidFile {
  seq: number;
  bidSeq: number;
  fileType: string;
  fileName: string;
  fileSize: number;
  fileUrl: string;
}

function IACServiceBidDetail() {
  const { serviceBidId } = useParams();

  const [bidListDetail, setBidListDetail] = useState<BidListDetailType>(
    {} as BidListDetailType
  );

  const [buildingList, setBuildingList] = useState<Building[]>([]);
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);
  const [infrastructure, setInfrastructure] = useState<InfraQuantitySummary[]>([]);
  const [infraModalvisible, setInfraModalvisible] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<StandardPriceTotal>();
  const [files, setFiles] = useState<BidFile[]>();

  const convertedDate = (date: string | undefined) => {
    if (!date) {
      return "";
    }
    const [year, month, day, hour, minute, second] = date
      .split(" ")[0]
      .split("-")
      .concat(date.split(" ")[1].split(":"));
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  const showInfraSummary = (data:any) => {
    setInfrastructure(data.infraSummary);
    setInfraModalvisible(true);
  };
  const sumTotalArea = (buildingList:Building[]) => {
    let sum = 0;
    for (const item of buildingList) {
      sum = item.totalArea + sum;
    }
    return sum.toLocaleString() + " m²";
  };
  const infraSummaryButtonTemplate = (data: any) => {
    return (
      <button
        className={'px-10 py-2 bg-[#3F80EA] disabled:bg-[#DADADA] hover:bg-[#4a8dcc] duration-300 text-base font-extrabold text-white rounded-xl'}
        onClick={()=>showInfraSummary(data)}
      >보기</button>
    );
  };
  const totalAreaTemplate = (rowData: Building) => {
    return rowData.totalArea.toLocaleString() + " m²";
  };
  const allowExpansion = (rowData: any) => {
    return rowData.buildingDongList.length > 0;
  };
  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="">
        <h5 className={'my-3 font-semibold'}>포함된 표제부 목록</h5>
        <DataTable
          value={data.buildingDongList}
          size={'small'}
          rowClassName={(data, options) => "text-sm border"}
        >
          <Column field="buildingName" header="건물명"></Column>
          <Column field="totalArea" header="연면적"></Column>
          <Column field="dongName" header="동이름"></Column>
          <Column field="mainPurposeName" header="주용도"></Column>
          <Column field="useApproveDay" header="사용승인일"></Column>
        </DataTable>
      </div>
    );
  };
  const downloadFile = (file: BidFile) => {
    downloadBidFile(file.seq, file.fileName).then(()=>{
    });
  };

  // Fetch bid list and infraTypes
  useEffect(() => {
    getBidListDetail(serviceBidId).then((data)=>{
      setBidListDetail(data);
      setBuildingList(data.buildingList);

      let sumMaintenancePrice = 0;
      let sumPerformancePrice = 0;
      for (const item of data.buildingList) {
        getSummaryIACInfra(item.buildingId, item.quotationSeq).then((result)=>{
          item.infraSummary = result;
          for (const infra of item.infraSummary) {
            sumMaintenancePrice = infra.maintenancePrice + sumMaintenancePrice;
            sumPerformancePrice = infra.performancePrice + sumPerformancePrice;
          }
        }).finally(()=>{
          setTotalPrice({
            maintenancePrice: sumMaintenancePrice,
            performancePrice: sumPerformancePrice,
            wholePrice: sumMaintenancePrice + sumPerformancePrice
          });
        });
      }
    }).finally(()=>{

    });

    getBidFiles(serviceBidId).then((data)=>{
      setFiles(data);
    });

  }, []);

  return (
    <section>
      <Header />

      <div className={'grid grid-cols-12'}>
        <div className={'col-span-2'}></div>
        <div className={'col-span-8'}>
          <div className="pt-10 mt-12">
            <div className="text-start flex gap-2">
              <Badge value={bidListDetail?.status} className={'bg-[#2AC9A4] rounded-2xl'}/>
            </div>

            <div className="flex gap-2 items-center">
              <h1 className="">{bidListDetail?.title}</h1>
            </div>

            <div className="flex items-center mt-2 gap-4">
              <div className="flex gap-2 items-center">
                <h6>공고등록일</h6>
                <h6>{convertedDate(bidListDetail?.createDate)}</h6>
              </div>
              |
              <div className="flex gap-2 items-center">
                <h6>입찰마감일</h6>
                <h6>{convertedDate(bidListDetail?.dueDate)}</h6>
              </div>
            </div>

            <div className={'mt-10'}>
              <h2 className="text-start">요청할 점검 종류</h2>
              <div className={'text-start bg-white rounded-lg p-5'}>
                <ul className="mb-0 list-disc ml-2">
                  {
                    bidListDetail.maintenanceYn === 'Y'
                      ? <li className="text-start">유지보수 점검</li>
                      : <></>
                  }
                  {
                    bidListDetail.performanceYn === 'Y'
                      ? <li className="text-start">성능 점검</li>
                      : <></>
                  }
                </ul>
              </div>
            </div>

            <div className={'mt-10'}>
              <h2 className="text-start">대상 건물 목록</h2>
              <div className="">
                <DataTable
                  value={buildingList}
                  dataKey="buildingId"
                  emptyMessage="선택한 건물이 없습니다."
                  tableStyle={{minWidth: "50rem"}}
                  rowClassName={(data, options) => "text-sm border"}
                  tableClassName="border-separate"
                  columnResizeMode="expand"
                  className=""
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data)}
                  rowExpansionTemplate={rowExpansionTemplate}
                >
                  <Column expander={allowExpansion} style={{width: '5rem'}}/>
                  <Column field="buildingName" header="건물명"></Column>
                  <Column field="address" header="주소"></Column>
                  <Column
                    field="totalArea"
                    header="연면적"
                    body={totalAreaTemplate}
                    headerClassName=""
                    className=""
                  ></Column>
                  <Column
                    header="설비 현황"
                    body={infraSummaryButtonTemplate}
                  ></Column>
                </DataTable>
              </div>
              <div className={'card grid grid-cols-4 gap-4 my-3'}>
                <span className={'col-start-3 p-3 rounded-lg bg-white'}>총 연면적 합계</span>
                <span className={'p-3 rounded-lg bg-white'}>{sumTotalArea(buildingList)}</span>
              </div>
            </div>

            <div className="mt-10 text-start">
              <h2 className="">계약 관련 사항 안내</h2>
              <div className="p-5 rounded-lg bg-white">
                <div className={'grid grid-cols-4 gap-4'}>
                  <h3 className={''}>
                    계약 기간
                  </h3>
                  <div className="flex w-full items-start gap-x-1 col-span-3">
                    <img src={warning_badge} alt="warning_badge" className="mt-0.5"/>
                    <div className="text-start text-[#FF4242] text-sm font-normal">
                      표준 계약서에 명시될 계약 기간입니다.
                    </div>
                  </div>
                </div>
                <div className={'grid grid-cols-12 mt-4'}>
                  <div
                    className={'col-span-3'}
                  >
                    <div>계약 시작일</div>
                    <div className={'bg-[#EEEEEE] p-2 px-5 rounded-lg'}>{bidListDetail.startDate}</div>
                  </div>
                  <div className={'p-2 text-center mt-6'}>~</div>
                  <div
                    className={'col-span-3'}
                  >
                    <div>계약 종료일</div>
                    <div className={'bg-[#EEEEEE] p-2 px-5 rounded-lg'}>{bidListDetail.endDate}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 text-start">
              <h2>요청사항</h2>
              <div className="p-5 bg-white rounded-lg">
                <div className={'grid grid-cols-4 gap-4'}>
                  <h3>투찰시 필수 제출서류</h3>
                  <div className="flex w-full items-start gap-x-1 col-span-3">
                    <img src={warning_badge} alt="warning_badge" className="mt-0.5"/>
                    <div className="text-start text-[#FF4242] text-sm font-normal">
                      입찰을 등록한 건물 관리주체가 요청한 필수 제출 서류입니다.<br/> 투찰하기 전 아래 서류를 모두 준비해주시기 바랍니다.
                    </div>
                  </div>
                </div>

                <div className="p-5 bg-[#FAFAFA] rounded-lg">
                  <ul className="mb-0 list-disc ml-2">
                    {bidListDetail?.documentRequirementsList?.map((item, index) => (
                      <li className="text-start" key={index}>
                        {item?.documentReqName}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className={'p-5 bg-white rounded-lg mt-4'}>
                <h3>요청사항 설명</h3>
                <div className="p-5 bg-[#FAFAFA] rounded-lg">
                  {bidListDetail?.description}
                </div>
              </div>
              <div className={'p-5 bg-white rounded-lg mt-4'}>
                <div className={'grid grid-cols-4 gap-4'}>
                  <h3>첨부파일</h3>
                  <div className="flex w-full items-start gap-x-1 col-span-3">
                    <img src={warning_badge} alt="warning_badge" className="mt-0.5"/>
                    <div className="text-start text-[#FF4242] text-sm font-normal">
                      투찰 전 아래 첨부파일을 모두 확인하신 후 신중히 투찰해주시기 바랍니다.
                    </div>
                  </div>
                </div>
                <div className="p-5 bg-[#FAFAFA] rounded-lg">
                  <ul className="mb-0 list-disc ml-2">
                    {files?.map((item, index) => (
                      <li className="text-start" key={index}>
                        <a
                          className={'text-blue-500 cursor-pointer'}
                          onClick={() => downloadFile(item)}
                        >{item?.fileName}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

            </div>


            <div className="mt-10">
              <h2>입찰 결과 보기</h2>
              <div className="">
                <div className="flex items-center">
                  <div className="text-lg text-start font-semibold pt-10 pb-3">
                    견적 기준 표준 금액
                  </div>
                  <div className="flex items-start gap-x-1 pt-10 pb-3 ms-10">
                    <img
                      src={warning_badge}
                      alt="warning_badge"
                      className="mt-0.5"
                    />
                    <div>
                      <div className="text-start text-[#FF4242] text-sm font-normal">
                        연면적, 등록 설비에 대해 표준품셈을 기준으로 산정된 금액입니다.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full bg-white p-6 mb-3">
                  <div className="flex justify-between items-center pb-3">
                    <div className="font-normal">유지보수</div>
                    <div className="font-semibold">
                      {
                        totalPrice?.maintenancePrice
                          ? KRWon.format(totalPrice?.maintenancePrice)
                          : 0
                      }
                    </div>
                  </div>

                  <div className="flex justify-between items-center">
                    <div className="font-normal">성능점검</div>
                    <div className="font-semibold">
                      {
                        totalPrice?.performancePrice
                          ? KRWon.format(totalPrice?.performancePrice)
                          : 0
                      }
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center w-full bg-[#3F80EA] rounded-lg px-6 py-4 text-white">
                  <div className="font-bold">견적 기준 표준 금액</div>
                  <div className="font-semibold">
                    <span className="font-normal pr-2">총</span>
                    {
                      totalPrice?.wholePrice
                        ? KRWon.format(totalPrice?.wholePrice)
                        : 0
                    }
                  </div>
                </div>
              </div>
            </div>

            {
              bidListDetail.status === 'ANNOUNCED' && (
                <div className={'mt-10 text-start'}>
                  <h2>공고 마감 기한</h2>
                  <div className={'bg-white rounded-lg p-5 mt-3 text-center'}>
                    <div>
                      <span className={'text-[#424242] text-[32px]'}>마감시간</span>

                      <span className={'text-[#3F80EA] text-[60px] ms-10'}>22</span>
                      <span className={'text-[#424242] text-[20px]'}>시간</span>

                      <span className={'text-[#3F80EA] text-[60px] ms-5'}>46</span>
                      <span className={'text-[#424242] text-[20px]'}>분</span>

                      <span className={'text-[#424242] text-[32px] ms-10'}>남았습니다.</span>
                    </div>
                    <div>
                      <span className={'text-[#424242] text-[32px]'}>투찰 업체 수</span>
                      <span className={'text-[#3F80EA] text-[60px] ms-10'}>3</span>
                      <span className={'text-[#424242] text-[20px]'}>곳</span>
                    </div>
                  </div>
                </div>
              )
            }

            {
              bidListDetail.status === 'ANNOUNCE_ENDED' && (
                <div className={'mt-10 text-start'}>
                  <h2>투찰 업체 목록</h2>
                  <div className={'bg-white rounded-lg p-5 mt-3'}>
                    <div className={'text-[#2AC9A4] border-[#2AC9A4] border-2 rounded-2xl w-40 text-center'}>정보통신공사업증
                    </div>
                    <div className={'flex justify-between items-center my-3'}>
                      <h3>(주) 나도정보통신공사</h3>
                      <h3>{KRWon.format(13570000)}</h3>
                    </div>
                    <div className={'text-[#424242] flex justify-between'}>
                      <span>9년차 | 서울특별시</span>
                      <span><button
                        className={'px-10 py-2 bg-[#3F80EA] disabled:bg-[#DADADA] hover:bg-[#4a8dcc] duration-300 text-base font-extrabold text-white rounded-xl'}>우선순위 협상하기</button></span>
                    </div>
                    <Accordion className={'mt-3'}>
                      <AccordionTab header="요청한 서류보기">
                        <div className={'flex justify-between shadow p-3'}>
                  <span>
                    <i className="pi pi-file-check me-2" style={{fontSize: '1rem'}}></i>
                    사업계획서
                  </span>
                          <button
                            className={'border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-lg px-8'}>다운로드
                          </button>
                        </div>
                        <div className={'flex justify-between shadow p-3 mt-3'}>
                  <span>
                    <i className="pi pi-file-check me-2" style={{fontSize: '1rem'}}></i>
                    통장사본
                  </span>
                          <button
                            className={'border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-lg px-8'}>다운로드
                          </button>
                        </div>
                        <div className={'flex justify-between shadow p-3 mt-3'}>
                  <span>
                    <i className="pi pi-file-check me-2" style={{fontSize: '1rem'}}></i>
                    사업자등록증
                  </span>
                          <button
                            className={'border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-lg px-8'}>다운로드
                          </button>
                        </div>
                        <div className={'flex justify-between shadow p-3 mt-3'}>
                  <span>
                    <i className="pi pi-file-check me-2" style={{fontSize: '1rem'}}></i>
                    회사소개서
                  </span>
                          <button
                            className={'border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-lg px-8'}>다운로드
                          </button>
                        </div>
                      </AccordionTab>
                    </Accordion>
                  </div>

                  <div className={'bg-white rounded-lg p-5 mt-3'}>
                    <div className={'text-[#2AC9A4] border-[#2AC9A4] border-2 rounded-2xl w-40 text-center'}>정보통신공사업증
                    </div>
                    <div className={'flex justify-between items-center my-3'}>
                      <h3>(주) 나도정보통신공사</h3>
                      <h3>{KRWon.format(13570000)}</h3>
                    </div>
                    <div className={'text-[#424242] flex justify-between'}>
                      <span>9년차 | 서울특별시</span>
                      <span><button
                        className={'px-10 py-2 bg-[#3F80EA] disabled:bg-[#DADADA] hover:bg-[#4a8dcc] duration-300 text-base font-extrabold text-white rounded-xl'}>우선순위 협상하기</button></span>
                    </div>
                    <Accordion className={'mt-3'}>
                      <AccordionTab header="요청한 서류보기">
                        <div className={'flex justify-between shadow p-3'}>
                  <span>
                    <i className="pi pi-file-check me-2" style={{fontSize: '1rem'}}></i>
                    사업계획서
                  </span>
                          <button
                            className={'border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-lg px-8'}>다운로드
                          </button>
                        </div>
                        <div className={'flex justify-between shadow p-3 mt-3'}>
                  <span>
                    <i className="pi pi-file-check me-2" style={{fontSize: '1rem'}}></i>
                    통장사본
                  </span>
                          <button
                            className={'border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-lg px-8'}>다운로드
                          </button>
                        </div>
                        <div className={'flex justify-between shadow p-3 mt-3'}>
                  <span>
                    <i className="pi pi-file-check me-2" style={{fontSize: '1rem'}}></i>
                    사업자등록증
                  </span>
                          <button
                            className={'border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-lg px-8'}>다운로드
                          </button>
                        </div>
                        <div className={'flex justify-between shadow p-3 mt-3'}>
                  <span>
                    <i className="pi pi-file-check me-2" style={{fontSize: '1rem'}}></i>
                    회사소개서
                  </span>
                          <button
                            className={'border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-lg px-8'}>다운로드
                          </button>
                        </div>
                      </AccordionTab>
                    </Accordion>
                  </div>

                  <div className={'bg-white rounded-lg p-5 mt-3'}>
                    <div className={'text-[#2AC9A4] border-[#2AC9A4] border-2 rounded-2xl w-40 text-center'}>정보통신공사업증
                    </div>
                    <div className={'flex justify-between items-center my-3'}>
                      <h3>(주) 나도정보통신공사</h3>
                      <h3>{KRWon.format(13570000)}</h3>
                    </div>
                    <div className={'text-[#424242] flex justify-between'}>
                      <span>9년차 | 서울특별시</span>
                      <span><button
                        className={'px-10 py-2 bg-[#3F80EA] disabled:bg-[#DADADA] hover:bg-[#4a8dcc] duration-300 text-base font-extrabold text-white rounded-xl'}>우선순위 협상하기</button></span>
                    </div>
                    <Accordion className={'mt-3'}>
                      <AccordionTab header="요청한 서류보기">
                        <div className={'flex justify-between shadow p-3'}>
                  <span>
                    <i className="pi pi-file-check me-2" style={{fontSize: '1rem'}}></i>
                    사업계획서
                  </span>
                          <button
                            className={'border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-lg px-8'}>다운로드
                          </button>
                        </div>
                        <div className={'flex justify-between shadow p-3 mt-3'}>
                  <span>
                    <i className="pi pi-file-check me-2" style={{fontSize: '1rem'}}></i>
                    통장사본
                  </span>
                          <button
                            className={'border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-lg px-8'}>다운로드
                          </button>
                        </div>
                        <div className={'flex justify-between shadow p-3 mt-3'}>
                  <span>
                    <i className="pi pi-file-check me-2" style={{fontSize: '1rem'}}></i>
                    사업자등록증
                  </span>
                          <button
                            className={'border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-lg px-8'}>다운로드
                          </button>
                        </div>
                        <div className={'flex justify-between shadow p-3 mt-3'}>
                  <span>
                    <i className="pi pi-file-check me-2" style={{fontSize: '1rem'}}></i>
                    회사소개서
                  </span>
                          <button
                            className={'border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-lg px-8'}>다운로드
                          </button>
                        </div>
                      </AccordionTab>
                    </Accordion>
                  </div>
                </div>
              )
            }

            <div className={'py-10'}></div>

          </div>
        </div>
        <div className={'col-span-2'}></div>
      </div>

      <Dialog
        header="건물 설비 현황 수량 요약"
        visible={infraModalvisible}
        style={{width: "50vw"}}
        onHide={() => {
          if (!infraModalvisible) return;
          setInfraModalvisible(false);
        }}
      >
        <div className="card">
          <div className="grid grid-cols-4 gap-x-6 gap-y-3 bg-white rounded-md text-center">
            {infrastructure.map((item, index) => {
              return (
                <div
                  className="w-full bg-[#F5F5F5] border rounded-lg p-3"
                  key={item.infraTypeSeq}
                >
                  <div className="text-lg font-semibold">
                    {item.infraTypeName}
                  </div>
                  <div className="text-xl font-medium mt-2">
                    {item.quantity}
                    <span className="text-sm font-light"> 개</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Dialog>

      <Footer/>
    </section>
  );
}

export default IACServiceBidDetail;
