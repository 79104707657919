import React from "react";

function ParticipantTermsOfUse() {
  return (
    <div className="content spacing text-left terms-of-use-text">
      <h3 className="text-2xl">이용약관</h3>

      <h5 className="mt-3 mb-2 text-xl">제 1 조 목적</h5>
      <p className="text-base">
        투찰회원 약관의 작성 및 배포가 필요합니다!!!!!
      </p>
      <h5 className="mt-3 mb-2 text-xl">제 2 조 서비스의 내용 및 제공</h5>
      <ol data-list-style-type="ko">
        <li>
          회사는 정보통신설비 유지보수 관리 의무 규정(제8조) 및 정보통신설비
          성능점검 의무 규정(제10조)에 의거하여 매년 관리주체가 유지보수관리
          및 성능점검 위탁 용역 업체를 구매하기 위한 전자입찰 서비스를
          제공하고 있습니다.
          <ol>
            <li>
              점검대상 정보통신설비에 대하여 제6조 각 호에 따른 정보통신설비
              유지보수·관리 및 성능점검 대상 현황표 및 정보통신설비 성능점검
              시 검토사항 등을 참고하여 유지보수 관리 및 성능점검을
              수행합니다.
            </li>
          </ol>
        </li>
        <li>
          회사에서 제공하는 전자 입찰 서비스는 다음과 같습니다.
          <ol>
            <li>
              회사에서 거래되거나 거래될 수 있는 용역에 대한 정보의 제공
            </li>
            <li>용역의 계약 체결 업무</li>
          </ol>
        </li>
      </ol>

      <h5 className="mt-3 mb-2 text-xl">제 24 조 회사의 면책</h5>
      <ol data-list-style-type="ko">
        <li>
          회사는 천재지변 또는 이에 준하는 불가항력의 상황으로 인하여 서비스를
          제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.
        </li>
        <li>
          회사는 인터넷 이용자 또는 회원의 귀책사유로 인한 서비스 이용 장애에
          대해서는 책임을 지지 않습니다.
        </li>
        <li>
          회사는 회원간에 자발적으로 이루어진 정보의 교류, 서비스 교류 등으로
          인하여 발생한 손해에 대해서는 일체의 책임을 지지 않습니다.
        </li>
        <li>
          기타 회원이 게재한 정보, 자료, 사실의 정확성 등을 신뢰함으로써 입은
          손해에 대해서 회사는 책임지지 않습니다.
        </li>
        <li>
          "정보통신설비 유지관리 및 성능 점검 대상"의 점검 결과는 정확하지
          않을 수 있습니다.
        </li>
      </ol>
    </div>
  );
}

export default ParticipantTermsOfUse;
