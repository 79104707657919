import axiosInstance from "./utils/axiosInstance"; // accessToken 을 헤더에 포함해서 요청하는 경우 사용한다.

// const BASE_URL = 'http://localhost:8080';
const BASE_URL = "https://bid-api.beta-check.com";

export async function getServiceBidList(param) {
  const response = await axiosInstance.post(`${BASE_URL}/iac/service/bid/list`,
    param,
{
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
}

// function for fetching bid list detail
export const getBidListDetail = async (id) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/iac/service/bid/one`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        bidSeq: id,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching bid list data:", error);
    throw error;
  }
};
export const getBidFiles = async (id) => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/iac/service/bid/one/files`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        bidSeq: id,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching bid list data:", error);
    throw error;
  }
};

export async function saveServiceBid(formData) {
  const response = await axiosInstance.post(`${BASE_URL}/iac/service/bid/save`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  console.log("saveServiceBid response", response);

  return response.data;
}

export async function saveBuildingInfoList(param) {
  const response = await axiosInstance.post(`${BASE_URL}/building/saveList`, param, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}
export async function saveBuildingInfo(param) {
  const response = await axiosInstance.post(`${BASE_URL}/building/save`, param, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function listBuildingInfoDongs(param) {
  const response = await axiosInstance.post(`${BASE_URL}/building/dongList`, param, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function getMyBuildingList(param) {
  const response = await axiosInstance.post(
    `${BASE_URL}/building/list`,
      param,
    {
      headers: {
        // authorization: `Bearer ${getCookie("accessToken")}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
}

export async function getAssignedBuildingList(body) {
  const response = await axiosInstance.post(
    `${BASE_URL}/building/listBuildingForQuotation`,
    body,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
}

export async function getMyBuilding(buildingId, userId) {
  const response = await axiosInstance.get(
    `${BASE_URL}/building/one?buildingId=` + buildingId + '&userId=' + userId,
    {
      headers: {
        // authorization: `Bearer ${getCookie("accessToken")}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
}

export async function getSingleIACInfra(body) {
  const response = await axiosInstance.post(`${BASE_URL}/building/oneIACInfra`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function getlistIACInfra(buildingId, quotationSeq, infraTypeList, searchText) {
  let body = {
    buildingId: parseInt(buildingId),
    quotationSeq: quotationSeq,
    infraTypeList: infraTypeList,
    searchText: searchText,
  };
  const response = await axiosInstance.post(`${BASE_URL}/building/listIACInfra`, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });

  return response.data;
}

export async function removeIACInfra(param) {
  const response = await axiosInstance.post(
    `${BASE_URL}/building/removeIACInfra`,
    param,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
}

export async function getSummaryIACInfra(buildingId, quotationSeq) {
  const response = await axiosInstance.get(
    `${BASE_URL}/building/summaryIACInfra?buildingId=` + buildingId + '&quotationSeq=' + quotationSeq,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
}

export async function getIACInfraTypeBase() {
  const response = await axiosInstance.get(`${BASE_URL}/building/IACInfraTypeBase`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function getQuotationClosedList(userId) {
  const response = await axiosInstance.get(
    `${BASE_URL}/building/quotationClosedList?userId=` + userId,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
}

export async function getDocumentReqBase() {
  const response = await axiosInstance.get(
    `${BASE_URL}/iac/service/bid/documentReqBase`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
}

export async function saveIACInfra(param) {
  const response = await axiosInstance.post(
    `${BASE_URL}/building/saveIACInfra`,
    param,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response.data;
}

export async function updateBuildingQuotationStatus(param) {
  const response = await axiosInstance.post(
    `${BASE_URL}/building/updateBuildingQuotationStatus`,
    param,
    {
      headers: {
        "Content-Type": "application/json ",
      },
    }
  );

  return response.data;
}

export async function requestBuildingQuotation(param) {
  const response = await axiosInstance.post(
    `${BASE_URL}/building/requestBuildingQuotation`,
    param,
    {
      headers: {
        "Content-Type": "application/json ",
      },
    }
  );

  return response.data;
}
export async function getQuotationList(param) {
  const response = await axiosInstance.post(
      `${BASE_URL}/building/listQuotation`,
      param,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
  );
  return response.data;
}
export async function getQuotationListByBuildingId(buildingId) {
  const response = await axiosInstance.get(
      `${BASE_URL}/building/listQuotationByBuildingId?buildingId=` + buildingId,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
  );
  return response.data;
}
export async function getOneIACQuotation(param) {
  const response = await axiosInstance.post(
      `${BASE_URL}/building/getOneIACQuotation`,
      param,
      {
        headers: {
          "Content-Type": "application/json ",
        },
      }
  );
  return response.data;
}
export async function getBuildingDongList(buildingId) {
  const response = await axiosInstance.get(
      `${BASE_URL}/building/listBuildingDong?buildingId=` + buildingId,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
  );
  return response.data;
}
export async function downloadBidFile(fileSeq, fileName){
  let url = `${BASE_URL}/iac/service/bid/downloadFile`;
  if (fileSeq !== undefined) {
    url += `?fileSeq=${fileSeq}`;
  }
  const response = await axiosInstance
      .get(url, {
        responseType: "blob"
        , headers: {
          "Content-Type": 'application/json'
        },
      })
      .then((response) => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");

        a.href = fileUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(fileUrl);
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error("파일 다운로드 오류:", error);
      });
}