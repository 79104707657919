import React, { useEffect, useState } from "react";
import {getBusinessUser} from "../../services/businessUserService";
import {downloadBUFile, getBusinessUserFiles} from "../../services/authoriedUserService";

function MyInfoCompany(props: any) {
  const [businessUser, setBusinessUser] = useState({
    userId: props.props.userId,
    email: props.props.email,
    businessRegistrationNumber: "",
    representativeName: "",
    applyStatus: "",
    businessName: "",
    corporationRegistrationNumber: "",
    address: "",
    establishDate: "",
    managerEmail: "",
    managerPhone: "",
    managerName: "",
    shippingAddress: "",
    name: props.props.fullName,
    phone: props.props.phone,
    accessToken: "",
    bankAccOwner:"",
    bankAccNumber:"",
    bankName:""
  });

  const [fileList, setFileList] = useState([
    {
      userId: "",
      fileName: "",
      fileUrl: "",
      fileSize: "",
      seq: 0,
      fileType: "",
      fileTypeName: "",
    },
  ]);

  const [businessRegistrationFile, setBusinessRegistrationFile] = useState({
    userId: "",
    fileName: "",
    fileUrl: "",
    fileSize: "",
    seq: 0,
    fileType: "",
    fileTypeName: "",
  });
  const [bankAccImage, setBankAccImage] = useState({
    userId: "",
    fileName: "",
    fileUrl: "",
    fileSize: "",
    seq: 0,
    fileType: "",
    fileTypeName: "",
  });

  const downloadFile = (file: any) => {
    console.log(file);
    downloadBUFile(file.seq, file.fileName).then();
  };

  const changeModifyMode = () => {
    // 수정 모드로 변경
    props.setIsModifyMode(true);
  };

  useEffect(() => {
    getBusinessUser(props.props.userId).then((data) => {
      setBusinessUser({
        userId: props.props.userId,
        email: props.props.email,
        businessRegistrationNumber: data.businessRegistrationNumber,
        representativeName: data.representativeName,
        applyStatus: data.applyStatus,
        businessName: data.businessName,
        corporationRegistrationNumber: data.corporationRegistrationNumber,
        address: data.address,
        establishDate: data.establishDate,
        managerEmail: data.managerEmail,
        managerPhone: data.managerPhone,
        managerName: data.managerName,
        shippingAddress: data.shippingAddress,
        name: props.props.fullName,
        phone: props.props.phone,
        accessToken: props.props.accessToken,
        bankAccNumber: data.bankAccNumber,
        bankAccOwner: data.bankAccOwner,
        bankName: data.bankName
      });
    });

    getBusinessUserFiles(props.props.userId).then((data) => {
      setFileList(data);
      console.log("fileList", data);

      for (let file of data) {
        if (file.fileType === 'Business') {
          setBusinessRegistrationFile(file);
        } else if (file.fileType === 'BankAccImage') {
          setBankAccImage(file);
        }
      }
    });
  }, []);

  return (
    <>
      <div className={'grid grid-cols-1 lg:grid-cols-3 gap-4 mt-4'}>
        <div className={'h-96 mb-4 border rounded-xl shadow-lg p-4'}>
          <ul>
            <li>우리 서비스에서 용역 발주는 사업자등록증 인증을 받은 기업회원만 가능</li>
            <li>용역 수행이 가능한 조건의 증명 서류를 제출한 후 인증 받으면 역할회원 가능</li>
            <li>역할회원은 가입 후 마이페이지에서도 인증 받을 수 있음</li>
          </ul>
        </div>
        <div className={'grid grid-cols-subgrid'}>
          <div className="mb-3 p-4 border rounded-xl shadow-lg">
            <h2 className="mb-2 font-semibold">기업정보</h2>

            <hr/>

            <div className="mb-3">
              <label
                htmlFor="businessRegistrationNumber"
                className="block mt-8 mb-1 font-semibold"
              >
                사업자등록번호
              </label>
              <div className="grid grid-cols-3 gap-3">
                <div className="col-span-3">
                  <input
                    type="text"
                    id="businessRegistrationNumber"
                    value={businessUser.businessRegistrationNumber}
                    className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="mb-3 gap-3">
              <label className="block mt-8 mb-1 font-semibold">
                필수 파일
              </label>

              <div
                className={'h-16 mt-3 bg-opacity-20 ps-4 grid grid-cols-3 gap-3 content-center rounded-md'}
                style={{
                  backgroundColor: '#3F80EA36'
                }}
              >
                <div className={'col-span-3'}>
                  <div className={'grid grid-cols-2'}>
                    <span className={'w-full font-semibold'}>
                      사업자등록증
                    </span>
                    <span>
                      <button
                        onClick={()=>{
                          downloadFile(businessRegistrationFile);
                        }}
                        type="button"
                        className="px-3 bg-white rounded-2xl border border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white hover:border-transparent font-semibold"
                      >
                        승인된 파일보기
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="my-3 gap-3 justify-between">
              <div
                className="h-16 mt-3 bg-opacity-20 ps-4 grid grid-cols-3 gap-3 content-center rounded-md"
                style={{
                  backgroundColor: '#3F80EA36'
                }}
              >
                <div className={'col-span-3'}>
                  <div className={'grid grid-cols-2'}>
                    <span className={'font-semibold'}>
                      통장사본
                    </span>
                    <span>
                      <button
                        onClick={()=>{
                          downloadFile(bankAccImage);
                        }}
                        type="button"
                        className="px-3 bg-white rounded-2xl border border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white hover:border-transparent font-semibold"
                      >
                        승인된 파일보기
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-3 p-4 border rounded-xl shadow-lg">
          <h2 className="mb-2 font-semibold">담당자 정보</h2>

            <hr/>
            <div className="my-3 mt-5">
              <label htmlFor="managerName" className="block mb-1 font-semibold">
                담당자 이름
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  id="managerName"
                  value={businessUser.managerName}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>

            <div className="mb-3 mt-5">
              <label
                htmlFor="managerEmail"
                className="block mb-1 font-semibold"
              >
                담당자 이메일
              </label>
              <div className="col-span-3">
                <input
                  type="email"
                  id="managerEmail"
                  value={businessUser.managerEmail}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>

            <div className="mb-3 mt-5">
              <label
                htmlFor="managerPhone"
                className="block mb-1 font-semibold"
              >
                담당자 전화번호
              </label>
              <div className="col-span-2">
                <input
                  type="tel"
                  id="managerPhone"
                  value={businessUser.managerPhone}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div className={'grid grid-cols-subgrid'}>
          <div className="mb-3 p-4 border rounded-xl shadow-lg">
            <h2 className="mb-2 font-semibold">상세 정보</h2>

            <hr/>
            <div className="my-3">
              <label htmlFor="corporationRegistrationNumber" className="block mb-1 font-semibold">
                법인등록번호
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  value={businessUser.corporationRegistrationNumber}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>

            <div className="mb-3">
              <label
                htmlFor="establishDate"
                className="block mb-1 font-semibold"
              >
                설립일
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  value={businessUser.establishDate}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>

            <div className="mb-3">
              <label
                htmlFor="managerPhone"
                className="block mb-1 font-semibold"
              >
                기업명
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  value={businessUser.businessName}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="representativeName"
                className="block mb-1 font-semibold"
              >
                대표자명
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  value={businessUser.representativeName}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="address"
                className="block mb-1 font-semibold"
              >
                주소
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  value={businessUser.address}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="managerPhone"
                className="block mb-1 font-semibold"
              >
                예금주명
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  value={businessUser.bankAccOwner}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="managerPhone"
                className="block mb-1 font-semibold"
              >
                계좌번호
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  value={businessUser.bankAccNumber}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="managerPhone"
                className="block mb-1 font-semibold"
              >
                은행
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  value={businessUser.bankName}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 mb-5 text-center">
        <button
          className="hover:opacity-90 transition duration-300 ease-in-out bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white font-bold py-2 px-4 rounded w-full lg:w-1/3"
          onClick={changeModifyMode}
        >
          기업정보 수정 요청하기
        </button>
      </div>
    </>
  );
}

export default MyInfoCompany;
