import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import { isEmailExists, isIdExists, signUp } from "../../services/authService";
import { validateEmailFormat } from "../../services/utils/formatUtil";
import { NiceCommonForm } from "../NiceCommonForm";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "../../styles/personReg.css";
import CommonTermsOfUse from "../CommonTermsOfUse";
import CommonPrivatePolicy from "../CommonPrivacyPolicy";
import { useLoading } from '../LoadingContext';
import AlertDialog from "../AlertDialog";


function PersonRegistration(props:any) {
  const {setLoading} = useLoading();
  const [visible, setVisible] = useState<boolean>(false);
  const [visible2, setVisible2] = useState<boolean>(false);
  const [visible3, setVisible3] = useState<boolean>(false);

  const passwordOver8 = useRef(null);
  const passwordSpecialChar = useRef(null);
  const passwordNum = useRef(null);
  const passwordUpperCase = useRef(null);
  const passwordLowerCase = useRef(null);
  const passwordDiff = useRef(null);

  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [agreementYn, setAgreementYn] = useState(false);
  const [agreementYn2, setAgreementYn2] = useState(false);

  const [isUserIdValid, setIsUserIdValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const userIdCheckText = useRef(null);
  const emailCheckText = useRef(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordConfirmValid, setIsPasswordConfirmValid] = useState(false);

  const niceCommonFormRef = useRef(null);

  const passwordRef = useRef(null);
  const passwordConfirmRef = useRef(null);
  const handleShowPasswordConfirm = async (e: any) => {
    const passwordConfirm = passwordConfirmRef.current;
    if (passwordConfirm === null) return;
    setShowPasswordConfirm(!showPasswordConfirm);
    if (!showPasswordConfirm) {
      // @ts-ignore
      passwordConfirm.type = "text";
    } else {
      // @ts-ignore
      passwordConfirm.type = "password";
    }
  };
  const handleShowPassword = (e: any) => {
    const password = passwordRef.current;
    if (password === null) return;

    setShowPassword(!showPassword);
    if (!showPassword) {
      // @ts-ignore
      password.type = "text";
    } else {
      // @ts-ignore
      password.type = "password";
    }
  };

  const navigate = useNavigate();
  const goLogin = () => {
    navigate("/userSignIn");
  };

  const handleChangeEmail = (e: any) => {
    if (e.target.value.length > 254) {
      showAlert('이메일은 254자까지로 제한되어 있습니다.');
      return;
    }
    setIsEmailValid(false);
    // @ts-ignore
    emailCheckText.current.innerText = null;
    setEmail(e.target.value);
  };
  const handleChangeUserId = (e: any) => {
    if (e.target.value.length > 30) {
      showAlert('아이디는 30자까지로 제한되어 있습니다.');
      return;
    }
    setIsUserIdValid(false);
    // @ts-ignore
    userIdCheckText.current.innerText = null;
    setUserId(e.target.value);
  };

  const validateForm = () => {
    if (userId === "") {
      showAlert("아이디가 없습니다.");
      return false;
    }
    if (!isUserIdValid) {
      showAlert("아이디가 유효하지 않습니다.")
      return false;
    }
    if (password === "") {
      showAlert("비밀번호가 없습니다.");
      return false;
    }
    if (password !== passwordConfirm) {
      showAlert("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
      return false;
    }
    if (email === "") {
      showAlert("이메일이 없습니다.");
      return false;
    }
    if (!isEmailValid) {
      showAlert("이메일이 유효하지 않습니다.");
      return false;
    }
    if (!validateEmailFormat(email)) {
      showAlert("이메일 형식이 맞지 않습니다.");
    }
    if (!agreementYn) {
      showAlert("공통 이용 약관 동의를 해야 합니다.");
      return false;
    }
    if (!agreementYn2) {
      showAlert("개인정보 수집 및 이용 동의를 해야 합니다.");
      return false;
    }
    if (!fullName) {
      showAlert("본인인증을 완료해야 합니다.");
      return false;
    }
    return true;
  };

  const registerUser = () => {
    if (!validateForm()) {
      return;
    }

    props.setUserObj({
      userId: userId,
      email: email,
      password: password,
      name: fullName,
      phone: phone
    });
    props.setStep(2);

  };

  const isUserExists = () => {
    if (!userId) {
      showAlert('아이디가 없습니다.');
      return;
    }
    if (userId.length > 30) {
      showAlert('아이디는 30자까지로 제한되어 있습니다.');
      return;
    }
    setLoading(true);
    // ID 중복체크
    isIdExists(userId).then((existYn) => {
      if (existYn) {
        // 중복된 ID 가 존재
        // @ts-ignore
        userIdCheckText.current.className = "text-red-500 text-sm";
        // @ts-ignore
        userIdCheckText.current.innerText = "이미 사용중인 아이디 입니다.";
      } else {
        if (userIdCheckText.current) {
          setIsUserIdValid(true);
          // @ts-ignore
          userIdCheckText.current.className = "text-green-500 text-sm";
          // @ts-ignore
          userIdCheckText.current.innerText = "사용 가능한 아이디 입니다.";
        }
      }
    }).finally(()=>{
      setLoading(false);
    });
  };
  const isEmailAddressExists = () => {
    if (!email) {
      showAlert('이메일이 없습니다.');
      return;
    }
    if (email.length > 254) {
      showAlert('이메일은 254자까지로 제한되어 있습니다.');
      return;
    }
    if (!validateEmailFormat(email)) {
      showAlert('이메일 형식이 맞지 않습니다.');
      return;
    }

    setLoading(true);
    // Email 중복체크
    isEmailExists(email).then((existYn) => {
      if (existYn) {
        // 중복된 ID 가 존재
        // @ts-ignore
        emailCheckText.current.className = "text-red-500 text-sm";
        // @ts-ignore
        emailCheckText.current.innerText = "이미 사용중인 이메일 입니다.";
      } else {
        if (emailCheckText.current) {
          setIsEmailValid(true);
          // @ts-ignore
          emailCheckText.current.className = "text-green-500 text-sm";
          // @ts-ignore
          emailCheckText.current.innerText = "사용 가능한 이메일 입니다.";
        }
      }
    }).finally(()=>{
      setLoading(false);
    });
  };
  const passwordRuleCheck = (event: any) => {
    // 8자 이상, 숫자 포함, 소문자 포함, 대문자 포함, 특수문자 포함
    // console.log('Here is password ', event.target.value);
    const specialRule = /[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/g; // 특수문자 포함여부
    const numberRule = /[0-9]/g; // 숫자 포함여부
    const upperCaseRule = /[A-Z]/g; // 대문자 포함여부
    const lowerCaseRule = /[a-z]/g; // 소문자 포함여부

    let isPasswordOver8 = false;
    let isPasswordSpecialChar = false;
    let isPasswordNum = false;
    let isPasswordUpperCase = false;
    let isPasswordLowerCase = false;

    if (event.target.value.length > 40) {
      showAlert('비밀번호는 40자로 제한되어 있습니다.');
      return;
    }

    // 8자 이상
    if (event.target.value.length >= 8) {
      // @ts-ignore
      passwordOver8.current.className = "text-green-500";
      isPasswordOver8 = true;
    } else {
      // @ts-ignore
      passwordOver8.current.className = "text-red-500";
      isPasswordOver8 = false;
    }

    if (specialRule.test(event.target.value)) {
      // 특수문자가 포함되어 있으면
      // @ts-ignore
      passwordSpecialChar.current.className = "text-green-500";
      isPasswordSpecialChar = true;
    } else {
      // @ts-ignore
      passwordSpecialChar.current.className = "text-red-500";
      isPasswordSpecialChar = false;
    }

    if (numberRule.test(event.target.value)) {
      // 숫자가 포함되어 있으면
      // @ts-ignore
      passwordNum.current.className = "text-green-500";
      isPasswordNum = true;
    } else {
      // @ts-ignore
      passwordNum.current.className = "text-red-500";
      isPasswordNum = false;
    }

    if (upperCaseRule.test(event.target.value)) {
      // 대문자가 포함되어 있으면
      // @ts-ignore
      passwordUpperCase.current.className = "text-green-500";
      isPasswordUpperCase = true;
    } else {
      // @ts-ignore
      passwordUpperCase.current.className = "text-red-500";
      isPasswordUpperCase = false;
    }

    if (lowerCaseRule.test(event.target.value)) {
      // 소문자가 포함되어 있으면
      // @ts-ignore
      passwordLowerCase.current.className = "text-green-500";
      isPasswordLowerCase = true;
    } else {
      // @ts-ignore
      passwordLowerCase.current.className = "text-red-500";
      isPasswordLowerCase = false;
    }

    if (Object.is(passwordConfirm, event.target.value)) {
      // @ts-ignore
      passwordDiff.current.style.setProperty("display", "none");
      setIsPasswordConfirmValid(true);
    } else {
      // @ts-ignore
      passwordDiff.current.style.setProperty("display", "block");
      // @ts-ignore
      passwordDiff.current.className = "text-red-500";
      setIsPasswordConfirmValid(false);
    }

    setPassword(event.target.value);

    if (isPasswordOver8
      && isPasswordSpecialChar
      && isPasswordNum
      && isPasswordUpperCase
      && isPasswordLowerCase
    ) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
  };

  const passwordConfirmCheck = (event: any) => {
    if (event.target.value.length > 40) {
      showAlert('비밀번호는 40자로 제한되어 있습니다.');
      return;
    }

    // console.log('passwordConfirm', event.target.value);
    if (Object.is(password, event.target.value)) {
      // @ts-ignore
      passwordDiff.current.style.setProperty("display", "none");
      setIsPasswordConfirmValid(true);
    } else {
      // @ts-ignore
      passwordDiff.current.style.setProperty("display", "block");
      // @ts-ignore
      passwordDiff.current.className = "text-red-500";
      setIsPasswordConfirmValid(false);
    }
    setPasswordConfirm(event.target.value);
  };

  const nicePhoneValidation = () => {
    // @ts-ignore
    niceCommonFormRef.current.niceFormSubmit();
  };
  const footerContent = (
    <div className="">
      <h3 className="invisible">Footer</h3>
    </div>
  );

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const showAlert = (message:string) => {
    setAlertMessage(message);
    setAlertVisible(true);
  };
  const hideAlert = () => {
    setAlertVisible(false);
  };

  useEffect(() => {

    if (props.userId !== '') { // step2 에서 이전버튼으로 돌아왔을때
      setUserId(props.userId);
      setEmail(props.email);
      setPassword(props.password);
      setPasswordConfirm(props.password);
      setFullName(props.name);
      setPhone(props.phone);

      setAgreementYn(true);
      setAgreementYn2(true);
      setIsEmailValid(true);
      setIsUserIdValid(true);
      setIsPasswordValid(true);
      setIsPasswordConfirmValid(true);

      // @ts-ignore
      userIdCheckText.current.className = "text-green-500 text-sm";
      // @ts-ignore
      userIdCheckText.current.innerText = "사용 가능한 아이디 입니다.";
      // @ts-ignore
      emailCheckText.current.className = "text-green-500 text-sm";
      // @ts-ignore
      emailCheckText.current.innerText = "사용 가능한 이메일 입니다.";

      // @ts-ignore
      passwordOver8.current.className = "text-green-500";
      // @ts-ignore
      passwordSpecialChar.current.className = "text-green-500";
      // @ts-ignore
      passwordNum.current.className = "text-green-500";
      // @ts-ignore
      passwordUpperCase.current.className = "text-green-500";
      // @ts-ignore
      passwordLowerCase.current.className = "text-green-500";


    }

  }, []);

  return (
    <>
      <h1 className="pt-10 my-12 App">회원가입</h1>
      <div className="min-h-screen">
        <div className={'grid grid-cols-1 lg:grid-cols-3 mx-4'}>
          <div className={'h-96 mx-4 mb-4 border rounded-xl shadow-lg p-4'}>
            <ul>
              <li>우리 서비스에서 용역 발주는 사업자등록증 인증을 받은 기업회원만 가능</li>
              <li>용역 수행이 가능한 조건의 증명 서류를 제출한 후 인증 받으면 역할회원 가능</li>
              <li>역할회원은 가입 후 마이페이지에서도 인증 받을 수 있음</li>
            </ul>
          </div>
          <div className={'grid grid-cols-subgrid mx-4 lg:mx-0'}>
            <section>
              {/* // agreements - (1st portion) */}
              <div className="mb-3 p-4 border rounded-xl shadow-lg">
                <h3 className="mb-2 font-semibold">약관동의</h3>

                <hr/>
                {/* first checkbox  */}
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <div className="inline-flex items-center">
                      <label
                        className="relative flex items-center mt-4 rounded-full cursor-pointer"
                        htmlFor="agreement"
                      >
                        <input
                          type="checkbox"
                          className="peer relative appearance-none w-5 h-5 border rounded-md border-blue-gray-200 cursor-pointer transition-all before:content[''] before:block before:bg-blue-gray-500 before:w-12 before:h-12 before:rounded-full before:absolute before:top-2/4 before:left-2/4 before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 hover:before:opacity-10 before:transition-opacity"
                          id="agreement"
                          checked={agreementYn}
                          onChange={(event) => setAgreementYn(event.target.checked)}
                        />

                        <span
                          className="absolute text-gray-500 transition-opacity opacity-100 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-circle-check"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="gray"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"/>
                        <path d="M9 12l2 2l4 -4"/>
                      </svg>
                    </span>

                        <span
                          className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-circle-check"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="green"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"/>
                        <path d="M9 12l2 2l4 -4"/>
                      </svg>
                    </span>
                      </label>
                    </div>

                    <h3 className="mt-3">(필수) 공통 이용 약관</h3>
                  </div>

                  <div className="mt-3">
                    <a
                      onClick={() => setVisible(true)}
                      className="underline cursor-pointer"
                    >
                      내용보기
                    </a>
                  </div>
                </div>

                {/* 2nd checkbox  */}
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <div className="inline-flex items-center">
                      <label
                        className="relative flex items-center mt-4 rounded-full cursor-pointer"
                        htmlFor="agreement"
                      >
                        <input
                          type="checkbox"
                          className="peer relative appearance-none w-5 h-5 border rounded-md border-blue-gray-200 cursor-pointer transition-all before:content[''] before:block before:bg-blue-gray-500 before:w-12 before:h-12 before:rounded-full before:absolute before:top-2/4 before:left-2/4 before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 hover:before:opacity-10 before:transition-opacity"
                          id="agreement"
                          checked={agreementYn2}
                          onChange={(event) =>
                            setAgreementYn2(event.target.checked)
                          }
                        />

                        <span
                          className="absolute text-gray-500 transition-opacity opacity-100 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-circle-check"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="gray"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"/>
                        <path d="M9 12l2 2l4 -4"/>
                      </svg>
                    </span>

                        <span
                          className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-circle-check"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="green"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"/>
                        <path d="M9 12l2 2l4 -4"/>
                      </svg>
                    </span>
                      </label>
                    </div>

                    <h3 className="mt-3">(필수) 개인정보 수집 및 이용</h3>
                  </div>

                  <div className="mt-3">
                    <a
                      onClick={() => setVisible2(true)}
                      className="underline cursor-pointer"
                    >
                      내용보기
                    </a>
                  </div>
                </div>
              </div>

              {/* 2nd portion  */}
              <div className="mb-3 p-4 border rounded-xl shadow-lg">
                <h3 className="mb-2 font-semibold">가입정보</h3>

                <hr/>

                <div className="my-4">
                  <label htmlFor="userId" className="block mb-1 font-semibold">
                    아이디<span className="text-red-500">*</span>
                  </label>
                  <div className="grid grid-cols-3 gap-3">
                    <div className={'col-span-2'}>
                      <input
                        id="userId"
                        type="text"
                        value={userId}
                        maxLength={40}
                        placeholder="아이디를 입력해 주세요."
                        onChange={handleChangeUserId}
                        className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                      />
                    </div>
                    <div className={''}>
                      <button
                        onClick={isUserExists}
                        className="w-full h-14 px-2 rounded-xl border border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white hover:border-transparent font-semibold"
                      >
                        중복체크
                      </button>
                    </div>
                  </div>
                  <div ref={userIdCheckText} className="hidden text-sm"></div>
                </div>

                <div className="mb-3">
                  <label htmlFor="password" className="block mb-1 font-semibold">
                    비밀번호<span className="text-red-500">*</span>
                  </label>
                  <div className="flex relative">
                    <input
                      id="password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={passwordRuleCheck}
                      ref={passwordRef}
                      placeholder="비밀번호를 입력해주세요."
                      className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                    />
                    <button
                      type="button"
                      onClick={handleShowPassword}
                      className="absolute right-2 top-5 text-gray-500"
                    >
                      {showPassword ? (
                        <i className="pi pi-eye text-2xl"/>
                      ) : (
                        <i className="pi pi-eye-slash text-2xl"/>
                      )}
                    </button>
                  </div>
                  <div className="text-sm mt-1">
                <span ref={passwordOver8} className="text-red-500">
                  8자 이상 |{" "}
                </span>
                    <span ref={passwordSpecialChar} className="text-red-500">
                  특수 문자 |{" "}
                </span>
                    <span ref={passwordNum} className="text-red-500">
                  숫자 |{" "}
                </span>
                    <span ref={passwordUpperCase} className="text-red-500">
                  대문자 |{" "}
                </span>
                    <span ref={passwordLowerCase} className="text-red-500">
                  소문자
                </span>
                  </div>
                </div>

                <div className="mb-4">
                  <label
                    htmlFor="passwordConfirm"
                    className="block mb-1 font-semibold"
                  >
                    비밀번호 확인<span className="text-red-500">*</span>
                  </label>
                  <div className="flex relative">
                    <input
                      id="passwordConfirm"
                      type={showPasswordConfirm ? "text" : "password"}
                      value={passwordConfirm}
                      onChange={passwordConfirmCheck}
                      ref={passwordConfirmRef}
                      placeholder="비밀번호를 입력해주세요."
                      className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                    />
                    <button
                      type="button"
                      onClick={handleShowPasswordConfirm}
                      className="absolute right-2 top-5 text-gray-500"
                    >
                      {showPasswordConfirm ? (
                        <i className="pi pi-eye text-2xl"/>
                      ) : (
                        <i className="pi pi-eye-slash text-2xl"/>
                      )}
                    </button>
                  </div>
                  <div ref={passwordDiff} className="hidden text-red-500 text-sm">
                    비밀번호가 일치하지 않습니다.
                  </div>
                </div>

                <div className="mb-5">
                  <label htmlFor="email" className="block mb-1 font-semibold">
                    이메일<span className="text-red-500">*</span>
                  </label>
                  <div className="grid grid-cols-3 gap-3">
                    <div className={'col-span-2'}>
                      <input
                        id="email"
                        type="email"
                        value={email}
                        onChange={handleChangeEmail}
                        placeholder="이메일을 입력해주세요."
                        className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                      />
                    </div>
                    <div className={''}>
                      <button
                        onClick={isEmailAddressExists}
                        className="w-full h-14 px-2 rounded-xl border border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white hover:border-transparent font-semibold"
                      >
                        중복체크
                      </button>
                    </div>
                  </div>
                  <div ref={emailCheckText} className="hidden text-sm"></div>
                </div>
              </div>

              {/* 3rd portion  */}
              <div className="mb-3 p-4 border rounded-xl shadow-lg">
                <div className="flex justify-between mb-3">
                  <h3 className="font-semibold">본인인증</h3>
                </div>

                <hr/>

                {!fullName && (
                  <div className={'mt-3'}>
                    <button
                      type="button"
                      onClick={nicePhoneValidation}
                      className="w-full h-14 px-2 rounded-xl border border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white hover:border-transparent font-semibold"
                    >
                      휴대폰 본인인증
                    </button>
                  </div>
                )}

                <div className="my-3">
                  <label htmlFor="fullName" className="block mb-1 font-semibold">
                    이름<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="fullName"
                    type="text"
                    value={fullName}
                    disabled
                    className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  />
                </div>

                <div className="mb-5">
                  <label htmlFor="phone" className="block mb-1 font-semibold">
                    전화번호<span className="text-red-500">*</span>
                  </label>
                  <input
                    id="phone"
                    type="tel"
                    value={phone}
                    disabled
                    className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  />
                </div>
              </div>

              <div className="pt-4 pb-24">
                <button
                  type="button"
                  disabled={!fullName || !phone || !agreementYn || !agreementYn2 || !isUserIdValid || !isEmailValid || !isPasswordValid || !isPasswordConfirmValid}
                  onClick={registerUser}
                  className="shadow-lg w-full h-14 bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white py-2.5 rounded-xl disabled:bg-gray-500 disabled:from-gray-500 disabled:to-gray-500 disabled:border-none disabled:cursor-not-allowed"
                >
                  다음
                </button>
              </div>
            </section>
          </div>
          {/*<div className={'grid grid-cols-subgrid'}>*/}
          {/*  <div className={'h-96 mx-4 border rounded-xl shadow-lg p-4'}>7</div>*/}
          {/*</div>*/}
        </div>
        <NiceCommonForm ref={niceCommonFormRef} setFullName={setFullName} setPhone={setPhone}/>
      </div>

      {/* 1st dialog */}
      <Dialog
        header="공통 이용 약관"
        headerClassName="bg-primary text-white"
        footer={footerContent}
        visible={visible}
        style={{width: "95vw"}}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
        }}
      >
        <div className="my-4">
          <CommonTermsOfUse/>
        </div>
      </Dialog>

      {/* 2nd dialog */}
      <Dialog
        header="개인정보 수집 및 동의"
        headerClassName="bg-primary text-white"
        footer={footerContent}
        visible={visible2}
        style={{width: "95vw"}}
        onHide={() => {
          if (!visible2) return;
          setVisible2(false);
        }}
      >
        <div className="my-4">
          <CommonPrivatePolicy/>
        </div>
      </Dialog>

      {/* 3rd dialog */}
      <Dialog
        visible={visible3}
        modal
        onHide={() => {
          if (!visible3) return;
          setVisible3(false);
        }}
        content={({hide}) => (
          <div className="p-10 gap-4 bg-white rounded-md">
            <div className="space-y-16">
              <h3 className="text-4xl text-center font-semibold">
                회원가입이 완료되었습니다.
              </h3>

              <Button
                onClick={(e) => {
                  goLogin();
                  hide(e);
                }}
                className="w-full hover:opacity-90 transition duration-300 ease-in-out active:bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white py-2.5 rounded-xl disabled:bg-secondary disabled:border-none"
                label="확인"
              />
            </div>
          </div>
        )}
      ></Dialog>

      <AlertDialog
        visible={alertVisible}
        onHide={hideAlert}
        message={alertMessage}
      />
    </>
  );
}

export default PersonRegistration;
