import React from "react";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  return (
    <div>
      <hr className="mt-0" />
      <div className={'grid grid-cols-12'}>
        <div className={'col-span-2'}></div>
        <div className={'col-span-8'}>
          <div className="text-left mt-10 mb-10">
            <div>
          <span
            className="font-bold cursor-pointer"
            onClick={() => navigate("/privatePolicy")}
          >
            개인정보 처리방침
          </span>
              <span className="mx-3 text-gray-500">|</span>
              <span
                className="cursor-pointer"
                onClick={() => navigate("/termsOfUse")}
              >
            이용약관
          </span>
            </div>
            <div className="text-sm mt-3 text-gray-500">
              <span className="mr-2">상호명: 사이언스빌드</span>
              <span>사업장소재지: 서울시 중랑구 신내동</span>
            </div>
            <div className="text-sm text-gray-500">
              <span className="mr-2">사업자등록번호: 123-12-12345</span>
              <span>통신판매업신고: 2024-서울중랑-11111</span>
            </div>
            <div className="text-sm text-gray-500">
              <span className="mr-2">이메일: support@mygongsa.com</span>
              {/*<span className="mr-2">대표: 정우진</span>*/}
              {/*<span className="mr-2">개인정보 보호책임자: 이형주</span>*/}
              <span>호스팅서비스: Amazon Web Service</span>
            </div>
          </div>
        </div>
        <div className={'col-span-2'}></div>
      </div>
    </div>
  );
}

export default Footer;
