import { Menubar } from "primereact/menubar";
import { MenuItem } from "primereact/menuitem";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logout_icon from "../../src/images/svg/logout_icon.svg";
import { UserInfoContext } from "../App";
import logo from "../logo.png";
import { OverlayPanel } from "primereact/overlaypanel";
import { nanoid } from "nanoid";

function Header() {
  const op = useRef(null);
  // @ts-ignore
  let { userInfo, logout } = useContext(UserInfoContext);
  const navigate = useNavigate();

  const itemRenderer = (item: any) => (
    <button
      onClick={
        (e) => {
          e.preventDefault();
          navigate(item.url, {state: nanoid()});
        }
      }
      className={`flex items-center text-lg font-bold p-3 ${
        window.location.pathname === item.url ||
        window.location.pathname.includes(item.url)
          ? "signature-color"
          : ""
      }`}
    >
      <span className="mx-2">{item.label}</span>
    </button>
  );

  const dropDownIcon = () => <img src={logout_icon} alt="logout_icon" />;

  const [items, setItems] = useState<MenuItem[]>([
    {
      label: "건물등록·조회·견적",
      url: "/myBuildingList",
      template: itemRenderer,
    },
    {
      label: "입찰등록",
      url: "/IACServiceBidSelectBuildings",
      template: itemRenderer,
    },
    {
      label: "입찰공고",
      url: "/IACServiceBidList",
      template: itemRenderer,
    },
    {
      label: "고객문의",
      url: "/asking",
      template: itemRenderer,
    },
  ]);

  const logoutItems: MenuItem[] = [
    {
      label: "마이페이지",
      url: "/myInfo",
    },
    {
      label: "로그아웃",
      command: () => {
        logout();
      },
    },
  ];

  const loginItems: MenuItem[] = [
    {
      label: "로그인",
      url: "/userSignIn",
    },
  ];

  const start = (
    <img
      onClick={() => navigate("/")}
      src={logo}
      alt="베타점검"
      width={120}
      className="m-3 cursor-pointer"
    ></img>
  );
  const end = (
    <>
      {userInfo ? (
        <div className="flex items-center gap-3">
          <div className="flex items-center divide-x-2">
            <span className="pr-2">
              {userInfo.userId}
            </span>
            <Link className="px-2"
                  onClick={()=>{}}
                  to={"/myInfo"}
            >
              마이페이지
            </Link>
            <Link className="pl-2"
              onClick={()=>logout()}
                  to={"/"}
            >
              로그아웃
            </Link>
          </div>
          <OverlayPanel ref={op}>
            {logoutItems.map((item) => (
              <div className="flex flex-col gap-y-4" key={item.label}>
                {item.label === "로그아웃" ? (
                  <button
                    key={item.label}
                    // @ts-ignore
                    onClick={item.command}
                    className="w-full py-1"
                  >
                    {item.label}
                  </button>
                ) : (
                  <Link
                    key={item.label}
                    // @ts-ignore
                    to={item.url}
                    className="w-full py-1"
                  >
                    {item.label}
                  </Link>
                )}
              </div>
            ))}
          </OverlayPanel>
        </div>
      ) : (
        <div className="flex items-center divide-x-2">
          <Link to="/userSignIn" className="pr-2">
            로그인
          </Link>
          <Link to="/userRegistration" className="pl-2">
            회원가입
          </Link>
        </div>
      )}
    </>
  );

  useEffect(() => {
    if (userInfo && userInfo.userRoles) {
      for (let i = 0; i < userInfo.userRoles.length; i++) {
        // 견적사 권한이 있으면
        if (userInfo.userRoles[i].roleId === "IACServiceQuotationer") {
          setItems([
            {
              label: "할당된 건물·조회·견적",
              url: "/assignedBuildingList",
              template: itemRenderer,
            },
            {
              label: "건물등록·조회·견적",
              url: "/myBuildingList",
              template: itemRenderer,
            },
            {
              label: "입찰등록",
              url: "/IACServiceBidSelectBuildings",
              template: itemRenderer,
            },
            {
              label: "입찰공고",
              url: "/IACServiceBidList",
              template: itemRenderer,
            },
            {
              label: "고객문의",
              url: "/asking",
              template: itemRenderer,
            },
          ]);

        }
      }
    }
  }, [userInfo]);

  return (
    <>
      <div className="sticky top-0 z-50">
        <div className='bg-[#3F80EA] h-12 text-white font-semibold text-lg p-3'>
          <span>베타점검에서 손쉽게 시작하세요!</span>
        </div>
        <div className={'grid grid-cols-12 bg-[#FAFAFA] shadow-lg'}>
          <div className={'col-span-2 border-none'}></div>
          <div className={'col-span-8 border-none'}>
            <Menubar
              model={items}
              start={start}
              end={end}
              className="px-6 py-4 border-none rounded-none"
            />
          </div>
          <div className={'col-span-2 border-none'}></div>
        </div>

      </div>
    </>
  );
}

export default Header;
