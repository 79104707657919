import {useEffect, useState, useRef, useCallback} from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {Image} from 'primereact/image';
import {useNavigate} from "react-router-dom";

function Main() {
  const navigate = useNavigate();
  const [flipState, setFlipState] = useState(false);
  const [concernList, setConcernList] = useState([
    {id: 1, msg: '인증받고, 믿을 수 있는 업체를 어디에서 찾을 수 있을까?', isHighlight: true}
    , {id: 2, msg: '우리 건물은 설비점검을 언제 마지막으로 했지?', isHighlight: false}
    , {id: 3, msg: '신고할 서류는 어떻게 작성하지?', isHighlight: false}
    , {id: 4, msg: '우리 회사는 설비 점검이 필요한 건물이 한두 채가 아닌데, 한번에 관리할 순 없을까?', isHighlight: false}
  ]);

  useEffect(() => {
    for (let i=0; i<concernList.length; i++) {
      if (concernList[i].isHighlight) {
        setTimeout(() => {
          setFlipState(!flipState);
          concernList[i].isHighlight = false;
          if (i === 3) {
            concernList[0].isHighlight = true;
          } else {
            concernList[i+1].isHighlight = true;
          }
        }, 3000);
      }
    }
  }, [flipState]);

  return (
    <div>
      <Header></Header>
      <div className="">
        <div className={'grid grid-cols-12 items-center w-100 h-[800px] bg-[#FAFAFA]'}>
          <div className={'col-span-2'}></div>
          <div className={'col-span-8'}>
            <div className={'grid grid-cols-12 text-start'}>
              <div className={'col-span-7'}>
                <div className={'subtitle-large'}>정보통신설비 점검업체 찾기</div>
                <h1 className={'title-large'}><span className={'text-[#2AC9A4] font-bold'}>베타점검</span>과 함께하세요-</h1>
                <div className={'subtitle-large'}>건축물 정보통신설비 의무점검 All-Care 플랫폼</div>
              </div>
              <div className={''}></div>
              <div className={'col-span-4'}>
                <Image src={"/home/workers.png"}></Image>
              </div>
            </div>
            <div className={'items-center grid grid-cols-7 gap-4 mt-20 shadow-[0px_0px_35px_35px_rgba(63,128,234,0.1)] rounded'}>
              <div className={'aspect-square bg-white rounded content-center shadow-[0px_0px_25px_25px_rgba(42,201,164,0.1)]'}>
                <div className={'grid grid-rows-2 gap-3 mt-3 justify-items-center'}>
                  <div className={''}>
                    <Image src={'/home/calculator.png'} width={'34.75'}></Image>
                  </div>
                  <div className={'mt-3'}>자동견적</div>
                </div>
              </div>
              <div className={'aspect-square bg-white rounded content-center shadow-[0px_0px_15px_15px_rgba(42,201,164,0.15)]'}>
                <div className={'grid grid-rows-2 gap-3 mt-3 justify-items-center'}>
                  <div className={''}>
                    <Image src={'/home/monitor.png'} width={'31.35'}></Image>
                  </div>
                  <div className={'mt-3'}>전자입찰</div>
                </div>
              </div>
              <div className={'aspect-square bg-white rounded content-center shadow-[0px_0px_15px_15px_rgba(42,201,164,0.15)]'}>
                <div className={'grid grid-rows-2 gap-3 mt-3 justify-items-center'}>
                  <div className={''}>
                    <Image src={'/home/document.png'} width={'44'}></Image>
                  </div>
                  <div className={'mt-3'}>전자문서</div>
                </div>
              </div>
              <div className={'aspect-square bg-white rounded content-center shadow-[0px_0px_15px_15px_rgba(42,201,164,0.15)]'}>
                <div className={'grid grid-rows-2 gap-3 mt-3 justify-items-center'}>
                  <div className={'ms-2'}>
                    <Image src={'/home/signature.png'} width={'52'}></Image>
                  </div>
                  <div className={'mt-3'}>전자계약</div>
                </div>
              </div>
              <div className={'aspect-square bg-white rounded content-center shadow-[0px_0px_15px_15px_rgba(42,201,164,0.15)]'}>
                <div className={'grid grid-rows-2 gap-3 mt-3 justify-items-center'}>
                  <div className={''}>
                    <Image src={'/home/signature2.png'} width={'54.5'}></Image>
                  </div>
                  <div className={'mt-3'}>전자결제</div>
                </div>
              </div>
              <div className={'aspect-square bg-white rounded content-center shadow-[0px_0px_15px_15px_rgba(42,201,164,0.15)]'}>
                <div className={'grid grid-rows-2 gap-3 mt-3 justify-items-center'}>
                  <div className={''}>
                    <Image src={'/home/security.png'} width={'37'}></Image>
                  </div>
                  <div className={'mt-3'}>전자보증</div>
                </div>
              </div>
              <div className={'aspect-square bg-white rounded content-center shadow-[0px_0px_15px_15px_rgba(42,201,164,0.15)]'}>
                <div className={'grid grid-rows-2 gap-3 mt-3 justify-items-center'}>
                  <div className={'ms-2'}>
                    <Image src={'/home/eye.png'} width={'42.5'}></Image>
                  </div>
                  <div className={'mt-3'}>자동감시</div>
                </div>
              </div>
            </div>
          </div>
          <div className={'col-span-2'}></div>
        </div>

        <div className={'bg-white grid grid-cols-12 my-20'}>
          <div className={'col-span-2'}></div>
          <div className={'col-span-8'}>
            <div className={'text-start col-span-10'}>
              <div className={'text-black'}>
                <span className={'text-[38px] font-bold'}>정보통신설비 의무점검</span>
                <span className={'home-subtitile'}>이란?</span>
                <span className={'betacheck-text-art text-end'}>BETACHECK</span>
              </div>
              <div className={'home-subtitile mt-10'}>
                <div>정보통신설비 의무점검은 건물 내 통신 장비와 네트워크가 잘 작동하는지 확인하는 필수 과정이에요.</div>
                <div>이 점검을 통해 인터넷 문제나 장비 고장을 미리 예방할 수 있고, 입주자들이 안정적으로 통신 서비스를 이용할 수 있게 돕죠.</div>
              </div>
              <div className={'mt-10 home-subtitile '}>
                <div>점검을 소홀히 하면 통신 장애나 보안 문제가 생길 수 있으니, <span className={'text-[#2AC9A4]'}>정기적인 점검이 정말 중요해요!</span></div>
              </div>
            </div>
          </div>
          <div className={'col-span-2'}></div>
        </div>

        <div className={'h-[900px] bg-[#FAFAFA] pt-32 grid grid-cols-12 text-start'}>
          <div className={'col-span-2'}></div>
          <div className={'col-span-8 home-section3-background z-0'}>
            <div className={'text-[#424242] text-[32px] font-bold mt-10'}>
              <div>건물 관리자님의 모든 걱정,</div>
              <div className={'mt-5'}><Image src={'/home/logo.png'} width={'195'} className={'inline-block me-2'}/>에서 싹-다! 해결해 드립니다.</div>
            </div>
            <div className={'home-subtitile mt-10 text-[24px] text-[#424242]/40 z-10'}>
              {
                concernList.map(item => (
                  <div
                    key={item.id}
                    className={item.isHighlight ? 'text-[28px] text-[#424242] px-6 py-4 font-semibold rounded-lg bg-white/40 shadow-[0px_0px_15px_15px_rgba(42,201,164,0.15)] my-6' : 'my-6 ms-10'}
                  >{item.msg}</div>
                ))
              }
            </div>
          </div>
          <div className={'col-span-2'}></div>
        </div>

        <div className={'h-[1000px] bg-white'}>
          <div className={'pt-24'}>
            <div className={'text-black text-[32px] font-semibold'}>건물설비현황관리·입찰·계약·수행결과 확인까지</div>
            <div className={'text-[#2AC9A4] text-[50px] font-semibold'}>ALL-CARE</div>
          </div>
          <div className={'grid grid-cols-12 text-white mt-10'}>
            <div className={'col-span-2'}></div>
            <div className={'col-span-8 grid grid-cols-3 gap-4'}>
              <div className={'rounded bg-[#FAFAFA] p-8 text-[#9E9E9E]'}>
                <div className={'flex justify-center items-center'}>
                  <Image src={"/home/section4/house.png"} width={"48.85"}/>
                </div>
                <div className={'flex justify-center mt-5'}>
                  <div className={'bg-[#13A785] rounded-xl text-white w-[80px] text-[14px]'}>STEP 01</div>
                </div>
                <div className={'mt-3'}>
                  <span className={'text-[20px] font-semibold text-[#424242]'}>건물등록</span>
                </div>
                <div className={'text-[16px] mt-3 text-[#424242]'}>한 번 등록한 건물은 언제든지 점검현황을 확인하고 관리할 수 있어요. 점검이 필요한 모든 건물들을 클릭 한 번에 모두
                  조회하세요!
                </div>
              </div>
              <div className={'rounded bg-[#FAFAFA] p-8 text-[#9E9E9E]'}>
                <div className={'flex justify-center items-center'}>
                  <Image src={"/home/section4/gear.png"} width={"52.18"}/>
                </div>
                <div className={'flex justify-center mt-5'}>
                  <div className={'bg-[#13A785] rounded-xl text-white w-[80px] text-[14px]'}>STEP 02</div>
                </div>
                <div className={'mt-3'}>
                  <span className={'text-[20px] font-semibold text-[#424242]'}>설비등록</span>
                </div>
                <div className={'text-[16px] mt-3 text-[#424242]'}>내 건물에 대한 예상 표준비용을 손쉽게 확인하세요.</div>
              </div>
              <div className={'rounded bg-[#FAFAFA] p-8 text-[#9E9E9E]'}>
                <div className={'flex justify-center items-center'}>
                  <Image src={"/home/section4/hand.png"} width={"49"}/>
                </div>
                <div className={'flex justify-center mt-5'}>
                  <div className={'bg-[#13A785] rounded-xl text-white w-[80px] text-[14px]'}>STEP 03</div>
                </div>
                <div className={'mt-3'}>
                  <span className={'text-[20px] font-semibold text-[#424242]'}>점검입찰</span>
                </div>
                <div className={'text-[16px] mt-3 text-[#424242]'}>인증이 완료된 점검업체만 입찰에 참가할 수 있어요. 전문가에 의한 믿을 수 있는 점검 결과를 제공해드려요.</div>
              </div>
            </div>
            <div className={'col-span-2'}></div>
          </div>

          <div className={'grid grid-cols-12 text-white mt-5'}>
            <div className={'col-span-2'}></div>
            <div className={'col-span-8 grid grid-cols-3 gap-4'}>
              <div className={'rounded bg-[#FAFAFA] p-8 text-[#9E9E9E]'}>
                <div className={'flex justify-center items-center'}>
                  <Image src={"/home/section4/people.png"} width={"51"}/>
                </div>
                <div className={'flex justify-center mt-5'}>
                  <div className={'bg-[#13A785] rounded-xl text-white w-[80px] text-[14px]'}>STEP 04</div>
                </div>
                <div className={'mt-3'}>
                  <span className={'text-[20px] font-semibold text-[#424242]'}>업체선정</span>
                </div>
                <div className={'text-[16px] mt-3 text-[#424242]'}>더 이상의 서류 작업은 그만! 온라인을 통한 전자계약으로 더욱 안전하고 쉽게 계약을 진행하세요.</div>
              </div>
              <div className={'rounded bg-[#FAFAFA] p-8 text-[#9E9E9E]'}>
                <div className={'flex justify-center items-center'}>
                  <Image src={"/home/section4/documents.png"} width={"44.33"}/>
                </div>
                <div className={'flex justify-center mt-5'}>
                  <div className={'bg-[#13A785] rounded-xl text-white w-[80px] text-[14px]'}>STEP 05</div>
                </div>
                <div className={'mt-3'}>
                  <span className={'text-[20px] font-semibold text-[#424242]'}>전자계약</span>
                </div>
                <div className={'text-[16px] mt-3 text-[#424242]'}>온라인을 통해 정보통신설비 점검 결과를 받아보세요. 쉽고, 빠르지만 더욱 안전하게 관리가
                  가능해요.
                </div>
              </div>
              <div className={'rounded bg-[#FAFAFA] p-8 text-[#9E9E9E]'}>
                <div className={'flex justify-center items-center'}>
                  <Image src={"/home/section4/triangle.png"} width={"49.17"}/>
                </div>
                <div className={'flex justify-center mt-5'}>
                  <div className={'bg-[#13A785] rounded-xl text-white w-[80px] text-[14px]'}>STEP 06</div>
                </div>
                <div className={'mt-3'}>
                  <span className={'text-[20px] font-semibold text-[#424242]'}>수행확인</span>
                </div>
                <div className={'text-[16px] mt-3 text-[#424242]'}>한 번 등록한 건물은 언제든지 점검현황을 확인하고 관리할 수 있어요. 점검이 필요한 모든
                  건물들을 클릭 한 번에 모두
                  조회하세요!
                </div>
              </div>
            </div>
            <div className={'col-span-2'}></div>
          </div>
        </div>
        <div className={'bg-[#FAFAFA] h-[300px] pt-16'}>
          <div className={''}>
            <div className={'text-[#2AC9A4] text-[50px] font-semibold'}>베타점검과 함께하세요</div>
          </div>
          <div className={'mt-5'}>
            <button
              className={'px-20 py-4 font-semibold rounded-lg bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white'}
              onClick={() => {
                navigate('/userRegistration')
              }}
            >회원가입하기
            </button>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default Main;
