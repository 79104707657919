import { Column } from "primereact/column";
import {DataTable, DataTableExpandedRows, DataTableValueArray} from "primereact/datatable";
import React, {useContext, useEffect, useRef, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserInfoContext } from "../../App";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Stepper from "../../components/Stepper";
import { getQuotationClosedList } from "../../services/serviceBidService";

interface Building {
  buildingId: number;
  buildingName: string;
  address: string;
  totalArea: number;
  quotationStatus: string;
}

function IACServiceBidRegistration() {
  const navigate = useNavigate();

  // @ts-ignore
  let { userInfo } = useContext(UserInfoContext);

  const [buildingList, setBuildingList] = useState<Building[]>([]);
  const [selectedBuildings, setSelectedBuildings] = useState<Building[]>([]);
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);

  const totalAreaTemplate = (rowData: Building) => {
    return rowData.totalArea.toLocaleString() + " m²";
  };
  const isSelectable = (data: any) => data.quotationStatus == 'CLOSED' || data.quotationStatus == 'DONE';
  const isRowSelectable = (event: any) => (event.data ? isSelectable(event.data) : true);

  const allowExpansion = (rowData: any) => {
    return rowData.buildingDongList.length > 0;
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="">
        <h5 className={'my-3 font-semibold'}>포함된 표제부 목록</h5>
        <DataTable
          value={data.buildingDongList}
          size={'small'}
          rowClassName={(data, options) => "text-sm border"}
        >
          <Column field="buiildingName" header="건물명"></Column>
          <Column field="totalArea" header="연면적"></Column>
          <Column field="dongName" header="동이름"></Column>
          <Column field="mainPurposeName" header="주용도"></Column>
          <Column field="useApproveDay" header="사용승인일"></Column>
        </DataTable>
      </div>
    );
  };

  useEffect(() => {
    getQuotationClosedList(userInfo.userId).then((result) => {
      setBuildingList(result);
    });
  }, [userInfo.userId]);

  return (
    <div>
      <Header></Header>
      <div className={'grid grid-cols-12 content'}>
        <div className={'col-span-2'}></div>
        <div className={'col-span-8'}>
          <div className="pb-3 pt-10">
            <h1 className="my-12 font-semibold text-3xl">입찰하기</h1>
            <div className="my-6">
              <Stepper step={'step1'}></Stepper>
            </div>

            <div>
              <DataTable
                value={buildingList}
                selectionMode={"checkbox"}
                selection={selectedBuildings}
                showSelectAll={false}
                onSelectionChange={
                  (e) => {
                    let isProvinceMatch = true;
                    for (const i in e.value) {
                      const stProvince = e.value[0].address.split(' ')[0];
                      if (stProvince !== e.value[i].address.split(' ')[0]) {
                        isProvinceMatch = false;
                      }
                    }
                    if (isProvinceMatch) {
                      setSelectedBuildings(e.value);
                    } else {
                      alert('동일한 광역 지방자치단체(서울,경기 등) 만 한번에 견적 요청을 할 수 있습니다.');
                    }
                  }}
                dataKey="buildingId"
                emptyMessage="유지보수·관리 및 성능점검 대상 건물이 없습니다."
                tableStyle={{minWidth: "50rem"}}
                rowClassName={(data, options) => "text-sm border"}
                scrollable
                tableClassName="border-separate"
                columnResizeMode="expand"
                className=""
                isDataSelectable={isRowSelectable}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                // onRowExpand={onRowExpand}
                // onRowCollapse={onRowCollapse}
                rowExpansionTemplate={rowExpansionTemplate}
              >
                <Column expander={allowExpansion} style={{width: '5rem'}}/>
                <Column selectionMode="multiple" headerStyle={{width: '3rem'}}></Column>
                <Column field="buildingName" header="건물명"></Column>
                <Column field="address" header="주소"></Column>
                <Column
                  field="totalArea"
                  header="연면적"
                  body={totalAreaTemplate}
                  headerClassName=""
                  className=""
                ></Column>
                <Column field="quotationStatus" header="견적 상태"></Column>
                <Column field="bidStatus" header="입찰 상태"></Column>
              </DataTable>
            </div>

            <div className="flex flex-col justify-start items-start">
              <p className="mb-0 mt-2 text-[#FF4242] text-base font-normal">
                * 유지보수·관리 및 성능점검 대상 현황표가 등록된 건물만 목록에
                나타납니다.
              </p>
              <p className="mb-0 text-[#FF4242] text-base font-normal">
                * 현재 입찰·계약중인 건물은 목록에 표시되지 않습니다.
              </p>
            </div>

            <button
              disabled={selectedBuildings.length == 0}
              onClick={() =>
                navigate(
                  "/IACServiceBidRegistration", {state: {buildings: selectedBuildings}}
                )
              }
              className="bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] w-1/2 my-8 mt-12 px-3 py-2.5 disabled:opacity-50 hover:opacity-90 duration-300 text-base font-semibold text-white rounded-xl"
            >
              입찰 시작하기
            </button>
          </div>
        </div>
        <div className={'col-span-2'}></div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default IACServiceBidRegistration;
