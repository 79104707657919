import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import {getCookie, isTokenExpired, setCookie} from './cookieUtil';


// Axios 인스턴스 생성
const axiosInstance: AxiosInstance = axios.create();

// 함수: 액세스 토큰 갱신
const refreshToken = async () => {
  try {
    // const BASE_URL = 'http://localhost:8080';
    const BASE_URL = "https://user-api.beta-check.com";

    const refreshToken = getCookie('refreshToken');
    const userId = getCookie('userId');
    if (!refreshToken) throw new Error('No refresh token available');

    const response = await axios.get(BASE_URL + '/auth/refreshAccessToken?refreshToken=' + refreshToken + '&userId=' + userId);

    const newAccessToken = response.data;
    setCookie('accessToken', newAccessToken);
    setCookie("expiry", (new Date().getTime() + (10 * 60 * 1000)).toString()); // 10분
    console.log("토큰이 갱신되었습니다.");

    return newAccessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

// 요청 인터셉터 추가
// @ts-ignore
axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
    if (isTokenExpired()) {
      try {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${newAccessToken}`,
          };
        }
      } catch (error) {
        console.error('Error during token refresh: ', error);
        return Promise.reject('토큰 만료, 로그아웃 처리됨.');
      }
    }

    // 토큰이 만료되지 않았을 때 요청 헤더에 토큰 추가
    const token = getCookie('accessToken');
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;