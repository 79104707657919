import { Cookies } from 'react-cookie';

const cookies = new Cookies();

// 쿠키에 값을 저장할 때
export const setCookie = (name: string, value: string, options?: any): void => {
  cookies.set(name, value, { ...options });
};

// 쿠키에 있는 값을 꺼낼 때
export const getCookie = (name: string): string | undefined => {
  return cookies.get(name);
};

// 쿠키를 지울 때
export const removeCookie = (name: string): void => {
  cookies.remove(name);
};

export const isTokenExpired = (): boolean => {
  const expiry = getCookie('expiry');
  return expiry ? new Date().getTime() > parseInt(expiry, 10) : true;
};