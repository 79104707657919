import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserInfoContext } from "../../App";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import warning_badge_blue from "../../images/svg/warning_badge_blue.svg";
import quotaion_assigned from "../../images/quotation-process/quotaion_assigned.png";
import quotaion_closed from "../../images/quotation-process/quotaion_closed.png";
import quotaion_requested from "../../images/quotation-process/quotaion_requested.png";
import { getMygongsaBankAccount } from "../../services/authoriedUserService";
import { getBusinessUser } from "../../services/businessUserService";
import {
  getMyBuilding,
  requestBuildingQuotation,
} from "../../services/serviceBidService";
import { Accordion, AccordionTab } from "primereact/accordion";

function QuotationRequest() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // @ts-ignore
  let { userInfo, logout } = useContext(UserInfoContext);
  const { buildingId } = useParams();

  const [buildingInfo, setBuildingInfo] = useState({
    buildingName: "",
    address: "",
    totalArea: 0,
    quotationCost: 0,
    quotationStatus: "",
    createDate: "",
    updateDate: "",
  });

  const [businessUserInfo, setBusinessUserInfo] = useState({
    bankName: "",
    bankAccOwner: "",
    bankAccNumber: "",
    phone: "",
    businessUserFiles: [
      {
        fileName: "",
        fileUrl: "",
        fileType: "",
      },
    ],
  });

  const [mygongsaBankInfo, setMygongsaBankInfo] = useState({
    bankName: "",
    bankAccOwner: "",
    bankAccNumber: "",
  });
  const [submitModalvisible, setSubmitModalvisible] = useState(false);
  const [confirmModalvisible, setconfirmModalvisible] = useState(false);

  console.log("buildingInfo", buildingInfo);
  // console.log("businessUserInfo", businessUserInfo);
  // console.log("mygongsaBankInfo", mygongsaBankInfo);

  const requestQuotation = () => {
    let payload = {
      buildingId: parseInt(buildingId as string),
      createUser: userInfo.userId,
      quotationStatus: "REQUESTED",
    };

    setLoading(true);
    requestBuildingQuotation(payload).then((result) => {
      if (result.status === 200) {
        setSubmitModalvisible(false);
        setconfirmModalvisible(true);
      } else {
        alert("오류가 발생했습니다. 관리자에게 문의하세요.");
      }
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getMyBuilding(buildingId, userInfo.userId).then((result) => {
      setBuildingInfo(result);
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });

    getBusinessUser(userInfo.userId).then((result) => {
      setBusinessUserInfo(result);
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });

    getMygongsaBankAccount().then((result) => {
      setMygongsaBankInfo(result);
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });
  }, [buildingId, userInfo.userId]);

  return (
    <div>
      <Header></Header>

      <div className="content spacing pb-20">
        {buildingInfo?.quotationStatus ? (
          <>
            <h1 className="pt-10 my-12 font-semibold text-3xl">
              견적 요청 현황
            </h1>

            <div className="mt-20">
              {buildingInfo?.quotationStatus === "REQUESTED" ? (
                <img src={quotaion_requested} alt="quotaion_requested" />
              ) : buildingInfo?.quotationStatus === "ASSIGNED" ? (
                <img src={quotaion_assigned} alt="quotaion_assigned" />
              ) : buildingInfo?.quotationStatus === "CLOSED" ? (
                <img src={quotaion_closed} alt="quotaion_closed" />
              ) : null}
            </div>

            <div className="grid grid-cols-2 grid-rows-2 gap-x-10 gap-y-3 pt-20">
              <div className="flex w-full gap-x-5">
                <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                  견적 요청일
                </div>
                <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                  {buildingInfo?.createDate}
                </div>
              </div>

              <div className="flex w-full gap-x-5">
                <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                  견적 완료일
                </div>
                <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                  {buildingInfo?.updateDate}
                </div>
              </div>

              <div className="flex w-full gap-x-5">
                <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                  담당 견적사
                </div>
                <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                  {/* {buildingInfo?.} */}
                </div>
              </div>

              <div className="flex w-full gap-x-5">
                <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                  견적사 연락처
                </div>
                <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                  {/* {buildingInfo?.} */}
                </div>
              </div>
            </div>

            <Accordion className="mt-20">
              <AccordionTab
                header={
                  <div className="flex justify-start items-center text-xl font-semibold pl-6">
                    견적 요청서 보기
                  </div>
                }
              >
                <div className="px-20 bg-[#FAFAFA] pb-20">
                  <div>
                    <div className="text-lg text-start font-semibold pt-10 pb-3">
                      견적비 확인
                    </div>

                    <div className="grid grid-cols-3 grid-rows-1 gap-x-3">
                      <div className="flex w-full gap-x-3">
                        <div className="w-1/4 truncate bg-[#3F80EA] rounded-lg p-3 text-white">
                          건물명
                        </div>
                        <div className="w-3/4 truncate text-start text-base font-semibold p-3 border rounded-lg bg-white">
                          {buildingInfo?.buildingName}
                        </div>
                      </div>

                      <div className="flex w-full gap-x-3">
                        <div className="w-1/4 truncate bg-[#3F80EA] rounded-lg p-3 text-white">
                          주소
                        </div>
                        <div className="w-3/4 truncate text-start text-base font-semibold p-3 border rounded-lg bg-white">
                          {buildingInfo?.address}
                        </div>
                      </div>

                      <div className="flex w-full gap-x-3">
                        <div className="w-1/4 truncate bg-[#3F80EA] rounded-lg p-3 text-white">
                          연면적 (m²)
                        </div>
                        <div className="w-3/4 truncate text-start text-base font-semibold p-3 border rounded-lg bg-white">
                          {buildingInfo?.totalArea}
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between items-center p-3 bg-[#F5F5F5] rounded-xl mt-4">
                      <div className="flex w-full gap-x-3 text-start">
                        <div className="text-lg font-normal pr-20">견적비</div>
                        <div className="text-xl font-bold">
                          {buildingInfo?.quotationCost}
                          <span className="text-xl font-normal">원</span>
                        </div>
                      </div>
                      <button
                        // onClick={() => {}}
                        className="flex justify-center items-center w-1/6 truncate p-2 bg-[#424242] disabled:bg-[#DADADA] hover:bg-[#242424] duration-300 text-base text-white rounded-xl"
                        style={{ boxShadow: "0px 0px 100px #5A5A5A14" }}
                      >
                        견적비 조건표 확인
                      </button>
                    </div>
                  </div>

                  <div>
                    <div className="flex items-center gap-6 pt-20 pb-3">
                      <div className="text-lg text-start font-semibold ">
                        등록된 계좌 정보
                      </div>
                      <div className="flex gap-x-1">
                        <img
                          src={warning_badge_blue}
                          alt="warning_badge_blue"
                          className="mt-0.5"
                        />
                        <div className="text-start text-[#757575] text-sm font-normal">
                          가입시 등록한 정보로 입금이 확인됩니다. 등록된 통장과
                          예금주명을 사용하여 입금 부탁드립니다.
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-2 grid-rows-3 gap-3 bg-white p-6 rounded-xl border border-[#F5F5F5]">
                      <div className="col-start-1 row-start-1 flex w-full gap-x-3 text-start">
                        <div className="w-20 p-3 text-sm text-end font-thin text-[#9E9E9E]">
                          예금주명
                        </div>
                        <div className="w-3/4 text-start text-base font-semibold p-3 border border-[#F5F5F5] rounded-lg">
                          {businessUserInfo?.bankAccOwner}
                        </div>
                      </div>

                      <div className="col-start-1 row-start-2 flex w-full gap-x-3 text-start">
                        <div className="w-20 p-3 text-sm text-end font-thin text-[#9E9E9E]">
                          계좌번호
                        </div>
                        <div className="w-3/4 text-start text-base font-semibold p-3 border border-[#F5F5F5] rounded-lg">
                          {businessUserInfo?.bankAccNumber}
                        </div>
                      </div>

                      <div className="col-start-1 row-start-3 flex w-full gap-x-3 text-start">
                        <div className="w-20 p-3 text-sm text-end font-thin text-[#9E9E9E]">
                          은행
                        </div>
                        <div className="w-3/4 text-start text-base font-semibold p-3 border border-[#F5F5F5] rounded-lg">
                          {businessUserInfo?.bankName}
                        </div>
                      </div>

                      <div className="row-span-3 col-start-2 row-start-1 flex w-full gap-x-3 text-start">
                        <div className="w-20 p-3 text-sm text-end font-thin text-[#9E9E9E]">
                          통장사본
                        </div>
                        <div className="w-3/4 text-start text-base font-semibold p-3 border border-[#F5F5F5] rounded-lg">
                          {businessUserInfo?.businessUserFiles
                            ?.filter((file) => file.fileType === "BankAccImage")
                            ?.map((file, index) => {
                              return (
                                <div
                                  key={index}
                                  className="flex w-full rounded-lg"
                                >
                                  <Image
                                    src={file.fileUrl}
                                    alt={file.fileName}
                                    width="250"
                                    preview
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="text-lg text-start font-semibold pt-20 pb-3">
                      내 연락처 확인
                    </div>

                    <div className="flex justify-between items-center w-full bg-white p-3 rounded-xl gap-3">
                      <div className="flex gap-x-1">
                        <img
                          src={warning_badge_blue}
                          alt="warning_badge_blue"
                          className="mt-0.5"
                        />
                        <div className="text-start text-[#757575] text-sm font-normal">
                          본인인증시 사용한 연락처가 견적사님께 전달됩니다.
                          수정을 원하시면 다시 본인인증 해주세요.
                        </div>
                      </div>

                      <div
                        className={`w-1/3 text-start text-base font-semibold p-3 border border-[#F5F5F5] rounded-xl bg-white`}
                      >
                        {businessUserInfo?.phone}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between items-center p-6 bg-[#F5F5F5] rounded-xl mt-20">
                    <div className="text-xl font-semibold text-start">
                      입금정보
                    </div>
                    <div className="flex justify-center items-center gap-6">
                      <div className="flex justify-center items-center">
                        <div className="text-base font-normal pr-3 border-r-2">
                          입금하실 은행
                        </div>
                        <div className="text-base font-bold pl-3">
                          {mygongsaBankInfo?.bankName}
                        </div>
                      </div>

                      <div className="flex justify-center items-center">
                        <div className="text-base font-normal pr-3 border-r-2">
                          계좌번호
                        </div>
                        <div className="text-base font-bold pl-3">
                          {mygongsaBankInfo?.bankAccNumber}
                        </div>
                      </div>

                      <div className="flex justify-center items-center">
                        <div className="text-base font-normal pr-3 border-r-2">
                          예금주명
                        </div>
                        <div className="text-base font-bold pl-3">
                          {mygongsaBankInfo?.bankAccOwner}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
          </>
        ) : (
          <>
            <h4 className="pt-10 my-12 font-semibold text-3xl">
              견적 요청서 작성
            </h4>

            <div>
              <div className="text-lg text-start font-semibold pt-10 pb-3">
                견적비 확인
              </div>

              <div className="grid grid-cols-3 grid-rows-1 gap-x-3">
                <div className="flex w-full gap-x-3">
                  <div className="w-1/4 truncate bg-[#3F80EA] rounded-lg p-3 text-white">
                    건물명
                  </div>
                  <div className="w-3/4 truncate text-start text-base font-semibold p-3 border rounded-lg bg-white">
                    {buildingInfo?.buildingName}
                  </div>
                </div>

                <div className="flex w-full gap-x-3">
                  <div className="w-1/4 truncate bg-[#3F80EA] rounded-lg p-3 text-white">
                    주소
                  </div>
                  <div className="w-3/4 truncate text-start text-base font-semibold p-3 border rounded-lg bg-white">
                    {buildingInfo?.address}
                  </div>
                </div>

                <div className="flex w-full gap-x-3">
                  <div className="w-1/4 truncate bg-[#3F80EA] rounded-lg p-3 text-white">
                    연면적 (m²)
                  </div>
                  <div className="w-3/4 truncate text-start text-base font-semibold p-3 border rounded-lg bg-white">
                    {buildingInfo?.totalArea}
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-center p-3 bg-[#F5F5F5] rounded-xl mt-4">
                <div className="flex w-full gap-x-3 text-start">
                  <div className="text-lg font-normal pr-20">견적비</div>
                  <div className="text-xl font-bold">
                    {buildingInfo?.quotationCost}
                    <span className="text-xl font-normal">원</span>
                  </div>
                </div>
                <button
                  // onClick={() => {}}
                  className="flex justify-center items-center w-1/6 truncate p-2 bg-[#424242] disabled:bg-[#DADADA] hover:bg-[#242424] duration-300 text-base text-white rounded-xl"
                  style={{ boxShadow: "0px 0px 100px #5A5A5A14" }}
                >
                  견적비 조건표 확인
                </button>
              </div>
            </div>

            <div>
              <div className="flex items-center gap-6 pt-20 pb-3">
                <div className="text-lg text-start font-semibold ">
                  등록된 계좌 정보
                </div>
                <div className="flex gap-x-1">
                  <img
                    src={warning_badge_blue}
                    alt="warning_badge_blue"
                    className="mt-0.5"
                  />
                  <div className="text-start text-[#757575] text-sm font-normal">
                    가입시 등록한 정보로 입금이 확인됩니다. 등록된 통장과
                    예금주명을 사용하여 입금 부탁드립니다.
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 grid-rows-3 gap-3 bg-white p-6 rounded-xl border border-[#F5F5F5]">
                <div className="col-start-1 row-start-1 flex w-full gap-x-3 text-start">
                  <div className="w-20 p-3 text-sm text-end font-thin text-[#9E9E9E]">
                    예금주명
                  </div>
                  <div className="w-3/4 text-start text-base font-semibold p-3 border border-[#F5F5F5] rounded-lg">
                    {businessUserInfo?.bankAccOwner}
                  </div>
                </div>

                <div className="col-start-1 row-start-2 flex w-full gap-x-3 text-start">
                  <div className="w-20 p-3 text-sm text-end font-thin text-[#9E9E9E]">
                    계좌번호
                  </div>
                  <div className="w-3/4 text-start text-base font-semibold p-3 border border-[#F5F5F5] rounded-lg">
                    {businessUserInfo?.bankAccNumber}
                  </div>
                </div>

                <div className="col-start-1 row-start-3 flex w-full gap-x-3 text-start">
                  <div className="w-20 p-3 text-sm text-end font-thin text-[#9E9E9E]">
                    은행
                  </div>
                  <div className="w-3/4 text-start text-base font-semibold p-3 border border-[#F5F5F5] rounded-lg">
                    {businessUserInfo?.bankName}
                  </div>
                </div>

                <div className="row-span-3 col-start-2 row-start-1 flex w-full gap-x-3 text-start">
                  <div className="w-20 p-3 text-sm text-end font-thin text-[#9E9E9E]">
                    통장사본
                  </div>
                  <div className="w-3/4 text-start text-base font-semibold p-3 border border-[#F5F5F5] rounded-lg">
                    {businessUserInfo?.businessUserFiles
                      ?.filter((file) => file.fileType === "BankAccImage")
                      ?.map((file, index) => {
                        return (
                          <div key={index} className="flex w-full rounded-lg">
                            <Image
                              src={file.fileUrl}
                              alt={file.fileName}
                              width="250"
                              preview
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="text-lg text-start font-semibold pt-20 pb-3">
                내 연락처 확인
              </div>

              <div className="flex justify-between items-center w-full bg-white p-3 rounded-xl gap-3">
                <div className="flex gap-x-1">
                  <img
                    src={warning_badge_blue}
                    alt="warning_badge_blue"
                    className="mt-0.5"
                  />
                  <div className="text-start text-[#757575] text-sm font-normal">
                    본인인증시 사용한 연락처가 견적사님께 전달됩니다. 수정을
                    원하시면 다시 본인인증 해주세요.
                  </div>
                </div>

                <div
                  className={`w-1/3 flex ${
                    businessUserInfo?.phone ? "justify-between" : "justify-end"
                  } items-center text-start text-base font-semibold p-2 border border-[#F5F5F5] rounded-xl bg-white`}
                >
                  {businessUserInfo?.phone}
                  <button
                    className="border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-xl px-4 py-3"
                    // onClick={() => {}}
                  >
                    변경하기
                  </button>
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center p-6 bg-[#F5F5F5] rounded-xl mt-20">
              <div className="text-xl font-semibold text-start">입금정보</div>
              <div className="flex justify-center items-center gap-6">
                <div className="flex justify-center items-center">
                  <div className="text-base font-normal pr-3 border-r-2">
                    입금하실 은행
                  </div>
                  <div className="text-base font-bold pl-3">
                    {mygongsaBankInfo?.bankName}
                  </div>
                </div>

                <div className="flex justify-center items-center">
                  <div className="text-base font-normal pr-3 border-r-2">
                    계좌번호
                  </div>
                  <div className="text-base font-bold pl-3">
                    {mygongsaBankInfo?.bankAccNumber}
                  </div>
                </div>

                <div className="flex justify-center items-center">
                  <div className="text-base font-normal pr-3 border-r-2">
                    예금주명
                  </div>
                  <div className="text-base font-bold pl-3">
                    {mygongsaBankInfo?.bankAccOwner}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {buildingInfo?.quotationStatus ? (
          <div className="flex w-full justify-center items-center gap-6 mt-20">
            <button
              className="bg-white border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white whitespace-nowrap w-1/3 px-3 py-3 disabled:opacity-50 hover:opacity-90 duration-300 text-base font-semibold rounded-xl"
              onClick={() => {
                navigate("/buildingDetail/" + buildingId);
              }}
            >
              돌아가기
            </button>
          </div>
        ) : (
          <div className="flex w-full justify-center items-center gap-6 mt-20">
            <button
              className="bg-white border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white whitespace-nowrap w-1/3 px-3 py-3 disabled:opacity-50 hover:opacity-90 duration-300 text-base font-semibold rounded-xl"
              onClick={() => {
                navigate("/buildingDetail/" + buildingId);
              }}
            >
              돌아가기
            </button>

            <button
              className="w-1/3 bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] px-3 py-3 disabled:opacity-50 hover:opacity-90 duration-300 text-base font-semibold text-white rounded-xl"
              onClick={() => setSubmitModalvisible(true)}
            >
              요청서 작성 완료
            </button>
          </div>
        )}

        <Dialog
          header=""
          visible={submitModalvisible}
          closable={false}
          style={{ width: "50vw" }}
          onHide={() => {
            if (!submitModalvisible) return;
            setSubmitModalvisible(false);
          }}
        >
          <div className="flex flex-col justify-center items-center gap-6">
            <p className="text-2xl font-normal">요청서가 접수되었습니다.</p>

            <div className="text-center">
              <p className=" text-base font-normal">
                견적사 배정 후 알림이 전송됩니다.
              </p>
              <p className="text-base font-normal">
                배정된 견적사가 등록된 연락처로 방문 전 사전 연락 예정입니다.
              </p>
            </div>

            <div className="flex justify-center items-center w-full space-x-2 mt-6">
              <button
                className="w-1/4 px-3 py-2 text-[#519CE1] disabled:bg-[#DADADA] hover:bg-[#ebebeb] duration-300 text-base font-extrabold border border-[#519CE1] rounded-xl"
                onClick={() => {
                  setSubmitModalvisible(false);
                }}
              >
                취소
              </button>
              <button
                className="w-1/4 px-3 py-2 bg-[#519CE1] disabled:bg-[#DADADA] hover:bg-[#4a8dcc] duration-300 text-base font-extrabold text-white rounded-xl"
                onClick={() => {
                  requestQuotation();
                }}
              >
                저장
              </button>
            </div>
          </div>
        </Dialog>

        <Dialog
          header=""
          visible={confirmModalvisible}
          closable={false}
          style={{ width: "50vw" }}
          onHide={() => {
            if (!confirmModalvisible) return;
            setconfirmModalvisible(false);
          }}
        >
          <div className="flex flex-col justify-center items-center gap-6">
            <p className="text-2xl font-normal">입력이 완료되었습니다.</p>

            <div className="flex justify-center items-center w-full space-x-2 mt-6">
              <button
                className="w-1/4 px-3 py-2 bg-[#519CE1] disabled:bg-[#DADADA] hover:bg-[#4a8dcc] duration-300 text-base font-extrabold text-white rounded-xl"
                onClick={() => {
                  setconfirmModalvisible(false);
                  navigate("/buildingDetail/" + buildingId);
                }}
              >
                확인
              </button>
            </div>
          </div>
        </Dialog>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default QuotationRequest;
