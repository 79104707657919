import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserInfoContext } from "../../../App";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import {getQuotationList} from "../../../services/serviceBidService";
import {KRWon} from "../../../services/utils/formatUtil";

interface Quotation {
  seq: number,
  price: number,
  createDate: string,
  status: string,
}

function AssignedBuildingList() {
  const navigate = useNavigate();

  // @ts-ignore
  let { userInfo, logout } = useContext(UserInfoContext);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [quotationList, setQuotationList] = useState<Quotation[]>([]);

  const statusOptions = [
    { name: "REQUESTED", value: "REQUESTED" },
    { name: "ASSIGNED", value: "ASSIGNED" },
    { name: "CLOSED", value: "CLOSED" },
  ];

  const handleSearch = () => {
    setLoading(true);
    const payload = {
      // userId: userInfo.userId,
      koreanProvinces: selectedLocation,
      status: selectedStatus,
      searchText: searchText,
    };

    getQuotationList(payload).then((result) => {
      setQuotationList(result);
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    const param = {
      // quotationerId: userInfo.userId,
      status: selectedStatus
    };

    getQuotationList(param).then((result)=>{
      console.log('getQuotationList', result);
      setQuotationList(result);
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });
  }, [userInfo.userId]);

  return (
    <div>
      <Header></Header>
      <div className={'grid grid-cols-12 content'}>
        <div className={'col-span-2'}></div>
        <div className={'col-span-8'}>
          <div className="pb-20">
            <h1 className="pt-10 my-12 font-semibold text-3xl">견적 목록</h1>

            <div className="flex items-end w-full bg-[#F5F5F5] p-3 rounded-md gap-3">
              <div className="w-1/4">
                <label
                  htmlFor="status"
                  className="flex text-start text-base font-semibold"
                >
                  견적 요청 상태
                </label>

                <Dropdown
                  showClear
                  value={selectedStatus}
                  onChange={(e: DropdownChangeEvent) => setSelectedStatus(e.value)}
                  options={statusOptions}
                  optionLabel="name"
                  placeholder="선택"
                  id="status"
                  className="w-60 rounded-xl mt-2 text-left"
                />
              </div>

              <div className="w-full">
                <label
                  htmlFor="searchBuilding"
                  className="flex text-start text-base font-semibold"
                >
                  검색
                </label>
                <InputText
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyUp={(e) => {
                    e.key === "Enter" && handleSearch();
                  }}
                  placeholder="키워드를 검색해 주세요."
                  id="searchBuilding"
                  className="w-full rounded-xl mt-2"
                />
              </div>

              <button
                className="border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-xl px-8 h-12"
                onClick={() => handleSearch()}
              >
                검색하기
              </button>
            </div>

            <div className="flex justify-start items-center pt-8">
              <div>
                Total{" "}
                <span className="font-bold text-[#3F80EA]">
              {quotationList?.length || 0}
            </span>
              </div>
            </div>

            <div>
              <DataTable
                value={quotationList}
                selectionMode="single"
                onSelectionChange={(e) => {
                  navigate('/assignedQuotationDetail/' + e.value.seq);
                }}
                dataKey="seq"
                emptyMessage="요청된 견적 건이 없습니다."
                tableStyle={{minWidth: "50rem"}}
                rowClassName={(data, options) => "border text-sm"}
                scrollable
                resizableColumns
                loading={loading}
                columnResizeMode="expand"
                tableClassName="border-separate"
              >
                <Column
                  field="seq"
                  sortable
                  header="번호"
                  headerClassName=""
                  className=""
                ></Column>
                <Column
                  field="price"
                  sortable
                  header="견적가"
                  headerClassName=""
                  className=""
                  body={(item) => {
                    return KRWon.format(item.price)
                  }}
                ></Column>
                <Column
                  field="createDate"
                  sortable
                  header="견적 요청일"
                  headerClassName=""
                  className=""
                ></Column>
                <Column
                  field="status"
                  sortable
                  header="상태"
                  headerClassName=""
                  className=""
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
        <div className={'col-span-2'}></div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default AssignedBuildingList;
