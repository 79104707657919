import React, {useCallback, useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import {
  verifyBusinessRegistrationNumber,
} from "../../services/businessUserService";
import {
  validateEmailFormat,
  validateNumberFormat,
} from "../../services/utils/formatUtil";
import { Dialog } from "primereact/dialog";
import "../../styles/personReg.css";
import { useLoading } from '../LoadingContext';
import AlertDialog from "../AlertDialog";
import ConfirmDialog from "../ConfirmDialog";


function CompanyRegistration(props:any) {
  const navigate = useNavigate();
  const {setLoading} = useLoading();

  const [businessRegistrationNumber, setBusinessRegistrationNumber] =
    useState("");

  const [managerName, setManagerName] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [managerPhone, setManagerPhone] = useState("");

  const businessRegistrationNumberCheckText = useRef(null);

  const cameraRef = useRef<HTMLInputElement | null>(null);
  const cameraRef2 = useRef<HTMLInputElement | null>(null);
  // const [showImage, setShowImage] = useState("");
  const [submitImage, setSubmitImage] = useState<File | undefined>(undefined);
  // const [showImage2, setShowImage2] = useState("");
  const [submitImage2, setSubmitImage2] = useState<File | undefined>(undefined);

  const [visible4, setVisible4] = useState<boolean>(false);
  const [isValidBusinessNumber, setIsValidBusinessNumber] = useState(false);

  const validateForm = () => {
    if (businessRegistrationNumber === "") {
      showAlert("사업자등록번호가 없습니다.");
      return false;
    }
    if (!validateNumberFormat(businessRegistrationNumber)) {
      showAlert("사업자등록번호는 숫자만 입력 가능합니다.");
      return false;
    }
    if (!isValidBusinessNumber) {
      showAlert("사업자등록번호 유효성 체크를 진행해주세요.");
      return false;
    }
    if (submitImage === undefined ) {
      showAlert("사업자등록증을 업로드 해야 합니다.");
      return false;
    }
    if (submitImage2 === undefined ) {
      showAlert("통장사본을 업로드 해야 합니다.");
      return false;
    }
    if (managerName === "") {
      showAlert("담당자명이 없습니다.");
      return false;
    }
    if (managerEmail === "") {
      showAlert("담당자이메일이 없습니다.");
      return false;
    }
    if (!validateEmailFormat(managerEmail)) {
      showAlert("이메일 형식이 맞지 않습니다.");
    }
    if (managerPhone === "") {
      showAlert("담당자연락처가 없습니다.");
      return false;
    }

    return true;
  };

  const registerBusinessUser = () => {
    if (!validateForm()) {
      return;
    }
    showConfirm("기업 인증을 요청하시겠습니까?");
  };

  const checkBusinessRegistrationNumber = () => {
    // 사업자등록번호 유효성체크
    setLoading(true);
    verifyBusinessRegistrationNumber(businessRegistrationNumber).then(
      (response) => {
        console.log(response);
        // @ts-ignore
        businessRegistrationNumberCheckText.current.innerText =
          response.message;
        if (response.status === "VALID") {
          // @ts-ignore
          businessRegistrationNumberCheckText.current.className =
            "text-green-500 text-sm";
          setIsValidBusinessNumber(true);
        } else {
          // @ts-ignore
          businessRegistrationNumberCheckText.current.className =
            "text-red-500 text-sm";
          setIsValidBusinessNumber(false);
        }
      }
    ).finally(()=>{
      setLoading(false);
    });
  };

  const onUploadImageButtonClicked = useCallback(() => {
    if (!cameraRef.current) {
      return;
    }
    cameraRef.current.click();
  }, []);

  const onUploadImageButtonClicked2 = useCallback(() => {
    if (!cameraRef2.current) {
      return;
    }
    cameraRef2.current.click();
  }, []);

  const onUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const selectedFile = e.target.files[0];

    // 파일사이즈 5MB 제한
    const maxSize = 5 * 1024 * 1024;

    if (selectedFile.size > maxSize) {
      showAlert("파일 크기는 5MB 미만만 업로드가 가능합니다.");
      return;
    }

    // 파일 이름을 state에 저장
    // setShowImage(selectedFile.name);

    // 단일 파일을 state에 저장
    setSubmitImage(selectedFile);
  };

  const onUploadImage2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const selectedFile = e.target.files[0];

    // 파일사이즈 5MB 제한
    const maxSize = 5 * 1024 * 1024;

    if (selectedFile.size > maxSize) {
      showAlert("파일 크기는 5MB 미만만 업로드가 가능합니다.");
      return;
    }

    // 파일 이름을 state에 저장
    // setShowImage2(selectedFile.name);

    // 단일 파일을 state에 저장
    setSubmitImage2(selectedFile);
  };

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const showAlert = (message:string) => {
    setAlertMessage(message);
    setAlertVisible(true);
  };
  const hideAlert = () => {
    setAlertVisible(false);
  };
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const showConfirm = (message:string) => {
    setConfirmMessage(message);
    setConfirmVisible(true);
  };
  const hideConfirm = () => {
    setConfirmVisible(false);
  };
  const handleConfirm = () => {
    // 확인이 선택되었을 때 수행할 동작
    const param = {
      businessRegistrationNumber: businessRegistrationNumber,
      managerName: managerName,
      managerEmail: managerEmail,
      managerPhone: managerPhone,
      businessRegistrationFile: submitImage,
      bankAccFile: submitImage2,
      siteName: "BETA-CHECK",
    };
    props.save(param);
  };

  useEffect(() => {
    // step1 에서 다시 복귀
    if (props.businessRegistrationNumber !== '') {
      setBusinessRegistrationNumber(props.businessRegistrationNumber);
    }
    if (props.businessRegistrationFile !== undefined) {
      setSubmitImage(props.businessRegistrationFile);
    }
    if (props.bankAccFile !== undefined) {
      setSubmitImage2(props.bankAccFile);
    }
    if (props.managerName !== '') {
      setManagerName(props.managerName);
    }
    if (props.managerEmail !== '') {
      setManagerEmail(props.managerEmail);
    }
    if (props.managerPhone !== '') {
      setManagerPhone(props.managerPhone);
    }
  }, []);

  return (
    <>
      <h1 className="pt-10 my-12 App">기업 인증하기</h1>
      <div className="min-h-screen">
        <div className={'grid grid-cols-1 lg:grid-cols-3'}>
          <div className={'h-96 mx-4 border rounded-xl shadow-lg p-4 mb-4'}>
            <ul>
              <li>기업회원은 입찰공고 열람, 내 건물 등록, 발주 가능</li>
              <li>00일 이내 관리자가 요청된 내용을 검토하여 승인</li>
              <li>승인 완료 후 이메일로 알림 전송</li>
            </ul>
          </div>
          <section className="space-y-4 pb-16 mx-4 lg:mx-0">
            {/* 4th portion  */}
            <div className="mb-3 p-4 border rounded-xl shadow-lg">
              <h3 className="mb-2 font-semibold">기업정보</h3>

              <hr/>

              <div className="mb-3">
                <label
                  htmlFor="businessRegistrationNumber"
                  className="block mt-3 mb-1 font-semibold"
                >
                  사업자등록번호
                  <span className="text-red-500">*</span>
                </label>
                <div className="grid grid-cols-3 gap-3">
                  <div className="col-span-2">
                    <input
                      type="text"
                      id="businessRegistrationNumber"
                      value={businessRegistrationNumber}
                      onChange={(e) => {
                        setBusinessRegistrationNumber(e.target.value);
                        setIsValidBusinessNumber(false);
                        // @ts-ignore
                        businessRegistrationNumberCheckText.current.className = "hidden text-sm";
                      }}
                      className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                      placeholder="'-'를 제외한 숫자만 입력해주세요"
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={checkBusinessRegistrationNumber}
                      className="w-full h-14 px-2 rounded-xl border border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white hover:border-transparent font-semibold"
                    >
                      유효성 체크
                    </button>
                  </div>
                  <div className={'col-span-3'}>
                    <div
                      ref={businessRegistrationNumberCheckText}
                      className="hidden text-sm"
                    ></div>
                  </div>

                </div>
              </div>

              <div className="mb-3 gap-3">
                <label className="block mt-3 mb-1 font-semibold">
                  필수 파일 업로드
                  <span className="text-red-500">*</span>
                </label>
                <div className={'text-red-500 text-sm'}>
                  <span>
                    <i className={'pi pi-info-circle m-1'} style={{fontSize: '1rem'}}></i>
                    5MB 미만의 .pdf .png .jpg .jpeg 확장자 파일만 업로드 가능합니다.
                  </span>
                </div>

                <div
                  className={'h-16 mt-3 bg-opacity-20 ps-4 grid grid-cols-3 gap-3 content-center rounded-md'}
                  style={{
                    backgroundColor: submitImage ? '#2AC9A444' : '#E0E0E0'
                  }}
                >
                  <div className={'col-span-2'}>
                    <span className={'w-full'}>
                      {
                        submitImage
                          ? <i className={'pi pi-check me-2'} style={{color: '#61D737'}}></i>
                          : <></>
                      }
                      사업자등록증
                    </span>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={onUploadImageButtonClicked}
                      className=" px-3 bg-white rounded-2xl border border-[#2AC9A4] text-[#2AC9A4] hover:bg-[#2AC9A4] hover:text-white hover:border-transparent font-semibold"
                    >
                      업로드
                      <i className="ps-3 pi pi-upload" style={{fontSize: '1rem'}}></i>
                    </button>
                    <input
                      type="file"
                      ref={cameraRef}
                      onChange={onUploadImage}
                      accept=".pdf, image/*"
                      className="hidden"
                    />
                  </div>
                </div>
              </div>
              <div className="border px-2 py-2 rounded-md">
                <div
                  className="flex flex-row-reverse items-center justify-end mb-1 gap-3"
                >
                  {
                    submitImage
                      ? <>
                        <span className={'ps-4'}>{submitImage?.name}</span>
                      </>
                      : <></>
                  }
                </div>
              </div>

              <div className="my-3 gap-3 justify-between">
                <div
                  className="h-16 mt-3 bg-opacity-20 ps-4 grid grid-cols-3 gap-3 content-center rounded-md"
                  style={{backgroundColor: submitImage2 ? '#2AC9A444' : '#E0E0E0'}}
                >
                  <div className={'col-span-2'}>
                    <span>
                      {
                        submitImage2
                          ? <i className={'pi pi-check me-2'} style={{color: '#61D737'}}></i>
                          : <></>
                      }
                      통장사본
                    </span>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={onUploadImageButtonClicked2}
                      className="px-3 bg-white rounded-2xl border border-[#2AC9A4] text-[#2AC9A4] hover:bg-[#2AC9A4] hover:text-white hover:border-transparent font-semibold"
                    >
                      업로드
                      <i className="ps-3 pi pi-upload" style={{fontSize: '1rem'}}></i>
                    </button>
                    <input
                      type="file"
                      ref={cameraRef2}
                      onChange={onUploadImage2}
                      accept=".pdf, image/*"
                      className="hidden"
                    />
                  </div>
                </div>
              </div>
              <div className="border px-2 py-2 rounded-md">
                <div
                  className="flex flex-row-reverse items-center justify-end mb-1 gap-3"
                >
                  {
                    submitImage2
                      ? <>
                        <span className={'ps-4'}>{submitImage2?.name}</span>
                      </>
                      : <></>
                  }
                </div>
              </div>
            </div>

            {/* 7th portion  */}
            <div className="mb-3 p-4 border rounded-xl shadow-lg">
              <h3 className="mb-2 font-semibold">담당자 정보</h3>

              <hr/>
              <div className="my-3">
                <label htmlFor="managerName" className="block mb-1 font-semibold">
                  담당자 이름<span className="text-red-500">*</span>
                </label>
                <div className="col-span-2">
                  <input
                    type="text"
                    id="managerName"
                    value={managerName}
                    onChange={(e) => setManagerName(e.target.value)}
                    className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                    placeholder="담당자 이름를 입력해주세요."
                  />
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="managerEmail"
                  className="block mb-1 font-semibold"
                >
                  담당자 이메일<span className="text-red-500">*</span>
                </label>
                <div className="col-span-2">
                  <input
                    type="email"
                    id="managerEmail"
                    value={managerEmail}
                    onChange={(e) => setManagerEmail(e.target.value)}
                    className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                    placeholder="담당자 Email를 입력해주세요."
                  />
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="managerPhone"
                  className="block mb-1 font-semibold"
                >
                  담당자 전화번호<span className="text-red-500">*</span>
                </label>
                <div className="col-span-2">
                  <input
                    type="tel"
                    id="managerPhone"
                    value={managerPhone}
                    onChange={(e) => setManagerPhone(e.target.value)}
                    className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                    placeholder="담당자 번호를 입력해주세요."
                  />
                </div>
              </div>
            </div>

            <div className="mt-8 flex justify-between gap-6">
              <button
                type="button"
                onClick={() => {
                  props.setBusinessUserObj({
                    businessRegistrationNumber: businessRegistrationNumber,
                    managerName: managerName,
                    managerEmail: managerEmail,
                    managerPhone: managerPhone,
                    businessRegistrationFile: submitImage,
                    bankAccFile: submitImage2
                  });
                  props.setStep(1);
                }}
                className="w-full hover:opacity-90 transition duration-300 ease-in-out bg-white text-primary py-2.5 rounded-xl border border-primary shadow-lg"
              >
                이전
              </button>
              <button
                type="button"
                onClick={registerBusinessUser}
                disabled={!isValidBusinessNumber || !submitImage || !submitImage2 || !managerName || !managerPhone || !managerEmail}
                className="shadow-lg w-full h-14 bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white py-2.5 rounded-xl disabled:bg-gray-500 disabled:from-gray-500 disabled:to-gray-500 disabled:border-none disabled:cursor-not-allowed"
              >
                기업인증 요청하기
              </button>
            </div>

          </section>
          {/*<div className={'grid grid-cols-subgrid gap-4'}>*/}
          {/*  <div className={'h-96 mx-4 border rounded-xl shadow-lg p-4'}>7</div>*/}
          {/*</div>*/}
        </div>
      </div>

      {/* cancel confirmation dialog dialog */}
      <Dialog
        visible={visible4}
        style={{width: "30vw"}}
        onHide={() => {
          if (!visible4) return;
          setVisible4(false);
        }}
      >
        <div className="my-2">
          <h3 className="text-2xl font-bold text-center my-4">
            입력된 내용은 저장되지 않습니다. <br/> 가입을 취소하시겠습니까?
          </h3>

          <div className="flex justify-between gap-6 px-6 mt-8">
            <button
              type="button"
              onClick={() => navigate("/")}
              className="w-full hover:opacity-90 transition duration-300 ease-in-out bg-white text-primary py-2.5 rounded-xl border border-primary"
            >
              가입 취소
            </button>
            <button
              type="button"
              onClick={() => setVisible4(false)}
              className="w-full hover:opacity-90 transition duration-300 ease-in-out bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white py-2.5 rounded-xl"
            >
              회원가입
            </button>
          </div>
        </div>
      </Dialog>

      <AlertDialog
        visible={alertVisible}
        onHide={hideAlert}
        message={alertMessage}
      />
      <ConfirmDialog
        visible={confirmVisible}
        onHide={hideConfirm}
        message={confirmMessage}
        onConfirm={handleConfirm}
      />

    </>
  );
}

export default CompanyRegistration;
