import React, {useCallback, useEffect, useRef, useState} from "react";
import {getBusinessUser} from "../../services/businessUserService";
import {downloadBUFile, getBusinessUserFiles, updateBusinessUser,} from "../../services/authoriedUserService";
import { validateEmailFormat } from "../../services/utils/formatUtil";
import AlertDialog from "../AlertDialog";
import {useLoading} from "../LoadingContext";

function MyInfoCompanyModify(props: any) {
  const cameraRef = useRef<HTMLInputElement | null>(null);
  const cameraRef2 = useRef<HTMLInputElement | null>(null);
  const [submitImage, setSubmitImage] = useState<File | undefined>(undefined);
  const [submitImage2, setSubmitImage2] = useState<File | undefined>(undefined);

  const {setLoading} = useLoading();

  const [businessUser, setBusinessUser] = useState({
    userId: props.props.userId,
    email: props.props.email,
    businessRegistrationNumber: "",
    representativeName: "",
    applyStatus: "",
    businessName: "",
    corporationRegistrationNumber: "",
    address: "",
    establishDate: "",
    managerEmail: "",
    managerPhone: "",
    managerName: "",
    shippingAddress: "",
    name: props.props.fullName,
    phone: props.props.phone,
    accessToken: "",
    bankName:"",
    bankAccNumber:"",
    bankAccOwner:""
  });

  const [managerName, setManagerName] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [managerPhone, setManagerPhone] = useState("");

  const [fileList, setFileList] = useState([
    {
      userId: "",
      fileName: "",
      fileUrl: "",
      fileSize: "",
      seq: 0,
      fileType: "",
      fileTypeName: "",
    },
  ]);
  const [businessRegistrationFile, setBusinessRegistrationFile] = useState({
    userId: "",
    fileName: "",
    fileUrl: "",
    fileSize: "",
    seq: 0,
    fileType: "",
    fileTypeName: "",
  });
  const [bankAccFile, setBankAccFile] = useState({
    userId: "",
    fileName: "",
    fileUrl: "",
    fileSize: "",
    seq: 0,
    fileType: "",
    fileTypeName: "",
  });

  const downloadFile = (file: any) => {
    console.log(file);
    downloadBUFile(file.seq, file.fileName).then();
  };

  const changeModifyMode = () => {
    // 뷰 모드로 변경
    props.setIsModifyMode(false);
  };

  const validateBusinessUser = () => {
    if (businessUser.email === null || businessUser.email === "") {
      alert("이메일이 비어있습니다.");
      return false;
    }
    if (!validateEmailFormat(businessUser.email)) {
      alert("이메일 형식이 잘못되었습니다.");
      return false;
    }
    if (businessUser.managerName === null || businessUser.managerName === "") {
      alert("담당자명이 비어있습니다.");
      return false;
    }
    if (
      businessUser.managerEmail === null ||
      businessUser.managerEmail === ""
    ) {
      alert("담당자 이메일이 비어있습니다.");
      return false;
    }
    if (!validateEmailFormat(businessUser.managerEmail)) {
      alert("담당자 이메일 형식이 잘못되었습니다.");
      return false;
    }
    if (
      businessUser.managerPhone === null ||
      businessUser.managerPhone === ""
    ) {
      alert("담당자 연락처가 비어있습니다.");
      return false;
    }
    return true;
  };

  const onUploadImageButtonClicked = useCallback(() => {
    if (!cameraRef.current) {
      return;
    }
    cameraRef.current.click();
  }, []);
  const onUploadImageButtonClicked2 = useCallback(() => {
    if (!cameraRef2.current) {
      return;
    }
    cameraRef2.current.click();
  }, []);

  const onUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const selectedFile = e.target.files[0];

    // 파일사이즈 5MB 제한
    const maxSize = 5 * 1024 * 1024;

    if (selectedFile.size > maxSize) {
      showAlert("파일 크기는 5MB 미만만 업로드가 가능합니다.");
      return;
    }
    // 단일 파일을 state에 저장
    setSubmitImage(selectedFile);
  };
  const onUploadImage2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const selectedFile = e.target.files[0];

    // 파일사이즈 5MB 제한
    const maxSize = 5 * 1024 * 1024;

    if (selectedFile.size > maxSize) {
      showAlert("파일 크기는 5MB 미만만 업로드가 가능합니다.");
      return;
    }
    // 단일 파일을 state에 저장
    setSubmitImage2(selectedFile);
  };

  const saveMyInfo = () => {
    const param = {
      userId: businessUser.userId,
      managerName: managerName,
      managerEmail: managerEmail,
      managerPhone: managerPhone,
      businessRegistrationFile: submitImage,
      bankAccFile: submitImage2
    };

    console.log('saveMyInfo', param);

    if (window.confirm("기업정보 수정을 요청하시겠습니까?")) {
      if (validateBusinessUser()) {
        setLoading(true);
        updateBusinessUser(param).then(() => {
          showAlert("요청이 완료되었습니다. 상세 정보는 관리자 승인 후 수정 내용이 반영됩니다.");
          // 뷰 모드로 변경
          props.setIsModifyMode(false);
        }).finally(()=>{
          setLoading(false);
        });
      }
    }
  };

  useEffect(() => {
    getBusinessUser(props.props.userId).then((data) => {
      setBusinessUser({
        userId: props.props.userId,
        email: props.props.email,
        businessRegistrationNumber: data.businessRegistrationNumber,
        representativeName: data.representativeName,
        applyStatus: data.applyStatus,
        businessName: data.businessName,
        corporationRegistrationNumber: data.corporationRegistrationNumber,
        address: data.address,
        establishDate: data.establishDate,
        managerEmail: data.managerEmail,
        managerPhone: data.managerPhone,
        managerName: data.managerName,
        shippingAddress: data.shippingAddress,
        name: props.props.fullName,
        phone: props.props.phone,
        accessToken: props.props.accessToken,
        bankName: props.props.bankNAme,
        bankAccNumber: props.props.bankAccNumber,
        bankAccOwner: props.props.bankAccOwner
      });

      setManagerName(data.managerName);
      setManagerEmail(data.managerEmail);
      setManagerPhone(data.managerPhone);
    });

    getBusinessUserFiles(props.props.userId).then((data) => {
      setFileList(data);
      console.log("fileList", data);
      for (let file of data) {
        if (file.fileType === 'Business') {
          setBusinessRegistrationFile(file);
        } else if (file.fileType === 'bankAccFile') {
          setBankAccFile(file);
        }
      }
    });
  }, []);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const showAlert = (message:string) => {
    setAlertMessage(message);
    setAlertVisible(true);
  };
  const hideAlert = () => {
    setAlertVisible(false);
  };

  return (
    <>
      <div className={'grid grid-cols-1 lg:grid-cols-3 gap-4 mt-4'}>
        <div className={'h-96 mb-4 border rounded-xl shadow-lg p-4'}>
          <ul>
            <li>우리 서비스에서 용역 발주는 사업자등록증 인증을 받은 기업회원만 가능</li>
            <li>용역 수행이 가능한 조건의 증명 서류를 제출한 후 인증 받으면 역할회원 가능</li>
            <li>역할회원은 가입 후 마이페이지에서도 인증 받을 수 있음</li>
          </ul>
        </div>
        <div className={'grid grid-cols-subgrid'}>
          <div className={'grid grid-cols-subgrid'}>
            <div className="mb-3 p-4 border rounded-xl shadow-lg">
              <h2 className="mb-2 font-semibold">기업정보</h2>

              <hr/>

              <div className="mb-3">
                <label
                  htmlFor="businessRegistrationNumber"
                  className="block mt-3 mb-1 font-semibold"
                >
                  사업자등록번호
                </label>
                <div className="grid grid-cols-3 gap-3">
                  <div className="col-span-3">
                    <input
                      type="text"
                      id="businessRegistrationNumber"
                      value={businessUser.businessRegistrationNumber}
                      className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="mb-3 gap-3">
                <label className="block mt-3 mb-1 font-semibold">
                  필수 파일 업로드
                  <span className="text-red-500">*</span>
                </label>
                <div className={'text-red-500 text-sm'}>
                  <span>
                    <i className={'pi pi-info-circle m-1'} style={{fontSize: '1rem'}}></i>
                    5MB 미만의 .pdf .png .jpg .jpeg 확장자 파일만 업로드 가능합니다.
                  </span>
                </div>

                <div
                  className={'h-16 mt-3 bg-opacity-20 ps-4 grid grid-cols-3 gap-3 content-center rounded-md'}
                  style={{
                    backgroundColor: submitImage ? '#2AC9A444' : '#3F80EA36'
                  }}
                >
                  <div className={'col-span-3'}>
                    <div className={'grid grid-cols-3'}>
                    <span className={'w-full font-semibold'}>
                      {
                        submitImage
                          ? <i className={'pi pi-check me-2'} style={{color: '#61D737'}}></i>
                          : <></>
                      }
                      사업자등록증
                    </span>
                      <span>
                      <button
                        onClick={() => {
                          downloadFile(businessRegistrationFile);
                        }}
                        type="button"
                        className="px-3 bg-white rounded-2xl border border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white hover:border-transparent font-semibold"
                      >
                        파일 다운로드
                      </button>
                    </span>
                      <span>
                      <button
                        type="button"
                        onClick={onUploadImageButtonClicked}
                        className=" px-3 bg-white rounded-2xl border border-[#2AC9A4] text-[#2AC9A4] hover:bg-[#2AC9A4] hover:text-white hover:border-transparent font-semibold"
                      >
                      업로드
                      <i className="ps-3 pi pi-upload" style={{fontSize: '1rem'}}></i>
                    </button>
                    <input
                      type="file"
                      ref={cameraRef}
                      onChange={onUploadImage}
                      accept=".pdf, image/*"
                      className="hidden"
                    />
                    </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border px-2 py-2 rounded-md">
                <div
                  className="flex flex-row-reverse items-center justify-end mb-1 gap-3"
                >
                  {
                    submitImage
                      ? <>
                        <span className={'ps-4'}>{submitImage?.name}</span>
                      </>
                      : <></>
                  }
                </div>
              </div>

              <div className="my-3 gap-3 justify-between">
                <div
                  className="h-16 mt-3 bg-opacity-20 ps-4 grid grid-cols-3 gap-3 content-center rounded-md"
                  style={{
                    backgroundColor: submitImage2 ? '#2AC9A444' : '#3F80EA36'
                  }}
                >
                  <div className={'col-span-3'}>
                    <div className={'grid grid-cols-3'}>
                    <span className={'font-semibold'}>
                      {
                        submitImage2
                        ? <i className={'pi pi-check me-2'} style={{color: '#61D737'}}></i>
                        : <></>
                      }
                      통장사본
                    </span>
                      <span>
                      <button
                        onClick={() => {
                          downloadFile(bankAccFile);
                        }}
                        type="button"
                        className="px-3 bg-white rounded-2xl border border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white hover:border-transparent font-semibold"
                      >
                        파일 다운로드
                      </button>
                    </span>
                      <span>
                        <button
                          type="button"
                          onClick={onUploadImageButtonClicked2}
                          className="px-3 bg-white rounded-2xl border border-[#2AC9A4] text-[#2AC9A4] hover:bg-[#2AC9A4] hover:text-white hover:border-transparent font-semibold"
                        >
                        업로드
                        <i className="ps-3 pi pi-upload" style={{fontSize: '1rem'}}></i>
                      </button>
                      <input
                        type="file"
                        ref={cameraRef2}
                        onChange={onUploadImage2}
                        accept=".pdf, image/*"
                        className="hidden"
                      />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border px-2 py-2 rounded-md">
                <div
                  className="flex flex-row-reverse items-center justify-end mb-1 gap-3"
                >
                  {
                    submitImage2
                      ? <>
                        <span className={'ps-4'}>{submitImage2?.name}</span>
                      </>
                      : <></>
                  }
                </div>
              </div>

            </div>

            <div className="mb-3 p-4 border rounded-xl shadow-lg">
              <h2 className="mb-2 font-semibold">담당자 정보</h2>

              <hr/>
              <div className="my-3">
                <label htmlFor="managerName" className="block mb-1 font-semibold">
                  담당자 이름<span className="text-red-500">*</span>
                </label>
                <div className="col-span-3">
                  <input
                    type="text"
                    id="managerName"
                    value={managerName}
                    onChange={(e) => setManagerName(e.target.value)}
                    className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  />
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="managerEmail"
                  className="block mb-1 font-semibold"
                >
                  담당자 이메일<span className="text-red-500">*</span>
                </label>
                <div className="col-span-3">
                  <input
                    type="email"
                    id="managerEmail"
                    value={managerEmail}
                    onChange={(e) => setManagerEmail(e.target.value)}
                    className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  />
                </div>
              </div>

              <div className="mb-3">
                <label
                  htmlFor="managerPhone"
                  className="block mb-1 font-semibold"
                >
                  담당자 전화번호<span className="text-red-500">*</span>
                </label>
                <div className="col-span-2">
                  <input
                    type="tel"
                    id="managerPhone"
                    value={managerPhone}
                    onChange={(e) => setManagerPhone(e.target.value)}
                    className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={'grid grid-cols-subgrid'}>
          <div className="mb-3 p-4 border rounded-xl shadow-lg h-max">
            <h2 className="mb-2 font-semibold">상세 정보</h2>

            <hr/>
            <div className="my-3">
              <label htmlFor="corporationRegistrationNumber" className="block mb-1 font-semibold">
                법인등록번호
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  value={businessUser.corporationRegistrationNumber}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>

            <div className="mb-3">
              <label
                htmlFor="establishDate"
                className="block mb-1 font-semibold"
              >
                설립일
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  value={businessUser.establishDate}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>

            <div className="mb-3">
              <label
                htmlFor="managerPhone"
                className="block mb-1 font-semibold"
              >
                기업명
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  value={businessUser.businessName}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="representativeName"
                className="block mb-1 font-semibold"
              >
                대표자명
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  value={businessUser.representativeName}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="address"
                className="block mb-1 font-semibold"
              >
                주소
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  value={businessUser.address}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="managerPhone"
                className="block mb-1 font-semibold"
              >
                예금주명
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  value={businessUser.bankAccOwner}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="managerPhone"
                className="block mb-1 font-semibold"
              >
                계좌번호
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  value={businessUser.bankAccNumber}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="managerPhone"
                className="block mb-1 font-semibold"
              >
                은행
              </label>
              <div className="col-span-3">
                <input
                  type="text"
                  value={businessUser.bankName}
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5 mb-5 text-center gap-4">
        <button
          type="button"
          onClick={() => {
            if (window.confirm("입력된 내용은 저장되지 않습니다. 페이지를 나가시겠습니까?")) {
              changeModifyMode();
            }
          }}
          className="h-12 hover:opacity-90 transition duration-300 ease-in-out bg-white text-primary py-2.5 rounded-xl border border-primary shadow-lg w-1/2 lg:w-1/6"
        >
          이전
        </button>
        <button
          onClick={saveMyInfo}
          type="button"
          className="h-12 hover:opacity-90 transition duration-300 ease-in-out bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white font-bold py-2 px-4 rounded-xl w-1/2 lg:w-1/6"
        >
          수정 요청하기
        </button>
      </div>

      <AlertDialog
        visible={alertVisible}
        onHide={hideAlert}
        message={alertMessage}
      />

    </>
  );
}

export default MyInfoCompanyModify;
