import axiosInstance from "./utils/axiosInstance";

// const BASE_URL = 'http://localhost:8080';
const BASE_URL = "https://user-api.beta-check.com";


export async function verifyBusinessNumber(businessNumber) {
  let url = `${BASE_URL}/business/verifyBusinessNumber`;
  if (businessNumber !== undefined) {
    url += `?businessNumber=${businessNumber}`;
  }
  const response = await axiosInstance.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function updatePassword(param) {
  const response = await axiosInstance.post(`${BASE_URL}/auth/updatePassword`, param, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function sendEmail(param) {
  const response = await axiosInstance.post(
      `${BASE_URL}/notification/sendEmail`,
      param,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
  );
  return response.data;
}

// 마이페이지 정보 업데이트
export async function updateUser(param) {
  const response = await axiosInstance.post(`${BASE_URL}/auth/updateUser`, param, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function getMygongsaBankAccount() {
  let url = `${BASE_URL}/auth/getMygongsaBankAcc`;
  const response = await axiosInstance.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function getTermsOfUseAgree(param) {
  let url = `${BASE_URL}/auth/getTermsOfUseAgree`;
  const response = await axiosInstance.post(url, param, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}



// Business User APIs

// 마이페이지에서 정보 업데이트
export async function updateBusinessUser(businessUser) {
  const response = await axiosInstance.post(
      `${BASE_URL}/business/updateBusinessUser`
      , businessUser
      , {headers: {
          "Content-Type": 'multipart/form-data'
        }}
  );

  return response.data;
}
export async function getBusinessUserFiles(userId) {
  let url = `${BASE_URL}/business/getBusinessUserFiles`;
  if (userId !== undefined) {
    url += `?userId=${userId}`;
  }
  const response = await axiosInstance.get(
      url
      , {
        headers: {
          "Content-Type": 'application/json'
        },
      }
  );
  return response.data;
}

export async function downloadBUFile(fileSeq, fileName){
  let url = `${BASE_URL}/business/downloadFile`;
  if (fileSeq !== undefined) {
    url += `?fileSeq=${fileSeq}`;
  }
  const response = await axiosInstance
      .get(url, {
        responseType: "blob"
        , headers: {
          "Content-Type": 'application/json'
        },
      })
      .then((response) => {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");

        a.href = fileUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(fileUrl);
        document.body.removeChild(a);
      })
      .catch((error) => {
        console.error("파일 다운로드 오류:", error);
      });
}