import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function UserRegistrationComplete() {
  const navigate = useNavigate();
  const goSignIn = () => {
    navigate("/userSignIn");
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="content">
        <div className="max-w-md mx-auto px-4 py-8 text-center min-h-screen">
          <h3 className="text-2xl font-bold mb-6">회원 가입 완료</h3>
          <button
            className="px-4 py-2 text-white rounded bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA]"
            onClick={goSignIn}
          >
            로그인 하러 가기
          </button>
        </div>
      </div>
    </>
  );
}

export default UserRegistrationComplete;
