import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Image } from "primereact/image";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserInfoContext } from "../../App";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import camera_icon from "../../images/svg/camera_icon.svg";
import {
  getIACInfraTypeBase,
  saveIACInfra,
} from "../../services/serviceBidService";

function AddNewIACFacility() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // @ts-ignore
  let { userInfo, logout } = useContext(UserInfoContext);
  const { buildingId, quotationSeq } = useParams();

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [etcSelected, setEtcSelected] = useState(false);
  const [showImages, setShowImages] = useState([]);
  const [submitImageList, setSubmitImageList] = useState<File[]>([]);

  const [IACFacility, setIACFacility] = useState({
    createUser: userInfo.userId,
    buildingId: parseInt(buildingId as string),
    infraTypeSeq: null as number | null,
    infraTypeName: "" as string,
    name: "" as string,
    modelName: "" as string,
    quantity: null as number | null,
    manufacturer: "" as string,
    location: "" as string,
    installYear: "" as string,
    description: "" as string,
    files: [] as File[],
    quotationSeq: parseInt(quotationSeq as string)
  });
  console.log("IACFacility", IACFacility);

  const saveIACFacility = () => {
    if (window.confirm("입력한 설비를 저장하시겠습니까?")) {
      setLoading(true);
      saveIACInfra(IACFacility).then((result) => {
        if (result.status === 200) {
          alert("설비가 저장되었습니다.");

          if (window.location.pathname.includes("addNewAssignedIACFacility")) {
            navigate(-1);
          } else {
            navigate("/buildingDetail/" + buildingId);
          }
        } else {
          alert("오류가 발생했습니다. 관리자에게 문의하세요.");
        }
      }).catch((error)=>{
        console.log(error);
        if (error === '토큰 만료, 로그아웃 처리됨.') {
          alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
          logout();
        }
      }).finally(()=>{
        setLoading(false);
      });
    }
  };

  const cameraRef = useRef<HTMLInputElement | null>(null);
  const onUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const imageList = e.target.files;
    let imageUrlList: any = [...showImages];

    for (let i = 0; i < imageList.length; i++) {
      const currentImageUrl = URL.createObjectURL(imageList[i]);
      imageUrlList.push(currentImageUrl);
    }

    if (imageUrlList.length > 10) {
      alert("이미지는 10장까지 업로드가 가능합니다.");
      // imageUrlList = imageUrlList.slice(0, 10);
      return;
    }

    let finalImageList = Array.from(submitImageList).concat(
      Array.from(imageList)
    );
    setSubmitImageList(finalImageList);

    setIACFacility({ ...IACFacility, files: finalImageList });

    setShowImages(imageUrlList);

    // file input 값 초기화
    e.target.value = "";
  };

  const onDeleteImage = (id: any) => {
    setShowImages(showImages.filter((_, index) => index !== id));
    setSubmitImageList(submitImageList.filter((_, index) => index !== id));
    setIACFacility({
      ...IACFacility,
      files: submitImageList.filter((_, index) => index !== id),
    });
  };

  const onUploadImageButtonClicked = useCallback(() => {
    if (!cameraRef.current) {
      return;
    }
    cameraRef.current.click();
  }, []);

  useEffect(() => {

    console.log("quotationSeq", quotationSeq);


    setLoading(true);
    getIACInfraTypeBase().then((result) => {
      const tempList: any = [];
      for (const item of result) {
        tempList.push({
          id: item.infraTypeSeq,
          name: item.infraTypeName,
        });
      }

      setOptions(tempList);
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <Header></Header>
      <div className="content spacing pb-20 pt-10">
        <h1 className="py-12 font-semibold text-3xl">새 정보통신설비 추가</h1>

        <div className="max-w-[500px] mx-auto border bg-white rounded-lg shadow-sm p-6">
          <div className="flex flex-col items-start mb-8">
            <div className="text-base font-semibold mb-2">설비유형</div>
            <Dropdown
              value={selectedOption}
              onChange={(e: DropdownChangeEvent) => {
                setSelectedOption(e.value);
                setIACFacility({
                  ...IACFacility,
                  infraTypeSeq: e.value.id,
                  infraTypeName: e.value.name,
                });
                if (e.value.id === 35) {
                  setEtcSelected(true);
                } else {
                  setEtcSelected(false);
                }
              }}
              options={options}
              optionLabel="name"
              placeholder="선택"
              className="w-full border rounded-xl text-start placeholder:text-[#E0E0E0]"
              style={{ boxShadow: "0px 0px 100px #5A5A5A14" }}
            />
          </div>

          {etcSelected ? (
            <div className="flex flex-col items-start mb-8">
              <div className="text-base font-semibold mb-2">설비유형명</div>
              <input
                type="text"
                name="infraTypeName"
                placeholder="설비유형명을 입력해주세요."
                value={IACFacility.infraTypeName}
                onChange={(e) => {
                  setIACFacility({
                    ...IACFacility,
                    infraTypeName: e.target.value,
                  });
                }}
                className="w-full p-3 border rounded-xl focus:outline-none focus:border-blue-500 transition duration-300 placeholder:text-[#E0E0E0]"
                style={{ boxShadow: "0px 0px 100px #5A5A5A14" }}
              />
            </div>
          ) : (
            <></>
          )}

          <div className="flex flex-col items-start mb-8">
            <div className="text-base font-semibold mb-2">설비명</div>
            <input
              type="text"
              name="name"
              placeholder="설비명을 입력해주세요."
              value={IACFacility.name}
              onChange={(e) => {
                setIACFacility({ ...IACFacility, name: e.target.value });
              }}
              className="w-full p-3 border rounded-xl focus:outline-none focus:border-blue-500 transition duration-300 placeholder:text-[#E0E0E0]"
              style={{ boxShadow: "0px 0px 100px #5A5A5A14" }}
            />
          </div>

          <div className="flex items-center gap-4">
            <div className="flex flex-col items-start mb-8">
              <div className="text-base font-semibold mb-2">규격(모델명)</div>
              <input
                type="text"
                name="modelName"
                placeholder="규격을 입력해주세요."
                value={IACFacility.modelName}
                onChange={(e) => {
                  setIACFacility({
                    ...IACFacility,
                    modelName: e.target.value,
                  });
                }}
                className="w-full p-3 border rounded-xl focus:outline-none focus:border-blue-500 transition duration-300 placeholder:text-[#E0E0E0]"
                style={{ boxShadow: "0px 0px 100px #5A5A5A14" }}
              />
            </div>

            <div className="flex flex-col items-start mb-8">
              <div className="text-base font-semibold mb-2">수량</div>
              <input
                type="number"
                name="quantity"
                placeholder="수량을 입력해주세요."
                value={
                  IACFacility.quantity === null ? "" : IACFacility.quantity
                }
                onChange={(e) => {
                  setIACFacility({
                    ...IACFacility,
                    quantity: parseInt(e.target.value),
                  });
                }}
                className="w-full p-3 border rounded-xl focus:outline-none focus:border-blue-500 transition duration-300 placeholder:text-[#E0E0E0]"
                style={{ boxShadow: "0px 0px 100px #5A5A5A14" }}
              />
            </div>
          </div>

          <div className="flex flex-col items-start mb-8">
            <div className="text-base font-semibold mb-2">제조사</div>
            <input
              type="text"
              name="manufacturer"
              placeholder="제조사를 입력해주세요."
              value={IACFacility.manufacturer}
              onChange={(e) => {
                setIACFacility({
                  ...IACFacility,
                  manufacturer: e.target.value,
                });
              }}
              className="w-full p-3 border rounded-xl focus:outline-none focus:border-blue-500 transition duration-300 placeholder:text-[#E0E0E0]"
              style={{ boxShadow: "0px 0px 100px #5A5A5A14" }}
            />
          </div>

          <div className="flex flex-col items-start mb-8">
            <div className="text-base font-semibold mb-2">설치 위치</div>
            <input
              type="text"
              name="location"
              placeholder="설치 위치를 입력해주세요."
              value={IACFacility.location}
              onChange={(e) => {
                setIACFacility({
                  ...IACFacility,
                  location: e.target.value,
                });
              }}
              className="w-full p-3 border rounded-xl focus:outline-none focus:border-blue-500 transition duration-300 placeholder:text-[#E0E0E0]"
              style={{ boxShadow: "0px 0px 100px #5A5A5A14" }}
            />
          </div>

          <div className="flex flex-col items-start mb-8">
            <div className="text-base font-semibold mb-2">설치 연도</div>
            <input
              type="number"
              name="installYear"
              placeholder="설치 연도를 입력해주세요."
              value={IACFacility.installYear}
              onChange={(e) => {
                setIACFacility({
                  ...IACFacility,
                  installYear: e.target.value,
                });
              }}
              className="w-full p-3 border rounded-xl focus:outline-none focus:border-blue-500 transition duration-300 placeholder:text-[#E0E0E0]"
              style={{ boxShadow: "0px 0px 100px #5A5A5A14" }}
            />
          </div>

          <div className="flex flex-col items-start mb-8">
            <div className="text-base font-semibold mb-2">비고</div>
            <textarea
              name="description"
              rows={4}
              value={IACFacility.description}
              onChange={(e) => {
                setIACFacility({
                  ...IACFacility,
                  description: e.target.value,
                });
              }}
              className="p-3 shadow-[0px 0px 20px #5A5A5A14] border bg-white rounded-md text-base w-full placeholder:text-[#BDBDBD]"
              style={{ boxShadow: "0px 0px 100px #5A5A5A14" }}
            ></textarea>
          </div>

          <div className="flex flex-col items-start mb-8">
            <div className="text-base font-semibold mb-2">첨부파일</div>
            <button
              onClick={onUploadImageButtonClicked}
              className="flex justify-center items-center w-full p-3 bg-[#424242] disabled:bg-[#DADADA] hover:bg-[#242424] duration-300 text-base text-white rounded-xl"
              style={{ boxShadow: "0px 0px 100px #5A5A5A14" }}
            >
              <img src={camera_icon} alt="camera_icon" />
            </button>

            <input
              type="file"
              name="files"
              multiple={true}
              accept={"image/*"}
              ref={cameraRef}
              onChange={onUploadImage}
              className="hidden"
            />

            <div className="inline-block mt-6">
              {showImages.map((imageUrl, id) => (
                <div key={id} className="image-wrapper">
                  <Image src={imageUrl} alt="image" preview />
                  <span
                    className={
                      "image-delete-button rounded-circle bg-transparent"
                    }
                    onClick={() => onDeleteImage(id)}
                  >
                    <i className={"pi pi-times-circle"}></i>
                  </span>
                </div>
              ))}
            </div>
          </div>

          <button
            disabled={
              IACFacility.infraTypeSeq === null ||
              IACFacility.name === "" ||
              IACFacility.modelName === "" ||
              IACFacility.quantity === null ||
              Number.isNaN(IACFacility.quantity) ||
              IACFacility.manufacturer === "" ||
              IACFacility.location === "" ||
              IACFacility.installYear === ""
            }
            onClick={saveIACFacility}
            className="m-6 bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] w-1/2 p-3 disabled:opacity-50 hover:opacity-90 duration-300 text-base font-semibold text-white rounded-xl"
          >
            저장하기
          </button>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default AddNewIACFacility;
