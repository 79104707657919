import React, {useState} from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import PersonRegistration from "../../components/UserRegistration/PersonRegistration";
import CompanyRegistration from "../../components/UserRegistration/CompanyRegistration";
import usePreventClose from "../../services/utils/usePreventClose";
import {saveBusinessUser} from "../../services/businessUserService";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {useLoading} from "../../components/LoadingContext";

function UserRegistration() {
  const navigate = useNavigate();
  const {setLoading} = useLoading();
  usePreventClose();

  interface user {
    userId: string,
    email: string,
    password: string,
    name: string,
    phone: string
  }

  interface businessUser {
    businessRegistrationNumber: string,
    managerName: string,
    managerEmail: string,
    managerPhone: string,
    businessRegistrationFile: File,
    bankAccFile: File,
    siteName: string,
  }

  const [visible5, setVisible5] = useState<boolean>(false);
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState("");
  const [managerName, setManagerName] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [managerPhone, setManagerPhone] = useState("");
  const [businessRegistrationFile, setBusinessRegistrationFile] = useState<File>();
  const [bankAccFile, setBankAccFile] = useState<File>();

  const [step, setStep] = useState(1);

  const onSetUserObj = (param:user) => {
    setUserId(param.userId);
    setEmail(param.email);
    setPassword(param.password);
    setName(param.name);
    setPhone(param.phone);
  }

  const onSetBusinessUserObj = (param:businessUser) => {
    setBusinessRegistrationNumber(param.businessRegistrationNumber);
    setManagerEmail(param.managerEmail);
    setManagerPhone(param.managerPhone);
    setManagerName(param.managerName);
    setBusinessRegistrationFile(param.businessRegistrationFile);
    setBankAccFile(param.bankAccFile);
  }

  const handleSaveBusinessUser = (param:businessUser) => {
    const bu = {
      userId: userId,
      email: email,
      password: password,
      name: name,
      phone: phone,
      companyYn: 'Y',
      siteName: 'BETA-CHECK',
      termList: ["COMMON", "PRIVACY"]
      , bankAccFile: param.bankAccFile
      , businessRegistrationFile: param.businessRegistrationFile
      , businessRegistrationNumber: param.businessRegistrationNumber
      , managerName: param.managerName
      , managerEmail: param.managerEmail
      , managerPhone: param.managerPhone
    }

    setLoading(true);
    saveBusinessUser(bu).then((signUpResponse) => {
      console.log(signUpResponse.status);
      console.log(signUpResponse.message);
      if (signUpResponse.status === "SUCCESS") {
        setVisible5(true);
      }
    }).finally(()=>{
      setLoading(false);
    });
  };

  return (
    <>
      <Header />
      <div className={'grid grid-cols-12 content'}>
        <div className={'col-span-2'}></div>
        <div className={'col-span-8'}>
          <div className="text-left min-h-screen">
            <div className="mx-auto">
              {
                step === 1
                  ? <PersonRegistration
                    setStep={setStep}
                    setUserObj={onSetUserObj}
                    userId={userId}
                    email={email}
                    password={password}
                    name={name}
                    phone={phone}
                  />
                  : <></>
              }
              {
                step === 2
                  ? <CompanyRegistration
                    setStep={setStep}
                    save={handleSaveBusinessUser}
                    setBusinessUserObj={onSetBusinessUserObj}
                    businessRegistrationNumber={businessRegistrationNumber}
                    managerName={managerName}
                    managerEmail={managerEmail}
                    managerPhone={managerPhone}
                    businessRegistrationFile={businessRegistrationFile}
                    bankAccFile={bankAccFile}
                  />
                  : <></>
              }
            </div>
          </div>
        </div>
        <div className={'col-span-2'}></div>
      </div>
      <Footer/>

      {/* registration complete dialog */}
      <Dialog
        visible={visible5}
        modal
        onHide={() => {
          if (!visible5) return;
          setVisible5(false);
        }}
        content={({hide}) => (
          <div className="p-10 gap-4 bg-white rounded-md">
            <div className="space-y-16">
              <h3 className="text-xl text-center font-semibold">
                기업인증이 요청되었습니다.<br/>
                관리자 승인 후 가입이 완료됩니다.
              </h3>

              <Button
                onClick={(e) => {
                  navigate("/additionalRegistration", {
                    state: {userId: userId}
                  });
                  hide(e);
                }}
                className="w-full hover:opacity-90 transition duration-300 ease-in-out active:bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white py-2.5 rounded-xl"
                label="확인"
              />
            </div>
          </div>
        )}
      ></Dialog>

    </>
  );
}

export default UserRegistration;
