import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserInfoContext } from "../../App";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import delete_icon_blue from "../../images/svg/delete_icon_blue.svg";
import warning_badge from "../../images/svg/warning_badge.svg";
import {
  getIACInfraTypeBase,
  getMyBuilding,
  getSummaryIACInfra,
  getlistIACInfra,
  removeIACInfra,
  updateBuildingQuotationStatus, getQuotationListByBuildingId, getBuildingDongList, requestBuildingQuotation,
} from "../../services/serviceBidService";
import {Accordion, AccordionTab} from "primereact/accordion";
import {MultiSelect} from "primereact/multiselect";

interface Infra {
  seq: number;
  buildingId: number;
  infraTypeName: string;
  name: string;
  modelName: string;
  quantity: number;
  manufacturer: string;
  location: string;
  installYear: string;
  description: string;
}
interface BuildingDong {
  seq: number,
  address: string,
  totalArea: number,
  mainPurposeCode: string,
  mainPurposeName: string,
  useApproveDay: string,
  dongName: string,
  buildingId: number,
  createDate: string,
  createUser: string,
  updateDate: string,
  updateUser: string
}

interface InfraQuantitySummary {
  infraTypeSeq: number;
  infraTypeName: string;
  quantity: number;
}

function MyBuildingIAC() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // @ts-ignore
  let { userInfo, logout } = useContext(UserInfoContext);
  const { buildingId } = useParams();

  const [buildingInfo, setBuildingInfo] = useState({
    buildingName: "",
    address: "",
    totalArea: 0,
    quotationYn: "",
    quotationStatus: "",
    createDate: "",
    updateDate: "",
    quotationUserId: "",
  });
  const [infraList, setInfraList] = useState<Infra[]>([]);
  const [selectedInfra, setSelectedInfra] = useState<Infra[]>([]);
  const [infraQuantitySummary, setInfraQuantitySummary] = useState<
    InfraQuantitySummary[]
  >([]);
  const [infraTypeNameOptions, setInfraTypeNameOptions] = useState([]);
  const [selectedInfraType, setSelectedInfraType] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [submitModalvisible, setSubmitModalvisible] = useState(false);
  const [confirmModalvisible, setconfirmModalvisible] = useState(false);
  const [quotationVersion, setQuotationVersion] = useState({
    quotationSeq: 0,
    createDate: "",
    status: ""
  });
  const [options, setOptions] = useState([
    {
      quotationSeq: 0,
      createDate: "",
      status: ""
    }
  ]);

  const [buildingDongList, setBuildingDongList] = useState<BuildingDong[]>([]);
  const [canRegisterInfra, setCanRegisterInfra] = useState(true);

  const searchInfra = () => {
    setLoading(true);
    getlistIACInfra(buildingId, quotationVersion.quotationSeq, selectedInfraType, searchText).then(
      (result) => {
        setInfraList(result);
      }
    ).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });
  };

  const deleteSelectedInfra = () => {
    if (!window.confirm('설비를 삭제하시겠습니까?')) {
      return;
    }

    let payload = selectedInfra.map((item) => {
      return {
        buildingId: item.buildingId,
        iacInfraSeq: item.seq,
        updateUser: userInfo.userId,
      };
    });

    setLoading(true);
    removeIACInfra(payload).then((result) => {
      if (result.status === 200) {
        searchInfra();
        setSelectedInfra([]);
        alert('삭제가 완료되었습니다.');
      } else {
        alert("오류가 발생했습니다. 관리자에게 문의하세요.");
      }
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });

  };

  const selectQuotationVersion = (quotationSeq:number) => {
    getSummaryIACInfra(buildingId, quotationSeq).then((result) => {
      setInfraQuantitySummary(result);
    });
    getIACInfraTypeBase().then((result) => {
      const tempList: any = [];
      for (const item of result) {
        tempList.push({
          name: item.infraTypeName,
          value: item.infraTypeSeq,
        });
      }
      setInfraTypeNameOptions(tempList);
    });
    getlistIACInfra(buildingId, quotationSeq, selectedInfraType, searchText).then(
      (result) => {
        setInfraList(result);
      }
    );
  }

  const startNewQuotation = () => {
    if (!window.confirm("설비 직접입력을 시작하시겠습니까?")) {
      return;
    }
    const param = {
      userId: userInfo.userId,
      price: 0,
      buildingIdList: [buildingId],
      quotationStatus: 'DOING'
    }
    requestBuildingQuotation(param).then(()=>{
      alert('설비 직접입력을 시작하였습니다.');
      setCanRegisterInfra(false);
    });

  };

  const infraNameBody = (rowData: any)=> {
    return (
      <a
        onClick={() => navigate("/infraDetail/" + buildingId + "/" + rowData.seq)}
        className={'cursor-pointer text-blue-600'}
      >
        {rowData.name}
      </a>
    )
  };

  const changeQuotationStatus = () => {
    let payload = {
      buildingId: parseInt(buildingId as string),
      quotationSeq: quotationVersion.quotationSeq,
      updateUser: userInfo.userId,
      quotationStatus: 'DONE'
    };

    setLoading(true);
    updateBuildingQuotationStatus(payload).then((result) => {
      if (result.status === 200) {
        setSubmitModalvisible(false);
        setconfirmModalvisible(true);
        // setBuildingInfo({ ...buildingInfo, quotationYn: "Y" });
        getMyBuilding(buildingId, userInfo.userId).then((result) => {
          setBuildingInfo(result);
        });
      } else {
        alert("오류가 발생했습니다. 관리자에게 문의하세요.");
      }
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    getMyBuilding(buildingId, userInfo.userId).then((result) => {
      if (!result) {
        alert('건물 정보를 조회할 수 없습니다.');
        navigate('/myBuildingList');
      }
      setBuildingInfo(result);
      getBuildingDongList(buildingId).then((result)=>{
        console.log('build dong list', result);
        setBuildingDongList(result);
      });
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });

    getQuotationListByBuildingId(buildingId).then((result)=>{
      if (result.length === 0) {
        return;
      }

      console.log('getQuotationListByBuildingId', result);
      setOptions(result);
      setQuotationVersion(result[0]);

      selectQuotationVersion(result[0].quotationSeq);

      // 상태가 REQUESTED, ASSIGNED 가 한건이라도 있으면 설비 직접입력 시작 불가.
      for (const quotation of result) {
        if (quotation.status === 'REQUESTED' || quotation.status === 'ASSIGNED' || quotation.status === 'DOING') {
          setCanRegisterInfra(false);
        }
      }
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    });
  }, [buildingId, canRegisterInfra]);

  return (
    <div>
      <Header></Header>
      <div className={'grid grid-cols-12 content'}>
        <div className={'col-span-2'}></div>
        <div className={'col-span-8'}>
          <div className="pb-20">
            <h1 className="pt-10 my-12 font-semibold text-3xl">건물 상세</h1>
            <div>
              <div className="text-lg text-start font-semibold pt-10 pb-3">
                건물정보
              </div>

              <div className="grid grid-cols-2 grid-rows-2 gap-x-10 gap-y-3">
                <div className="flex w-full gap-x-5">
                  <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                    건물명
                  </div>
                  <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                    {buildingInfo?.buildingName}
                  </div>
                </div>

                <div>
                  <div className="flex w-full gap-x-5">
                    <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                      주소
                    </div>
                    <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                      {buildingInfo?.address}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="flex w-full gap-x-5">
                    <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                      총 연면적 (m²)
                    </div>
                    <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                      {buildingInfo?.totalArea}
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className={'mt-6'}>
              <Accordion>
                <AccordionTab header={'표제부 목록 보기'}>
                  <DataTable
                    value={buildingDongList}
                    dataKey="seq"
                  >
                    <Column field="seq" header="번호"></Column>
                    <Column field="buildingName" header="건물명"></Column>
                    <Column field="totalArea" header="연면적"></Column>
                    <Column field="dongName" header="동이름"></Column>
                    <Column field="mainPurposeName" header="주용도"></Column>
                    <Column field="useApproveDay" header="사용승인일"></Column>
                  </DataTable>
                </AccordionTab>
              </Accordion>
            </div>

            {
              canRegisterInfra
                ? <div className={'my-8'}>
                  <button
                    onClick={startNewQuotation}
                    className="bg-white border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white whitespace-nowrap w-1/3 px-3 py-3 disabled:opacity-50 hover:opacity-90 duration-300 text-base font-semibold rounded-xl">
                    설비 직접입력 시작하기
                  </button>
                </div>
                : <></>
            }


            {quotationVersion?.status === "CLOSED" && (
              <div className="pt-6">
                <div className="flex justify-between items-center">
                  <div className="text-lg text-start font-semibold pt-10 pb-3">
                    견적 기준 표준 금액
                  </div>

                  <div className="flex items-start gap-x-1">
                    <img
                      src={warning_badge}
                      alt="warning_badge"
                      className="mt-0.5"
                    />
                    <div>
                      <div className="text-start text-[#FF4242] text-sm font-normal">
                        견적사가 설비 등록을 완료한 후 금액이 표시됩니다.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full bg-white p-6 mb-3">
                  <div className="flex justify-between items-center pb-3">
                    <div className="text-xl font-normal">유지보수</div>
                    <div className="text-2xl font-semibold">
                      3,560,000 <span className="text-xl font-normal">원</span>
                    </div>
                  </div>

                  <div className="flex justify-between items-center">
                    <div className="text-xl font-normal">성능점검</div>
                    <div className="text-2xl font-semibold">
                      11,600,000 <span className="text-xl font-normal">원</span>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center w-full bg-[#3F80EA] rounded-lg px-6 py-4 text-white">
                  <div className="text-xl font-bold">견적 기준 표준 금액</div>
                  <div className="text-2xl font-semibold">
                    <span className="text-xl font-normal pr-2">총</span>
                    15,160,000
                    <span className="text-xl font-normal pl-2">원</span>
                  </div>
                </div>
              </div>
            )}

            <div>
              <div className={'flex justify-between'}>
                <div className="text-lg text-start font-semibold pt-10 pb-3">
                  설비 현황 수량 요약
                </div>
                <div className="mt-8 mb-2">
                  <span className={'mx-4'}>설비등록 버전</span>
                  <Dropdown value={quotationVersion}
                            onChange={(e) => {
                              setQuotationVersion(e.value);
                              selectQuotationVersion(e.value.quotationSeq);
                            }}
                            options={options}
                            optionLabel="createDate"
                            placeholder="설비등록 버전을 선택해주세요."
                            className=""/>
                </div>
              </div>

              <div className="grid grid-cols-4 gap-x-6 gap-y-3 bg-[#F5F5F5] p-6 rounded-md">
                {infraQuantitySummary.map((item, index) => {
                  return (
                    <div
                      className="w-full bg-white border rounded-lg p-6"
                      key={item.infraTypeSeq}
                    >
                      <div className="text-lg font-semibold">
                        {item.infraTypeName}
                      </div>
                      <div className="text-xl font-medium mt-2">
                        {item.quantity}
                        <span className="text-sm font-light"> 개</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div>
              <div className={''}>
                <div className="text-lg text-start font-semibold mt-10 mb-3">
                  정보통신설비 현황
                </div>
              </div>

              <div className="flex items-end w-full bg-[#F5F5F5] p-6 rounded-md gap-3">
                <div className="w-1/4">
                  <label htmlFor="infraTypeName" className="flex text-start">
                    설비 유형
                  </label>
                  <MultiSelect
                    value={selectedInfraType}
                    onChange={(e: DropdownChangeEvent) =>
                      setSelectedInfraType(e.value)
                    }
                    options={infraTypeNameOptions}
                    optionLabel="name"
                    placeholder="선택"
                    id="infraTypeName"
                    className="w-full rounded-xl py-1 mt-2"
                  />
                </div>

                <div className="w-full">
                  <label htmlFor="searchInfra" className="flex text-start">
                    키워드 검색
                  </label>
                  <InputText
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyUp={(e) => {
                      e.key === "Enter" && searchInfra();
                    }}
                    placeholder="키워드를 검색해 주세요."
                    id="searchInfra"
                    className="w-full rounded-xl py-4 mt-2"
                  />
                </div>

                <button
                  className="border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-xl px-8 py-4"
                  onClick={() => searchInfra()}
                >
                  검색
                </button>
              </div>

              {quotationVersion.status === "DOING" && (
                <div className="w-full flex justify-start items-center gap-3 my-6">
                  <button
                    className="border-2 border-[#3F80EA] bg-white rounded-xl px-8 py-4"
                    onClick={() => deleteSelectedInfra()}
                  >
                    <img src={delete_icon_blue} alt="delete_icon_blue"/>
                  </button>

                  <button
                    className="w-1/4 min-w-60 bg-[#3F80EA] rounded-lg p-4 text-white truncate"
                    onClick={() => navigate("/addNewIACFacility/" + buildingId + "/" + quotationVersion.quotationSeq)}
                  >
                    정보통신설비 추가하기
                  </button>
                </div>
              )}
            </div>

            <div>
              {/*
          // @ts-ignore*/}
              <DataTable
                value={infraList}
                dataKey="seq"
                removableSort
                selectionMode={'checkbox'}
                selection={selectedInfra!}
                onSelectionChange={(e: any) => {
                  setSelectedInfra(e.value)
                }}
                emptyMessage="유지보수·관리 및 성능점검 대상 건물이 없습니다."
                tableStyle={{minWidth: "50rem"}}
                rowClassName={(data, options) => "font-semibold border text-sm"}
                scrollable
                tableClassName="border-separate"
              >
                <Column
                  selectionMode="multiple"
                  headerStyle={{width: "3rem"}}
                ></Column>
                <Column
                  field="infraTypeName"
                  header="설비유형"
                  sortable
                ></Column>
                <Column
                  field="name"
                  header="설비명"
                  body={infraNameBody}
                  sortable
                ></Column>
                <Column field="modelName" header="규격(모델명)" sortable></Column>
                <Column field="quantity" header="수량" sortable></Column>
                <Column field="manufacturer" header="제조사" sortable></Column>
                <Column field="location" header="설치위치" sortable></Column>
                <Column field="installYear" header="설치연도" sortable></Column>
                <Column
                  field="description"
                  header="비고"
                  sortable
                  headerClassName=""
                  className=""
                ></Column>
              </DataTable>
            </div>

            {quotationVersion.status === "DOING" && (
              <div className="flex w-full justify-center items-center gap-6 mt-10">
                <button
                  className="bg-white border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white whitespace-nowrap w-1/3 px-3 py-3 disabled:opacity-50 hover:opacity-90 duration-300 text-base font-semibold rounded-xl"
                  onClick={() => {
                    setSubmitModalvisible(true);
                  }}
                >
                  정보통신설비 입력완료
                </button>
              </div>
            )}
          </div>
        </div>
        <div className={'col-span-2'}></div>
      </div>

      <Dialog
        header=""
        visible={submitModalvisible}
        closable={false}
        style={{width: "50vw"}}
        onHide={() => {
          if (!submitModalvisible) return;
          setSubmitModalvisible(false);
        }}
      >
        <div className="flex flex-col justify-center items-center gap-6">
          <p className="text-2xl font-normal">
            정보통신설비 입력을 완료하시겠습니까?
          </p>

          <div className="text-center">
            <p className="text-[#FF4242] text-base font-normal">
              * 설비 입력이 완료되면 입찰이 가능합니다.
            </p>
            <p className="text-[#FF4242] text-base font-normal">
              * 입찰이 시작되면 등록된 설비 내역을 추가, 삭제 할 수 없습니다.
            </p>
          </div>

          <div className="flex justify-center items-center w-full space-x-2 mt-6">
            <button
              className="w-1/4 px-3 py-2 text-[#519CE1] disabled:bg-[#DADADA] hover:bg-[#ebebeb] duration-300 text-base font-extrabold border border-[#519CE1] rounded-xl"
              onClick={() => {
                setSubmitModalvisible(false);
              }}
            >
              취소
            </button>
            <button
              className="w-1/4 px-3 py-2 bg-[#519CE1] disabled:bg-[#DADADA] hover:bg-[#4a8dcc] duration-300 text-base font-extrabold text-white rounded-xl"
              onClick={() => {
                changeQuotationStatus();
              }}
            >
              저장
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header=""
        visible={confirmModalvisible}
        closable={false}
        style={{width: "50vw"}}
        onHide={() => {
          if (!confirmModalvisible) return;
          setconfirmModalvisible(false);
        }}
      >
        <div className="flex flex-col justify-center items-center gap-6">
          <p className="text-2xl font-normal">입력이 완료되었습니다.</p>

          <div className="flex justify-center items-center w-full space-x-2 mt-6">
            <button
              className="w-1/4 px-3 py-2 bg-[#519CE1] disabled:bg-[#DADADA] hover:bg-[#4a8dcc] duration-300 text-base font-extrabold text-white rounded-xl"
              onClick={() => {
                setconfirmModalvisible(false);
              }}
            >
              확인
            </button>
          </div>
        </div>
      </Dialog>

      <Footer></Footer>
    </div>
  );
}

export default MyBuildingIAC;
