import React from "react";

function CommonPrivatePolicy() {
  return (
    <div className="content spacing">
      <div className="text-xl">개인정보 처리방침</div>
      <div>내용 업데이트가 필요합니다.</div>
    </div>
  );
}

export default CommonPrivatePolicy;
