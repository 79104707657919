import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CommonPrivacyPolicy from "../components/CommonPrivacyPolicy";

function PrivatePolicy() {

  return (
    <div>
      <Header></Header>
        <CommonPrivacyPolicy/>
      <Footer></Footer>
    </div>
  );
}

export default PrivatePolicy;
