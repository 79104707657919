import React, { useEffect, useRef, useState } from "react";
import { getUser } from "../../services/authService";
import { NiceCommonForm } from "../NiceCommonForm";
import { Link, useNavigate } from "react-router-dom";

function MyInfoPerson(props: any) {
  const niceCommonFormRef = useRef(null);
  const [user, setUser] = useState({
    userId: props.userInfo.userId,
    shippingAddress: "",
    email: props.userInfo.email,
    accessToken: props.userInfo.accessToken,
    name: props.userInfo.fullName,
    phone: props.userInfo.phone,
  });

  // 본인인증 성공 시 fullName 셋팅
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");

  const navigate = useNavigate();


  const changeModifyMode = () => {
    // 본인인증 프로세스
    // @ts-ignore
    niceCommonFormRef.current.niceFormSubmit();
  };

  // fullName 변경 (나이스 본인인증 완료) 시 실행
  useEffect(() => {
    if (fullName !== "") {
      props.setFullName(fullName);
      props.setPhone(phone);

      // 수정 모드로 변경
      props.setIsModifyMode(true);
    }
  }, [fullName]);

  useEffect(() => {
    getUser(props.userInfo.userId).then((data) => {
      // console.log(data);
      setUser({
        userId: props.userInfo.userId,
        shippingAddress: data.shippingAddress,
        email: props.userInfo.email,
        accessToken: props.userInfo.accessToken,
        name: props.userInfo.fullName,
        phone: props.userInfo.phone,
      });
    });
  }, []);

  return (
    <>
      <section className="">
        <div className={'grid grid-cols-1 lg:grid-cols-3 mt-4'}>
          <div className={'h-96 mx-4 mb-4 border rounded-xl shadow-lg p-4'}>
            <ul>
              <li>본인 인증을 통해 이메일 수정 가능</li>
              <li>본인 인증된 정보로 이름과 전화번호가 자동으로 갱신되어 저장시 반영됨</li>
              <li>비밀번호를 변경할 수 있는 버튼 생김</li>
            </ul>
          </div>
          <div className={'grid grid-cols-subgrid mx-4 xl:mx-0'}>
            <div className="mb-3 p-4 border rounded-xl shadow-lg">
              <h2 className="mb-2 font-semibold">가입정보</h2>
              <hr/>
              <div className="my-3">
                <label htmlFor="userId" className="block mb-1 font-semibold">
                  아이디
                </label>
                <input
                  type="text"
                  value={user.userId}
                  disabled
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                />
              </div>
              <div className="my-3">
                <label htmlFor="email" className="block mb-1 font-semibold">
                  이메일
                </label>
                <input
                  type="text"
                  value={user.email}
                  disabled
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                />
              </div>
            </div>

            <div className="mb-3 p-4 border rounded-xl shadow-lg">
              <div className="flex justify-between mb-3">
                <h2 className="font-semibold">본인인증</h2>
              </div>
              <hr/>
              <div className="my-3">
                <label htmlFor="name" className="block mb-1 font-semibold">
                  이름
                </label>
                <input
                  id="name"
                  type="text"
                  value={user.name}
                  disabled
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                />
              </div>

              <div className="mb-5">
                <label htmlFor="phone" className="block mb-1 font-semibold">
                  전화번호
                </label>
                <input
                  id="phone"
                  type="tel"
                  value={user.phone}
                  disabled
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                />
              </div>
            </div>

            <div className="my-5">
              <button
                className="hover:opacity-90 transition duration-300 ease-in-out bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white font-bold py-3 px-4 rounded-xl w-full"
                onClick={changeModifyMode}
              >
                본인인증으로 정보 수정하기
              </button>
            </div>

            <div>
              <Link
                to="/myInfoCompany"
                className="text-gray-500/70 underline text-center text-lg underline-offset-2 hover:text-gray-500/90 translate-x-0.5 hover:translate-x-0 transition duration-300 ease-in-out"
              >
                회원탈퇴
              </Link>
            </div>
          </div>
        </div>
      </section>
      <NiceCommonForm ref={niceCommonFormRef} setFullName={setFullName} setPhone={setPhone}/>
    </>
  );
}

export default MyInfoPerson;
