import {createContext, useContext, useEffect, useState} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Authorization from "./components/Authorization";
import Asking from "./pages/Asking";
import AddNewIACFacility from "./pages/building/AddNewIACFacility";
import BuildingDetail from "./pages/building/BuildingDetail";
import MyBuilding from "./pages/building/MyBuilding";
import MyBuildingList from "./pages/building/MyBuildingList";
import AssignedBuildingDetail from "./pages/building/quotation/AssignedBuildingDetail";
import AssignedBuildingList from "./pages/building/quotation/AssignedBuildingList";
import HealthCheck from "./pages/HealthCheck";
import IACServiceBidDetail from "./pages/IACServiceBid/IACServiceBidDetail";
import IACServiceBidList from "./pages/IACServiceBid/IACServiceBidList";
import IACServiceBidRegistration from "./pages/IACServiceBid/IACServiceBidRegistration";
import IACServiceBidSubmitDetail from "./pages/IACServiceBid/IACServiceBidSubmitDetail";
import Main from "./pages/Main";
import MyInfo from "./pages/MyInfo";
import NotFound from "./pages/NotFound";
import PrivatePolicy from "./pages/PrivatePolicy";
import TermsOfUse from "./pages/TermsOfUse";
import ChangePassword from "./pages/user/ChangePassword";
import EmailVerification from "./pages/user/EmailVerification";
import FindId from "./pages/user/FindId";
import FindPassword from "./pages/user/FindPassword";
import NiceSuccess from "./pages/user/NiceSuccess";
import UserRegistrationComplete from "./pages/user/UserRegistrationComplete";
import UserSignIn from "./pages/user/UserSignIn";
import { checkAccessToken } from "./services/authService";
import InfraDetail from "./pages/building/InfraDetail";
import QuotationRequest from "./pages/building/QuotationRequest";
import ScrollToTop from "./components/ScrollToTop";
import {LoadingProvider} from "./components/LoadingContext";
import UserRegistration from "./pages/user/UserRegistration";
import AdditionalRegistration from "./components/UserRegistration/AdditionalRegistration";
import {getCookie, removeCookie, setCookie} from "./services/utils/cookieUtil";
import QuotationDetail from "./pages/building/QuotationDetail";
import AssignedQuotationDetail from "./pages/building/quotation/AssignedQuotationDetail";

export const UserInfoContext = createContext({});

function App() {
  let [userInfo, setUserInfo] = useState(null);
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");

  const [checkAccessTokenCompleted, setCheckAccessTokenCompleted] =
    useState(false);

  const login = (user: any) => {
    setUserInfo(user);
    // 쿠키에 token 정보를 쓴다.
    setCookie("accessToken", user.accessToken);
    setCookie("refreshToken", user.refreshToken);
    const now = new Date();
    setCookie("expiry", (now.getTime() + (10 * 60 * 1000)).toString()); // 10분
    setCookie("userId", user.userId);
  };
  const logout = () => {
    setUserInfo(null);
    removeCookie("accessToken");
    removeCookie("expiry");
    removeCookie("refreshToken");
    removeCookie("userId");

    // 로그아웃 시 로그인 페이지로 강제이동
    window.location.href = "/";
  };

  const authCheck = () => {
    const token = getCookie('accessToken');
    const refreshToken = getCookie('refreshToken');
    checkAccessToken(token)
      .then((data: any) => {
        //@ts-ignore
        userInfo = {
          userId: data.userId,
          email: data.email,
          fullName: data.fullName,
          phone: data.phone,
          accessToken: data.accessToken,
          refreshToken: refreshToken,
          companyYn: data.companyYn,
          businessUser: data.businessUser,
          userRoles: data.roles,
        };
        setUserInfo(userInfo);
        login(userInfo);
        setUserId(data.userId);
        setEmail(data.email);
        setPhone(data.phone);
        setFullName(data.fullName);
      })
      .finally(() => {
        // API 조회 후 상태를 변경해준다.
        setCheckAccessTokenCompleted(true);
      });
  };

  useEffect(() => {
    authCheck();
  }, []);

  return (
    <div className="App">
      {checkAccessTokenCompleted ? (
        <UserInfoContext.Provider value={{ userInfo, login, logout }}>
          <LoadingProvider>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path={"/privatePolicy"} element={<PrivatePolicy />} />
              <Route path={"/termsOfUse"} element={<TermsOfUse />} />
              <Route
                path={"/assignedBuildingList"}
                element={
                  <Authorization userInfo={userInfo} redirectTo={"/userSignIn"} pathname={"/assignedBuildingList"}>
                    <AssignedBuildingList />
                  </Authorization>
                }
              />
              <Route
                path={"/assignedBuildingDetail/:buildingId"}
                element={
                  <Authorization userInfo={userInfo} redirectTo={"/userSignIn"} pathname={"/assignedBuildingDetail/:buildingId"}>
                    <AssignedBuildingDetail />
                  </Authorization>
                }
              />
              <Route
                path={"/IACServiceBidList"}
                element={
                  <Authorization userInfo={userInfo} redirectTo={"/userSignIn"} pathname={"/IACServiceBidList"}>
                    <IACServiceBidList />
                  </Authorization>
                }
              />
              <Route
                path={"/IACServiceBidSelectBuildings"}
                element={
                  <Authorization userInfo={userInfo} redirectTo={"/userSignIn"} pathname={"/IACServiceBidSelectBuildings"}>
                    <IACServiceBidRegistration />
                  </Authorization>
                }
              />
              <Route
                path={"/IACServiceBidRegistration"}
                element={
                  <Authorization userInfo={userInfo} redirectTo={"/userSignIn"} pathname={"/IACServiceBidRegistration"}>
                    <IACServiceBidSubmitDetail />
                  </Authorization>
                }
              />
              <Route
                path={"/IACServiceBidDetail/:serviceBidId"}
                element={
                  <Authorization userInfo={userInfo} redirectTo={"/userSignIn"} pathname={"/IACServiceBidDetail/:serviceBidId"}>
                    <IACServiceBidDetail />
                  </Authorization>
                }
              />
              <Route
                path={"/myBuildingList"}
                element={
                  <Authorization userInfo={userInfo} redirectTo={"/userSignIn"} pathname={"/myBuildingList"}>
                    <MyBuildingList />
                  </Authorization>
                }
              />
              <Route
                path={"/myBuilding"}
                element={
                  <Authorization userInfo={userInfo} redirectTo={"/userSignIn"} pathname={"/myBuilding"}>
                    <MyBuilding />
                  </Authorization>
                }
              />
              <Route
                path={"/buildingDetail/:buildingId"}
                element={
                  <Authorization userInfo={userInfo} redirectTo={"/userSignIn"} pathname={"/buildingDetail/:buildingId"}>
                    <BuildingDetail />
                  </Authorization>
                }
              />
              <Route
                path={"/quotationRequest/:buildingId"}
                element={
                  <Authorization userInfo={userInfo} redirectTo={"/userSignIn"} pathname={"/quotationRequest/:buildingId"}>
                    <QuotationRequest />
                  </Authorization>
                }
              />
              <Route
                path={"/quotationDetail/:quotationSeq"}
                element={
                  <Authorization userInfo={userInfo} redirectTo={"/userSignIn"} pathname={"/quotationDetail/:quotationSeq"}>
                    <QuotationDetail />
                  </Authorization>
                }
              />
              <Route
                path={"/assignedQuotationDetail/:quotationSeq"}
                element={
                  <Authorization userInfo={userInfo} redirectTo={"/userSignIn"} pathname={"/assignedQuotationDetail/:quotationSeq"}>
                    <AssignedQuotationDetail />
                  </Authorization>
                }
              />
              <Route
                path={"/infraDetail/:buildingId/:infraId"}
                element={
                  <Authorization userInfo={userInfo} redirectTo={"/userSignIn"} pathname={"/infraDetail/:buildingId/:infraId"}>
                    <InfraDetail />
                  </Authorization>
                }
              />
              <Route
                path={"/assignedInfraDetail/:buildingId/:infraId"}
                element={
                  <Authorization userInfo={userInfo} redirectTo={"/userSignIn"} pathname={"/assignedInfraDetail/:buildingId/:infraId"}>
                    <InfraDetail />
                  </Authorization>
                }
              />
              <Route
                path={"/addNewIACFacility/:buildingId/:quotationSeq"}
                element={<AddNewIACFacility />}
              />
              <Route
                path={"/addNewAssignedIACFacility/:buildingId/:quotationSeq"}
                element={<AddNewIACFacility />}
              />
              <Route path="*" element={<NotFound />}></Route>
              <Route path={"/health"} element={<HealthCheck />} />
              <Route
                path={"/myInfo"}
                element={
                  <Authorization userInfo={userInfo} redirectTo={"/userSignIn"} pathname={"/myInfo"}>
                    <MyInfo />
                  </Authorization>
                }
              />
              <Route
                path={"/asking"}
                element={
                  <Authorization userInfo={userInfo} redirectTo={"/userSignIn"} pathname={"/asking"}>
                    <Asking />
                  </Authorization>
                }
              />

              {/* User 관련 페이지 */}
              <Route
                path={"/userRegistration"}
                element={<UserRegistration />}
              />
              <Route
                path={"/additionalRegistration"}
                element={<AdditionalRegistration />}
              />
              <Route path={"/changePassword"} element={<ChangePassword />} />
              <Route
                path={"/emailVerification"}
                element={<EmailVerification />}
              />
              <Route path={"/userSignIn"} element={<UserSignIn />} />
              <Route
                path={"/userRegistrationComplete"}
                element={<UserRegistrationComplete />}
              />
              <Route path={"/findPassword"} element={<FindPassword />} />
              <Route path={"/changePassword"} element={<ChangePassword />} />
              <Route path={"/findId"} element={<FindId />} />
              <Route path={"/niceSuccess"} element={<NiceSuccess />} />
            </Routes>
          </BrowserRouter>
          </LoadingProvider>
        </UserInfoContext.Provider>
      ) : (
        <></>
      )}
    </div>
  );
}

export default App;
