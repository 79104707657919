import React, { useEffect, useRef, useState } from "react";
import {getUser, isEmailExists} from "../../services/authService";
import {updateUser} from "../../services/authoriedUserService";
import { validateEmailFormat } from "../../services/utils/formatUtil";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import AlertDialog from "../AlertDialog";
import {useLoading} from "../LoadingContext";

function MyInfoPersonModify(props: any) {
  const {setLoading} = useLoading();
  const [user, setUser] = useState({
    userId: props.props.userId,
    shippingAddress: "",
    email: props.props.email,
    name: props.fullName,
    phone: props.phone,
  });
  const [visible3, setVisible3] = useState(false);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [myEmail, setMyEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const emailCheckText = useRef(null);

  const validateUser = () => {
    if (user.email === null || user.email === "") {
      alert("이메일이 비어있습니다.");
      return false;
    } else if (!validateEmailFormat(user.email)) {
      alert("이메일 형식이 잘못되었습니다.");
      return false;
    }
    return true;
  };

  const saveMyInfo = () => {
    const param = {
      userId: props.props.userId,
      email: email,
      name: name,
      phone: phone,
    };

    if (window.confirm("저장하시겠습니까?")) {
      if (validateUser()) {
        updateUser(param).then((response) => {
          if (response.status === '200') {
            setVisible3(true);
          } else {
            showAlert("오류가 발생했습니다.");
          }
        });
      }
    }
  };

  const handleChangeEmail = (e: any) => {
    if (e.target.value.length > 254) {
      showAlert('이메일은 254자까지로 제한되어 있습니다.');
      return;
    }
    // @ts-ignore
    emailCheckText.current.innerText = null;
    setEmail(e.target.value);

    if (e.target.value === myEmail) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };
  const isEmailAddressExists = () => {
    if (!email) {
      showAlert('이메일이 없습니다.');
      return;
    }
    if (email.length > 254) {
      showAlert('이메일은 254자까지로 제한되어 있습니다.');
      return;
    }
    if (!validateEmailFormat(email)) {
      showAlert('이메일 형식이 맞지 않습니다.');
      return;
    }

    setLoading(true);
    // Email 중복체크
    isEmailExists(email).then((existYn) => {
      if (existYn) {
        // 중복된 ID 가 존재
        // @ts-ignore
        emailCheckText.current.className = "text-red-500 text-sm";
        // @ts-ignore
        emailCheckText.current.innerText = "이미 사용중인 이메일 입니다.";
      } else {
        if (emailCheckText.current) {
          setIsEmailValid(true);
          // @ts-ignore
          emailCheckText.current.className = "text-green-500 text-sm";
          // @ts-ignore
          emailCheckText.current.innerText = "사용 가능한 이메일 입니다.";
        }
      }
    }).finally(()=>{
      setLoading(false);
    });
  };

  useEffect(() => {
    getUser(props.props.userId).then((data) => {
      setUser({
        userId: props.props.userId,
        shippingAddress: data.shippingAddress,
        email: props.props.email,
        name: props.fullName,
        phone: props.phone,
      });

      setEmail(props.props.email);
      setMyEmail(props.props.email);
      setName(props.fullName);
      setPhone(props.phone);
    });
  }, []);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const showAlert = (message:string) => {
    setAlertMessage(message);
    setAlertVisible(true);
  };
  const hideAlert = () => {
    setAlertVisible(false);
  };

  return (
    <>
      <section className="">
        <div className={'grid grid-cols-1 lg:grid-cols-3 mt-4'}>
          <div className={'h-96 mx-4 mb-4 border rounded-xl shadow-lg p-4'}>
            <ul>
              <li>본인 인증을 통해 이메일 수정 가능</li>
              <li>본인 인증된 정보로 이름과 전화번호가 자동으로 갱신되어 저장시 반영됨</li>
              <li>비밀번호를 변경할 수 있는 버튼 생김</li>
            </ul>
          </div>
          <div className={'grid grid-cols-subgrid mx-4 xl:mx-0'}>
            <div className="mb-3 p-4 border rounded-xl shadow-lg">
              <h2 className="mb-2 font-semibold">가입정보</h2>
              <hr/>
              <div className="my-3">
                <label htmlFor="userId" className="block mb-1 font-semibold">
                  아이디
                </label>
                <input
                  type="text"
                  value={user.userId}
                  disabled
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                />
              </div>
              <div className="my-3">
                <label htmlFor="email" className="block mb-1 font-semibold">
                  비밀번호
                </label>
                <button
                  className="w-full hover:opacity-90 transition duration-300 ease-in-out bg-white text-primary py-2.5 rounded-xl border border-primary"
                  onClick={() => {
                    navigate('/changePassword')
                  }}
                >
                  비밀번호 변경
                </button>
              </div>
              <div className="my-3">
                <label htmlFor="email" className="block mb-1 font-semibold">
                  이메일<span className="text-red-500">*</span>
                </label>
                <div className="grid grid-cols-3 gap-3">
                  <div className={'col-span-2'}>
                    <input
                      id="email"
                      type="email"
                      value={email}
                      onChange={handleChangeEmail}
                      placeholder="이메일을 입력해주세요."
                      className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                    />
                  </div>
                  <div className={''}>
                    <button
                      onClick={isEmailAddressExists}
                      disabled={email === myEmail}
                      className="w-full h-14 px-2 rounded-xl border border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white hover:border-transparent font-semibold disabled:bg-gray-500 disabled:from-gray-500 disabled:to-gray-500 disabled:border-none disabled:cursor-not-allowed disabled:text-white"
                    >
                      중복체크
                    </button>
                  </div>
                </div>
                <div ref={emailCheckText} className="hidden text-sm"></div>
              </div>
            </div>

            <div className="mb-3 p-4 border rounded-xl shadow-lg">
              <div className="flex justify-between mb-3">
                <h2 className="font-semibold">본인인증</h2>
              </div>
              <hr/>
              <div className="my-3">
                <label htmlFor="fullName" className="block mb-1 font-semibold">
                  이름
                </label>
                <input
                  id="fullName"
                  type="text"
                  value={props.fullName}
                  disabled
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                />
              </div>

              <div className="mb-5">
                <label htmlFor="phone" className="block mb-1 font-semibold">
                  전화번호
                </label>
                <input
                  id="phone"
                  type="tel"
                  value={props.phone}
                  disabled
                  className="w-full border rounded-xl py-4 px-2 focus:outline-[#3F80EA] focus:ring-1"
                />
              </div>
            </div>

            <div className="my-5">
              <div className="flex items-center gap-4 flex-row-reverse">
                <button
                  disabled={(!isEmailValid)}
                  className="w-full hover:opacity-90 transition duration-300 ease-in-out bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white py-2.5 rounded-xl disabled:bg-gray-500 disabled:from-gray-500 disabled:to-gray-500 disabled:border-none disabled:cursor-not-allowed "
                  onClick={saveMyInfo}
                >
                  저장하기
                </button>
                <button
                  className="w-full hover:opacity-90 transition duration-300 ease-in-out bg-white text-primary py-2.5 rounded-xl border border-primary"
                  onClick={() => props.setIsModifyMode(false)}
                >
                  돌아가기
                </button>
              </div>
            </div>

            <div>
              <Link
                to="/myInfoCompany"
                className="text-gray-500/70 underline text-center text-lg underline-offset-2 hover:text-gray-500/90 translate-x-0.5 hover:translate-x-0 transition duration-300 ease-in-out"
              >
                회원탈퇴
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* save button alert  */}
      <Dialog
        visible={visible3}
        modal
        onHide={() => {
          if (!visible3) return;
          setVisible3(false);
        }}
        content={({hide}) => (
          <div className="p-10 gap-4 bg-white rounded-md">
            <div className="space-y-8 p-4">
              <h3 className="text-2xl text-center font-semibold">
                수정이 완료되었습니다.
              </h3>

              <Button
                onClick={(e) => {
                  window.location.reload();
                  hide(e);
                }}
                className="w-full hover:opacity-90 transition duration-300 ease-in-out active:bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white py-2.5 rounded-xl"
                label="확인"
              />
            </div>
          </div>
        )}
      ></Dialog>

      <AlertDialog
        visible={alertVisible}
        onHide={hideAlert}
        message={alertMessage}
      />

    </>
  );
}

export default MyInfoPersonModify;
