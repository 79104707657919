import React from "react";
import dots_active from "../../src/images/stepper/dots_active.png";
import dots_inactive from "../../src/images/stepper/dots_inactive.png";
import step1_icon_active from "../../src/images/stepper/step1_icon_active.png";
import step1_text_active from "../../src/images/stepper/step1_text_active.png";
import step2_icon_inactive from "../../src/images/stepper/step2_icon_inactive.png";
import step2_text_inactive from "../../src/images/stepper/step2_text_inactive.png";
import step2_icon_active from "../../src/images/stepper/step2_icon_active.png";
import step2_text_active from "../../src/images/stepper/step2_text_active.png";
import step3_icon_inactive from "../../src/images/stepper/step3_icon_inactive.png";
import step3_text_inactive from "../../src/images/stepper/step3_text_inactive.png";
import step4_icon_inactive from "../../src/images/stepper/step4_icon_inactive.png";
import step4_text_inactive from "../../src/images/stepper/step4_text_inactive.png";
import step5_icon_inactive from "../../src/images/stepper/step5_icon_inactive.png";
import step5_text_inactive from "../../src/images/stepper/step5_text_inactive.png";

interface StepperProps {
  step?: string;
}

const Stepper: React.FC<StepperProps> = ({ step }) => {
  return (
    <>
      <div className="flex justify-center items-center gap-10">
        {step === "step1" ? (
          <>
            <div className="flex flex-col gap-2.5 justify-center items-center">
              <img src={step1_icon_active} alt="step1_icon_active" />
              <img src={step1_text_active} alt="step1_text_active" />
            </div>
            <img src={dots_inactive} alt="dots_inactive" />
          </>
        ) : (
          <>
            <div className="flex flex-col gap-2.5 justify-center items-center">
              <img src={step1_icon_active} alt="step1_icon_active" />
              <img src={step1_text_active} alt="step1_text_active" />
            </div>
            <img src={dots_active} alt="dots_active" />
          </>
        )}
        {step === "step2" ? (
          <>
            <div className="flex flex-col gap-2.5 justify-center items-center">
              <img src={step2_icon_active} alt="step2_icon_active" />
              <img src={step2_text_active} alt="step2_text_active" />
            </div>
            <img src={dots_inactive} alt="dots_inactive" />
          </>
        ) : (
          <>
            <div className="flex flex-col gap-2.5 justify-center items-center">
              <img src={step2_icon_inactive} alt="step2_icon_inactive" />
              <img src={step2_text_inactive} alt="step2_text_inactive" />
            </div>
            {step === "step3" || step === "step4" || step === "step5" ? (
              <img src={dots_active} alt="dots_active" />
            ) : (
              <img src={dots_inactive} alt="dots_inactive" />
            )}
          </>
        )}
        {step === "step3" ? (
          <>
            <div className="flex flex-col gap-2.5 justify-center items-center">
              <img src={step3_icon_inactive} alt="step3_icon_inactive" />
              <img src={step3_text_inactive} alt="step3_text_inactive" />
            </div>
            <img src={dots_inactive} alt="dots_inactive" />
          </>
        ) : (
          <>
            <div className="flex flex-col gap-2.5 justify-center items-center">
              <img src={step3_icon_inactive} alt="step3_icon_inactive" />
              <img src={step3_text_inactive} alt="step3_text_inactive" />
            </div>
            {step === "step4" || step === "step5" ? (
              <img src={dots_active} alt="dots_active" />
            ) : (
              <img src={dots_inactive} alt="dots_inactive" />
            )}
          </>
        )}
        {step === "step4" ? (
          <>
            <div className="flex flex-col gap-2.5 justify-center items-center">
              <img src={step4_icon_inactive} alt="step4_icon_inactive" />
              <img src={step4_text_inactive} alt="step4_text_inactive" />
            </div>
            <img src={dots_inactive} alt="dots_inactive" />
          </>
        ) : (
          <>
            <div className="flex flex-col gap-2.5 justify-center items-center">
              <img src={step4_icon_inactive} alt="step4_icon_inactive" />
              <img src={step4_text_inactive} alt="step4_text_inactive" />
            </div>
            {step === "step5" ? (
              <img src={dots_active} alt="dots_active" />
            ) : (
              <img src={dots_inactive} alt="dots_inactive" />
            )}
          </>
        )}
        {step === "step5" ? (
          <>
            <div className="flex flex-col gap-2.5 justify-center items-center">
              <img src={step5_icon_inactive} alt="step5_icon_inactive" />
              <img src={step5_text_inactive} alt="step5_text_inactive" />
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col gap-2.5 justify-center items-center">
              <img src={step5_icon_inactive} alt="step5_icon_inactive" />
              <img src={step5_text_inactive} alt="step5_text_inactive" />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Stepper;
