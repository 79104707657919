// 원화 currency format
import moment from "moment";

export const KRWon = new Intl.NumberFormat('ko-KR', {
  style: 'currency',
  currency: 'KRW'
});

// dateTime to date (날짜만)
export function dateFormat(dateTime:any) {
  return moment(dateTime).format('YYYY-MM-DD');
}

// 연월6자리 사이에 대시(-) 추가
export function yearMonthFormat(yearMonth:string) {
  return moment(yearMonth).format('YYYY-MM');
}
// 연월일 8자리 사이에 대시(-) 추가
export function yearMonthDayFormat(yearMonthDay:string) {
  return moment(yearMonthDay).format('YYYY-MM-DD');
}
// 연월일 8자리로 업력(x개월) 계산하기
export function calculateBusinessMonths(yearMonthDay:string) {
  // 0개월이 나오지 않도록 항상 +1을 해준다. 일종의 올림 방식
  return moment().diff(moment(yearMonthDay), 'months') + 1;
  // return moment().diff(moment('2017-04-21'), 'months') + 1;
}
// a개월 을 받아서 x년 y개월 을 리턴
export function calculateBusinessYearMonth(a:number) {
  if (a/12 < 1) {
    return a + '개월';
  }
  const x = Math.floor(a/12); // 연
  const y = a % 12; // 개월
  return x + '년 ' + y + '개월';
}
// 사업자등록번호 형식 (xxx-xx-xxxxx) 10자리
export function formatBusinessRegistrationNumber(businessRegistrationNumber:string) {
  const str1 = businessRegistrationNumber.substring(0, 3);
  const str2 = businessRegistrationNumber.substring(3, 5);
  const str3 = businessRegistrationNumber.substring(5);
  return str1 + "-" + str2 + "-" + str3;
}

// 법인등록번호 형식 (xxxx-xx-xxxxxx-x) 13자리
export function formatCorporateRegistrationNumber(corporateRegistrationNumber:string) {
  const str1 = corporateRegistrationNumber.substring(0, 4);
  const str2 = corporateRegistrationNumber.substring(4, 6);
  const str3 = corporateRegistrationNumber.substring(6, 12);
  const str4 = corporateRegistrationNumber.substring(12);
  return str1 + " " + str2 + "-" + str3 + " " + str4;
}

// Address 서울시 성북구 앞 2단어만
export function summaryAddress(address:any) {
  const words = address.split(' ');
  return words[0] + ' ' + words[1];
}

export function validateEmailFormat(str:string) {
  // 이메일 포멧 정규식
  const emailRegEx =
      /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;
  return emailRegEx.test(str);
}

export function validateNumberFormat(str:string) {
  // 숫자 포멧 정규식
  const numRegEx =
      /^[0-9]*$/g;
  return numRegEx.test(str);
}

export function isNullOrEmpty(value: any): boolean {
  return (
      !value ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === 'object' && Object.keys(value).length === 0)
  );
}

export function transformBrText(multiLineText:string): string {
  return multiLineText.replaceAll('<br/>', '\n');
}
