import { Dialog } from "primereact/dialog";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserInfoContext } from "../../App";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {
  getSingleIACInfra,
  removeIACInfra,
} from "../../services/serviceBidService";
import { Image } from "primereact/image";

function InfraDetail() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // @ts-ignore
  let { userInfo, logout } = useContext(UserInfoContext);
  const { buildingId, infraId } = useParams();

  const [infraInfo, setInfraInfo] = useState({
    createDate: "",
    createUser: "",
    updateDate: "",
    updateUser: "",
    buildingId: 0,
    seq: 0,
    infraTypeSeq: 0,
    infraTypeName: "",
    name: "",
    modelName: "",
    quantity: null,
    manufacturer: "",
    location: "",
    installYear: "",
    description: "",
    fileName: "",
    fileUrl: "",
    fileSize: 0,
    files: null,
    infraFiles: null,
  });
  const [submitModalvisible, setSubmitModalvisible] = useState(false);
  const [confirmModalvisible, setconfirmModalvisible] = useState(false);

  console.log("infraInfo: ", infraInfo);

  const deleteInfra = () => {
    let payload = [
      {
        buildingId: infraInfo.buildingId,
        iacInfraSeq: infraInfo.seq,
        updateUser: userInfo.userId,
      },
    ];

    setLoading(true);
    removeIACInfra(payload).then((result) => {
      if (result.status === 200) {
        setSubmitModalvisible(false);
        setconfirmModalvisible(true);
      } else {
        alert("오류가 발생했습니다. 관리자에게 문의하세요.");
      }
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });
  };

  useEffect(() => {
    let payload = {
      buildingId: parseInt(buildingId as string),
      iacInfraSeq: parseInt(infraId as string),
      updateUser: userInfo.userId,
    };

    getSingleIACInfra(payload).then((result) => {
      setInfraInfo(result);
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });
  }, [buildingId, infraId, userInfo.userId]);

  return (
    <div>
      <Header></Header>
      <div className={'grid grid-cols-12 content'}>
        <div className={'col-span-2'}></div>
        <div className={'col-span-8'}>
          <div className="">
            <div>
              <div className="text-lg text-start font-semibold pt-10 pb-3">
                건물정보
              </div>

              <div className="grid grid-cols-6 grid-rows-3 gap-x-6 gap-y-3">
                <div className="col-span-3 flex w-full gap-x-5">
                  <div className="w-1/4 flex justify-center items-center bg-[#3F80EA] rounded-lg p-3 text-white">
                    infraTypeName
                  </div>

                  <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                    {infraInfo?.infraTypeName}
                  </div>
                </div>

                <div className="col-span-3 flex w-full gap-x-5">
                  <div className="w-1/4 flex justify-center items-center bg-[#3F80EA] rounded-lg p-3 text-white">
                    name
                  </div>

                  <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                    {infraInfo?.name}
                  </div>
                </div>

                <div className="col-span-3 flex w-full gap-x-5">
                  <div className="w-1/4 flex justify-center items-center bg-[#3F80EA] rounded-lg p-3 text-white">
                    modelName
                  </div>

                  <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                    {infraInfo?.modelName}
                  </div>
                </div>

                <div className="col-span-3 flex w-full gap-x-5">
                  <div className="w-1/4 flex justify-center items-center bg-[#3F80EA] rounded-lg p-3 text-white">
                    quantity
                  </div>

                  <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                    {infraInfo?.quantity}
                  </div>
                </div>

                <div className="col-span-2 flex w-full gap-x-5">
                  <div className="w-5/12 flex justify-center items-center bg-[#3F80EA] rounded-lg p-3 text-white">
                    manufacturer
                  </div>

                  <div className="w-7/12 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                    {infraInfo?.manufacturer}
                  </div>
                </div>

                <div className="col-span-2 flex w-full gap-x-5">
                  <div className="w-5/12 flex justify-center items-center bg-[#3F80EA] rounded-lg p-3 text-white">
                    location
                  </div>

                  <div className="w-7/12 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                    {infraInfo?.location}
                  </div>
                </div>

                <div className="col-span-2 flex w-full gap-x-5">
                  <div className="w-5/12 flex justify-center items-center bg-[#3F80EA] rounded-lg p-3 text-white">
                    installYear
                  </div>

                  <div className="w-7/12 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                    {infraInfo?.installYear}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-6">
            <div>
              <div className="text-lg text-start font-semibold pt-10 pb-3">
                description
              </div>

              <div className="w-full h-60 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                {infraInfo?.description}
              </div>
            </div>
          </div>
          <div className="pt-6">
            <div>
              <div className="text-lg text-start font-semibold pt-10 pb-3">
                iacInfraFiles
              </div>

              <div className="grid grid-cols-8 gap-x-6 gap-y-3">
                {
                  // @ts-ignore
                  infraInfo?.infraFiles?.map((file, index) => {
                    return (
                      <div key={index} className="flex w-full rounded-lg">
                        <Image
                          src={file.fileUrl}
                          alt={file.fileName}
                          width="250"
                          preview
                        />
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
          <div className="flex w-full justify-center items-center gap-6 py-20">
            <button
              className="bg-white border-2 border-[#3F80EA] text-[#3F80EA] w-1/3 px-3 py-3 disabled:opacity-50 hover:opacity-90 duration-300 text-base font-semibold rounded-xl"
              onClick={() => {
                if (window.location.pathname.includes("assignedInfraDetail")) {
                  navigate(-1);
                } else {
                  navigate("/buildingDetail/" + buildingId);
                }
              }}
            >
              뒤로가기
            </button>

            <button
              className="bg-white border-2 border-[#3F80EA] text-[#3F80EA] w-1/3 px-3 py-3 disabled:opacity-50 hover:opacity-90 duration-300 text-base font-semibold rounded-xl"
              onClick={() => {
                setSubmitModalvisible(true);
              }}
            >
              삭제하기
            </button>
          </div>
        </div>
        <div className={'col-span-2'}></div>
      </div>

      <Dialog
        header=""
        visible={submitModalvisible}
        closable={false}
        style={{width: "50vw"}}
        onHide={() => {
          if (!submitModalvisible) return;
          setSubmitModalvisible(false);
        }}
      >
        <div className="flex flex-col justify-center items-center gap-6">
          <p className="text-2xl font-normal">설비를 삭제하시겠습니까?</p>

          <div className="text-center">
            <p className="text-[#FF4242] text-base font-normal">
              * 삭제 후에는 데이터 복구가 불가합니다.
            </p>
          </div>

          <div className="flex justify-center items-center w-full space-x-2 mt-3">
            <button
              className="w-1/4 px-3 py-2 text-[#519CE1] disabled:bg-[#DADADA] hover:bg-[#ebebeb] duration-300 text-base font-extrabold border border-[#519CE1] rounded-xl"
              onClick={() => {
                setSubmitModalvisible(false);
              }}
            >
              취소
            </button>
            <button
              className="w-1/4 px-3 py-2 bg-[#519CE1] disabled:bg-[#DADADA] hover:bg-[#4a8dcc] duration-300 text-base font-extrabold text-white rounded-xl"
              onClick={() => {
                deleteInfra();
              }}
            >
              삭제
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header=""
        visible={confirmModalvisible}
        closable={false}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!confirmModalvisible) return;
          setconfirmModalvisible(false);
        }}
      >
        <div className="flex flex-col justify-center items-center gap-6">
          <p className="text-2xl font-normal">삭제가 완료되었습니다.</p>

          <div className="flex justify-center items-center w-full space-x-2 mt-6">
            <button
              className="w-1/4 px-3 py-2 bg-[#519CE1] disabled:bg-[#DADADA] hover:bg-[#4a8dcc] duration-300 text-base font-extrabold text-white rounded-xl"
              onClick={() => {
                setconfirmModalvisible(false);
                if (window.location.pathname.includes("assignedInfraDetail")) {
                  navigate("/assignedBuildingDetail/" + buildingId);
                } else {
                  navigate("/buildingDetail/" + buildingId);
                }
              }}
            >
              확인
            </button>
          </div>
        </div>
      </Dialog>

      <Footer></Footer>
    </div>
  );
}

export default InfraDetail;
