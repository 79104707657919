import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

interface AlertDialogProps {
  visible: boolean;
  onHide: () => void;
  message: string;
}

const AlertDialog: React.FC<AlertDialogProps> = ({ visible, onHide, message }) => {
  return (
    <Dialog header={'경고'} visible={visible} style={{ width: '400px' }} modal onHide={onHide} closable={false}>
      <p>{message}</p>
      <div className="mt-6">
        <Button label="확인" icon="pi pi-check" onClick={onHide} className={'bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA]'}/>
      </div>
    </Dialog>
  );
};

export default AlertDialog;
