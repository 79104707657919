import React, {useContext, useEffect, useState} from "react";
import { UserInfoContext } from "../App";
import Footer from "../components/Footer";
import Header from "../components/Header";
import MyInfoCompany from "../components/MyInfo/MyInfoCompany";
import MyInfoPerson from "../components/MyInfo/MyInfoPerson";
import { TabView, TabPanel } from 'primereact/tabview';
import "../styles/MyInfo.css";
import MyInfoPersonModify from "../components/MyInfo/MyInfoPersonModify";
import MyInfoCompanyModify from "../components/MyInfo/MyInfoCompanyModify";
import MyInfoAdditionalRegistration from "../components/MyInfo/MyInfoAdditionalRegistration";

function MyInfo() {
  // @ts-ignore
  let { userInfo } = useContext(UserInfoContext);

  const [isCompanyModifyMode, setIsCompanyModifyMode] = useState(false);
  const [isModifyMode, setIsModifyMode] = useState(false);

  const onSetIsModifyMode = (param:boolean) => {
    setIsModifyMode(param);
  };
  const onSetIsCompanyModifyMode = (param:boolean) => {
    setIsCompanyModifyMode(param);
  };
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const onSetFullName = (fullName:string) => {
    setFullName(fullName);
  };
  const onSetPhone = (phone: string) => {
    setPhone(phone);
  };

  useEffect(() => {
    // console.log("MyInfo", userInfo);
    setIsModifyMode(false);
    setIsCompanyModifyMode(false);
  }, []);

  return (
    <>
      <Header />
      <div className={'grid grid-cols-12 content'}>
        <div className={'col-span-2'}></div>
        <div className={'col-span-8'}>
          <div className="text-left">
            <div className="mx-auto pt-10">
              <h1 className="my-12 App">내 정보 관리</h1>
              <TabView className={'m-4'}>
                <TabPanel header="가입정보 관리">
                  {
                    isModifyMode
                      ? <MyInfoPersonModify props={userInfo} setIsModifyMode={onSetIsModifyMode} fullName={fullName}
                                            phone={phone}></MyInfoPersonModify>
                      :
                      <MyInfoPerson userInfo={userInfo} setIsModifyMode={onSetIsModifyMode} setFullName={onSetFullName}
                                    setPhone={onSetPhone}></MyInfoPerson>
                  }
                </TabPanel>
                <TabPanel header="기업정보 관리">
                  {
                    isCompanyModifyMode
                      ? <MyInfoCompanyModify props={userInfo}
                                             setIsModifyMode={onSetIsCompanyModifyMode}></MyInfoCompanyModify>
                      : <MyInfoCompany props={userInfo} setIsModifyMode={onSetIsCompanyModifyMode}></MyInfoCompany>
                  }
                </TabPanel>
                <TabPanel header="추가 인증 정보 관리">
                  <MyInfoAdditionalRegistration userInfo={userInfo}/>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
        <div className={'col-span-2'}></div>
      </div>

      <Footer/>
    </>
  );
}

export default MyInfo;
