import { addDays } from "date-fns";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import {DataTable, DataTableExpandedRows, DataTableValueArray} from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Nullable } from "primereact/ts-helpers";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { UserInfoContext } from "../../App";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Stepper from "../../components/Stepper";
import calender_icon from "../../images/svg/calender_icon.svg";
import cross_icon from "../../images/svg/cross_icon.svg";
import warning_badge from "../../images/svg/warning_badge.svg";
import {
  getDocumentReqBase,
  getSummaryIACInfra,
  saveServiceBid,
} from "../../services/serviceBidService";
import {KRWon} from "../../services/utils/formatUtil";
import {Accordion, AccordionTab} from "primereact/accordion";
import {InputText} from "primereact/inputtext";
import {getBusinessUser} from "../../services/businessUserService";
import {downloadBUFile} from "../../services/authoriedUserService";

interface Building {
  buildingId: number;
  buildingName: string;
  address: string;
  totalArea: number;
  quotationStatus: string;
  quotationSeq: number;
  infraSummary: InfraQuantitySummary[];
}

interface InfraQuantitySummary {
  infraTypeSeq: number;
  infraTypeName: string;
  quantity: number;
  maintenancePrice: number;
  performancePrice: number;
}
interface StandardPriceTotal {
  maintenancePrice: number | bigint;
  performancePrice: number | bigint;
  wholePrice: number | bigint;
}
interface BusinessUserFile {
  fileType: string;
  fileS3Key: string;
  seq: number;
  fileName: string;
}
interface BusinessUser {
  bankAccNumber: string;
  bankName: string;
  bankAccOwner: string;
  businessUserFiles: BusinessUserFile[];
}

function IACServiceBidRegistration() {
  const navigate = useNavigate();
  const {state} = useLocation();
  const [buildingList, setBuildingList] = useState<Building[]>([]);
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows | DataTableValueArray | undefined>(undefined);

  // @ts-ignore
  let { userInfo } = useContext(UserInfoContext);

  const [totalPrice, setTotalPrice] = useState<StandardPriceTotal>();
  const [infrastructure, setInfrastructure] = useState<InfraQuantitySummary[]>([]);
  const [maintenance, setMaintenance] = useState<string | Blob>("N");
  const [performance, setPerformance] = useState<string | Blob>("N");
  const [documentList, setDocumentList] = useState<any[]>([]);
  const [documentSelected, setDocumentSelected] = useState<any[]>([]);
  const [description, setDescription] = useState<string | Blob>("");
  const [biddingEndDate, setBiddingEndDate] = useState<Nullable<Date>>(null);
  const [contractStartDate, setContractStartDate] = useState<Nullable<Date>>(null);
  const [contractEndDate, setContractEndDate] = useState<Nullable<Date>>(null);
  const [infraModalvisible, setInfraModalvisible] = useState<boolean>(false);
  const [submitModalvisible, setSubmitModalvisible] = useState<boolean>(false);
  const [confirmModalvisible, setconfirmModalvisible] =
    useState<boolean>(false);
  const [businessUser, setBusinessUser] = useState<BusinessUser>();
  const cameraRef = useRef<HTMLInputElement | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const quotationPriceSample = [
    {area: "1,000m²이상 ~ 3,000m²미만", price: 1000000}
    , {area: "3,000m²이상 ~ 5,000m²미만", price: 1500000}
    , {area: "5,000m²이상 ~ 10,000m²미만", price: 2000000}
    , {area: "10,000m²이상 ~ 20,000m²미만", price: 2500000}
    , {area: "20,000m²이상 ~ 30,000m²미만", price: 3000000}
    , {area: "30,000m²이상", price: 3500000}
  ];

  const depositAmount = ()=>{
    let sum = 0;
    for (const item of buildingList) {
      sum = item.totalArea + sum;
    }
    let price = 1000000;
    if (3000 <= sum && sum < 5000) {
      price = 1500000;
    } else if (5000 <= sum && sum < 10000) {
      price = 2000000;
    } else if (10000 <= sum && sum < 20000) {
      price = 2500000;
    } else if (20000 <= sum && sum < 30000) {
      price = 3000000;
    } else if (30000 <= sum) {
      price = 3500000;
    }
    return KRWon.format(price);
  };

  const downloadBankbookFile = () => {
    if (!businessUser?.businessUserFiles) {
      return;
    }
    for (const file of businessUser?.businessUserFiles) {
      if (file.fileType === 'BankAccFile') {
        downloadBUFile(file.seq, file.fileName).then(()=>{

        });
      }
    }
  };

  const showInfraSummary = (data:any) => {
    setInfrastructure(data.infraSummary);
    setInfraModalvisible(true);
  };

  const addDocuments = (length: any) => {
    setDocumentList([
      ...documentList,
      { documentReqNameEtc: "", documentReqId: length + 1, newDoc: true },
    ]);
  };

  const removeDocument = (id: any) => {
    setDocumentList(documentList.filter((doc) => doc.documentReqId !== id));
    setDocumentSelected(
      documentSelected.filter((doc) => doc.documentReqId !== id)
    );
  };

  const totalAreaTemplate = (rowData: Building) => {
    return rowData.totalArea.toLocaleString() + " m²";
  };
  const rowExpansionTemplate = (data: any) => {
    return (
      <div className="">
        <h5 className={'my-3 font-semibold'}>포함된 표제부 목록</h5>
        <DataTable
          value={data.buildingDongList}
          size={'small'}
          rowClassName={(data, options) => "text-sm border"}
        >
          <Column field="buiildingName" header="건물명"></Column>
          <Column field="totalArea" header="연면적"></Column>
          <Column field="dongName" header="동이름"></Column>
          <Column field="mainPurposeName" header="주용도"></Column>
          <Column field="useApproveDay" header="사용승인일"></Column>
        </DataTable>
      </div>
    );
  };
  const onUploadButtonClicked = useCallback(() => {
    if (!cameraRef.current) {
      return;
    }
    cameraRef.current.click();
  }, []);
  const onUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const selectedFiles = e.target.files;

    // 파일사이즈 5MB 제한
    const maxSize = 5 * 1024 * 1024;
    // @ts-ignore
    for (const file of selectedFiles) {
      if (file.size > maxSize) {
        alert("파일 크기는 5MB 미만만 업로드가 가능합니다.");
        return;
      }
    }
    let finalFileList = Array.from(uploadedFiles).concat(
      Array.from(selectedFiles)
    );
    setUploadedFiles(finalFileList);
  };
  const onDeleteFile = (id: any)=> {
    setUploadedFiles(uploadedFiles.filter((_, index) => index !== id));
  };
  const allowExpansion = (rowData: any) => {
    return rowData.buildingDongList.length > 0;
  };
  const infraSummaryButtonTemplate = (data: any) => {
    return (
      <button
        className={'px-10 py-2 bg-[#3F80EA] disabled:bg-[#DADADA] hover:bg-[#4a8dcc] duration-300 text-base font-extrabold text-white rounded-xl'}
        onClick={()=>showInfraSummary(data)}
      >보기</button>
    );
  };
  const sumTotalArea = (buildingList:Building[]) => {
    let sum = 0;
    for (const item of buildingList) {
      sum = item.totalArea + sum;
    }
    return sum.toLocaleString() + " m²";
  };

  const submitBidDetails = () => {
    const formData = new FormData();

    formData.append('createUser', userInfo?.userId);
    formData.append('maintenanceYn', maintenance);
    formData.append('performanceYn',performance);
    formData.append('description', description);
    formData.append('status', "ANNOUNCED");
    // @ts-ignore
    formData.append('dueDate', biddingEndDate?.toISOString().slice(0, 10));
    // @ts-ignore
    formData.append('contractStartDate', contractStartDate?.toISOString().slice(0, 10));
    // @ts-ignore
    formData.append('contractEndDate', contractEndDate?.toISOString().slice(0, 10));

    for (let i=0; i<buildingList.length; i++) {
      formData.append('buildingList['+ i +'].buildingId', buildingList[i].buildingId.toString());
      formData.append('buildingList['+ i +'].buildingName', buildingList[i].buildingName);
    }
    formData.append('province', buildingList[0].address.split(' ')[0]); // province 는 같으므로 첫번째 값을 넣어준다.
    for (let i=0; i<documentSelected.length; i++) {
      formData.append('documentRequirements['+i+'].documentReqId', documentSelected[i].documentReqId);
      if (documentSelected[i].documentReqBaseId) {
        formData.append('documentRequirements['+i+'].documentReqBaseId', documentSelected[i].documentReqBaseId);
      }
      if (documentSelected[i].documentReqName) {
        formData.append('documentRequirements['+i+'].documentReqName', documentSelected[i].documentReqName);
      }
      if (documentSelected[i].documentReqNameEtc) {
        formData.append('documentRequirements['+i+'].documentReqNameEtc', documentSelected[i].documentReqNameEtc);
      }
    }
    for (let i=0; i<uploadedFiles.length; i++) {
      formData.append('files', uploadedFiles[i]);
    }

    saveServiceBid(formData).then((result) => {
      if (result.status === 200) {
        setSubmitModalvisible(false);
        setconfirmModalvisible(true);
      } else {
        alert("오류가 발생했습니다. 관리자에게 문의하세요.");
      }
    });
  };


  useEffect(() => {
    setBuildingList(state.buildings);

    let sumMaintenancePrice = 0;
    let sumPerformancePrice = 0;
    for (const item of state.buildings) {
      getSummaryIACInfra(item.buildingId, item.quotationSeq).then((result)=>{
        item.infraSummary = result;
        for (const infra of item.infraSummary) {
          sumMaintenancePrice = infra.maintenancePrice + sumMaintenancePrice;
          sumPerformancePrice = infra.performancePrice + sumPerformancePrice;
        }
      }).finally(()=>{
        setTotalPrice({
          maintenancePrice: sumMaintenancePrice,
          performancePrice: sumPerformancePrice,
          wholePrice: sumMaintenancePrice + sumPerformancePrice
        });
      });
    }

    getDocumentReqBase().then((result) => {
      const updatedDocumentList = result.map((list: any, index: any) => ({
        documentReqId: index + 1,
        documentReqBaseId: list.documentReqBaseId,
        documentReqName: list.documentReqName,
        mandatoryYn: list.mandatoryYn,
      }));

      const documentList = [
        ...updatedDocumentList,
        {
          documentReqNameEtc: "",
          documentReqId: updatedDocumentList.length + 1,
          newDoc: true,
          firstNewDoc: true,
        },
      ];

      setDocumentList(documentList);

      setDocumentSelected(
        updatedDocumentList.filter((doc: any) => doc.mandatoryYn === "Y")
      );
    });

    getBusinessUser(userInfo.userId).then((result)=>{
      setBusinessUser(result);
    });

  }, [state]);

  return (
    <div>
      <Header></Header>
      <div className={'grid grid-cols-12 content'}>
        <div className={'col-span-2'}></div>
        <div className={'col-span-8'}>
          <div className="pb-3">
            <h1 className="pt-10 my-12 font-semibold text-3xl">입찰하기</h1>
            <div className="my-6">
              <Stepper step={'step2'}></Stepper>
            </div>

            {/* selected building information */}

            <div>
              <div className="text-lg text-start font-semibold pt-10 pb-3">
                선택한 건물정보
              </div>

              <div className="">
                <DataTable
                  value={buildingList}
                  dataKey="buildingId"
                  emptyMessage="선택한 건물이 없습니다."
                  tableStyle={{minWidth: "50rem"}}
                  rowClassName={(data, options) => "text-sm border"}
                  tableClassName="border-separate"
                  columnResizeMode="expand"
                  className=""
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data)}
                  rowExpansionTemplate={rowExpansionTemplate}
                >
                  <Column expander={allowExpansion} style={{width: '5rem'}}/>
                  <Column field="buildingName" header="건물명"></Column>
                  <Column field="address" header="주소"></Column>
                  <Column
                    field="totalArea"
                    header="연면적"
                    body={totalAreaTemplate}
                    headerClassName=""
                    className=""
                  ></Column>
                  <Column
                    header="설비 현황"
                    body={infraSummaryButtonTemplate}
                  ></Column>
                </DataTable>
              </div>
              <div className={'card grid grid-cols-4 gap-4 my-3'}>
                <span className={'col-start-3 p-3 rounded-lg bg-white'}>총 연면적 합계</span>
                <span className={'p-3 rounded-lg bg-white'}>{sumTotalArea(buildingList)}</span>
              </div>
            </div>

            {/* building infrastructure quantity check */}
            <Dialog
              header="건물 설비 현황 수량 요약"
              visible={infraModalvisible}
              style={{width: "50vw"}}
              onHide={() => {
                if (!infraModalvisible) return;
                setInfraModalvisible(false);
              }}
            >
              <div className="card">
                <div className="grid grid-cols-4 gap-x-6 gap-y-3 bg-white rounded-md text-center">
                  {infrastructure.map((item, index) => {
                    return (
                      <div
                        className="w-full bg-[#F5F5F5] border rounded-lg p-3"
                        key={item.infraTypeSeq}
                      >
                        <div className="text-lg font-semibold">
                          {item.infraTypeName}
                        </div>
                        <div className="text-xl font-medium mt-2">
                          {item.quantity}
                          <span className="text-sm font-light"> 개</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Dialog>

            <div className="">
              <div className="flex justify-between items-center">
                <div className="text-lg text-start font-semibold pt-10 pb-3">
                  견적 기준 표준 금액
                </div>
                <div className="flex items-start gap-x-1 pt-10">
                  <img
                    src={warning_badge}
                    alt="warning_badge"
                    className="mt-0.5"
                  />
                  <div>
                    <div className="text-start text-[#FF4242] text-sm font-normal">
                      연면적, 등록 설비에 대해 표준품셈을 기준으로 산정된 금액입니다.
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full bg-white p-6 mb-3">
                <div className="flex justify-between items-center pb-3">
                  <div className="font-normal">유지보수</div>
                  <div className="font-semibold">
                    {
                      totalPrice?.maintenancePrice
                        ? KRWon.format(totalPrice?.maintenancePrice)
                        : 0
                    }
                  </div>
                </div>

                <div className="flex justify-between items-center">
                  <div className="font-normal">성능점검</div>
                  <div className="font-semibold">
                    {
                      totalPrice?.performancePrice
                        ? KRWon.format(totalPrice?.performancePrice)
                        : 0
                    }
                  </div>
                </div>
              </div>

              <div className="flex justify-between items-center w-full bg-[#3F80EA] rounded-lg px-6 py-4 text-white">
                <div className="font-bold">견적 기준 표준 금액</div>
                <div className="font-semibold">
                  <span className="font-normal pr-2">총</span>
                  {
                    totalPrice?.wholePrice
                      ? KRWon.format(totalPrice?.wholePrice)
                      : 0
                  }
                </div>
              </div>
            </div>

            {/* service option */}

            <div>
              <div className="text-lg text-start font-semibold pt-10 pb-3">
                점검 종류 <span className="text-[#FF4242]">*</span>
              </div>

              <div className="grid grid-cols-4 gap-4 items-center bg-white p-4">
                <div className="ps-4 text-left">
                  <div>
                    <input
                      type="checkbox"
                      name="maintenance"
                      id="maintenance"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setMaintenance("Y");
                        } else {
                          setMaintenance("N");
                        }
                      }}
                    />
                    <span className="ml-3 text-base font-normal">
                  유지보수 점검
                </span>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="performance"
                      id="performance"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setPerformance("Y");
                        } else {
                          setPerformance("N");
                        }
                      }}
                    />
                    <span className="ml-3 text-base font-normal">
                  성능점검 {" "}
                </span>
                  </div>
                </div>

                <div className="flex w-full justify-end items-start gap-x-1 col-span-3">
                  <img src={warning_badge} alt="warning_badge" className="mt-0.5"/>
                  <div className="text-start text-[#FF4242] text-sm font-normal">
                    성능점검 가능 업체 수가 제한적인 현 상황에 따라 유지보수와
                    성능점검을 동시에 요청할 경우 유찰 될 가능성이 높습니다. 이 점
                    참고하시어 발주해주시기 바랍니다.
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="text-lg text-start font-semibold pt-10 pb-3">
                계약 관련 내용 등록
              </div>
              <div className="bg-white p-4 text-start">
                <div className={'font-semibold grid grid-cols-4 gap-4'}>
              <span className={'ml-3'}>
                계약 기간 <span className="text-[#FF4242]">*</span>
              </span>
                  <div className="flex w-full items-start gap-x-1 col-span-3">
                    <img src={warning_badge} alt="warning_badge" className="mt-0.5"/>
                    <div className="text-start text-[#FF4242] text-sm font-normal">
                      표준 계약서에 명시될 계약 기간이므로 신중하게 입력해주세요.
                    </div>
                  </div>
                </div>
                <div className={'grid grid-cols-4 gap-4 mt-6'}>
                  <div className={'col-span-2 ml-3'}>
                    <div>
                      계약 시작일
                    </div>
                    <Calendar
                      className="w-2/3 border rounded-lg"
                      inputClassName="p-3 text-base"
                      dateFormat="yy-mm-dd"
                      placeholder="날짜를 선택해주세요."
                      value={biddingEndDate}
                      onChange={(e) => setContractStartDate(e.value)}
                      readOnlyInput
                      showIcon
                      icon={() => <img src={calender_icon} alt="calender_icon"/>}
                    />
                  </div>
                  <div className={'col-span-2 ml-3'}>
                    <div>
                      계약 종료일
                    </div>
                    <Calendar
                      className="w-2/3 border rounded-lg"
                      inputClassName="p-3 text-base"
                      dateFormat="yy-mm-dd"
                      placeholder="날짜를 선택해주세요."
                      value={biddingEndDate}
                      onChange={(e) => setContractEndDate(e.value)}
                      readOnlyInput
                      showIcon
                      icon={() => <img src={calender_icon} alt="calender_icon"/>}
                    />
                  </div>

                </div>
              </div>
              {/*<div className="bg-white p-4 text-start mt-4">*/}
              {/*  <div className={'font-semibold grid grid-cols-6 gap-4'}>*/}
              {/*    <span className={'ml-3'}>*/}
              {/*      표준 계약서 확인<span className="text-[#FF4242]">*</span>*/}
              {/*    </span>*/}
              {/*    <div className="flex w-full items-start gap-x-1 col-span-4">*/}
              {/*      <img src={warning_badge} alt="warning_badge" className="mt-0.5"/>*/}
              {/*      <div className="text-start text-[#FF4242] text-sm font-normal">*/}
              {/*        베타점검의 계약은 표준계약서를 기준으로 체결됩니다. 우측의 양식 다운로드 버튼을 클릭하여 확인 가능합니다.*/}
              {/*        표준 계약서 이외의 별도 계약의 특이사항은 아래의 특약문서에 명시하여 첨부해주세요.*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <button*/}
              {/*        className={'px-10 py-2 bg-[#3F80EA] disabled:bg-[#DADADA] hover:bg-[#4a8dcc] duration-300 text-base font-extrabold text-white rounded-xl'}*/}
              {/*      >양식 다운로드*/}
              {/*      </button>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="bg-white p-4 text-start mt-4">*/}
              {/*  <div className={'font-semibold grid grid-cols-6 gap-4'}>*/}
              {/*    <span className={'ml-3'}>*/}
              {/*      특약 문서 첨부<span className="text-[#FF4242]">*</span>*/}
              {/*    </span>*/}
              {/*    <div className="flex w-full items-start gap-x-1 col-span-4">*/}
              {/*      <img src={warning_badge} alt="warning_badge" className="mt-0.5"/>*/}
              {/*      <div className="text-start text-[#FF4242] text-sm font-normal">*/}
              {/*        추가하신 특약 문서는 계약시에 첨부되어 계약의 특약 사항으로 취급됩니다.*/}
              {/*        특약 문서는 공고에 등록되어 투찰을 원하는 모든 업체들이 확인할 수 있습니다.*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <button*/}
              {/*        className={'px-10 py-2 bg-white disabled:bg-[#DADADA] duration-300 text-base font-extrabold text-[#3F80EA] rounded-xl border-2 border-[#3F80EA]'}*/}
              {/*      >항목 추가*/}
              {/*      </button>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>

            {/* document requirements */}

            <div>
              <div className="flex justify-between items-end w-full pt-10 pb-3">
                <div className="text-lg text-start font-semibold">
                  요청 사항
                </div>
              </div>

              <div className="flex flex-wrap w-full gap-3 items-start bg-white p-4  ">
                <div className={'font-semibold grid grid-cols-4 gap-4'}>
              <span className={'text-start'}>
                투찰시 요청할 서류
              </span>
                  <div className="flex w-full items-start gap-x-1 col-span-3">
                    <img src={warning_badge} alt="warning_badge" className="mt-0.5"/>
                    <div className="text-start text-[#FF4242] text-sm font-normal">
                      선택하신 요청 서류들은 투찰시 반드시 첨부해야하는 필수 조건으로 저장됩니다.
                      투찰 업체는 선택한 서류를 반드시 첨부해야 투찰을 완료할 수 있습니다.
                    </div>
                  </div>
                </div>

                {documentList.length === 0 && <div>No Documents Found</div>}

                {documentList.length > 0 && (
                  <>
                    {documentList?.map((document: any, index) =>
                      !document.newDoc ? (
                        <div
                          className={
                            document.mandatoryYn === "Y" ? "w-full text-start" : ""
                          }
                          key={document.documentReqId}
                        >
                          <input
                            checked={
                              document.mandatoryYn === "Y" ? true : undefined
                            }
                            disabled={document.mandatoryYn === "Y"}
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setDocumentSelected([
                                  ...documentSelected,
                                  document,
                                ]);
                              } else {
                                setDocumentSelected(
                                  documentSelected.filter((id) => id !== document)
                                );
                              }
                            }}
                          />
                          <span className="ml-3 text-nowrap">
                        {document.documentReqName}
                            {document.mandatoryYn === "Y" ? (
                              <span className="text-[#FF4242]"> (필수)</span>
                            ) : (
                              ""
                            )}
                      </span>
                        </div>
                      ) : (
                        <div
                          className="w-full text-start grid grid-cols-6 gap-4"
                          key={document.documentReqId}
                        >
                      <span>
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setDocumentSelected([
                                ...documentSelected,
                                document,
                              ]);
                            } else {
                              setDocumentSelected(
                                documentSelected.filter((id) => id !== document)
                              );
                            }
                          }}
                        />
                      <span className="ml-3 text-nowrap">직접입력</span>
                      </span>
                          <input
                            type="text"
                            className="ml-3 p-2 col-span-3 border rounded-lg text-base placeholder:text-[#E0E0E0]"
                            placeholder="직접 입력헤주세요."
                            onChange={(e) =>
                              (documentList[index].documentReqNameEtc =
                                e.target.value)
                            }
                          />
                          {
                            document.firstNewDoc && (
                              <button
                                onClick={() => addDocuments(documentList.length)}
                                className={' py-2 bg-white disabled:bg-[#DADADA] duration-300 text-base font-extrabold text-[#3F80EA] rounded-xl border-2 border-[#3F80EA]'}
                              >
                                <div className="font-semibold">항목 추가</div>
                              </button>
                            )
                          }

                          {!document.firstNewDoc && (
                            <button
                              onClick={() => removeDocument(document.documentReqId)}
                              className="ml-3"
                            >
                              <img
                                src={cross_icon}
                                alt="cross_icon"
                                className="-mb-0.5"
                              />
                            </button>
                          )}
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
            </div>

            {/* description */}

            <div className="flex flex-wrap w-full gap-3 items-start bg-white py-6 px-4 mt-4">
              <div className="text-start font-semibold">
                상세 설명
              </div>

              <textarea
                className="p-4 border bg-white rounded-md text-base w-full placeholder:text-[#BDBDBD] m-2"
                placeholder="입찰 내용, 건물 관련 상세 설명을 여기에 적습니다."
                rows={10}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>

            <div className=" bg-white p-4 text-start mt-4">
              <div className={'font-semibold grid grid-cols-6 gap-4'}>
              <span className={'ml-3'}>
                첨부 파일
              </span>
                <div className="flex w-full items-start gap-x-1 col-span-4">
                  <img src={warning_badge} alt="warning_badge" className="mt-0.5"/>
                  <div className="text-start text-[#FF4242] text-sm font-normal">
                    투찰 업체에게 전달할 상세 의뢰 내용을 파일로 첨부할 수 있습니다.
                    첨부된 파일은 공고를 열람할 수 잇는 모든 회원에게 공개됩니다.
                  </div>
                </div>
                <div>
                  <button
                    onClick={onUploadButtonClicked}
                    className={'px-10 py-2 bg-white disabled:bg-[#DADADA] duration-300 text-base font-extrabold text-[#3F80EA] rounded-xl border-2 border-[#3F80EA]'}
                  >파일 추가
                  </button>
                  <input
                    type="file"
                    ref={cameraRef}
                    onChange={onUploadFile}
                    accept=".pdf, .docx, .doc, .hwp, .hwpx, image/*"
                    className="hidden"
                    multiple
                  />
                </div>
              </div>
              <div className={'p-4 border rounded-xl mt-4 bg-[#FAFAFA]'}>
                {
                  uploadedFiles.map((file, id) => {
                    return (
                      <div key={id}>
                        <button
                          onClick={() => onDeleteFile(id)}
                          className="ml-3"
                        >
                          <img
                            src={cross_icon}
                            alt="cross_icon"
                            className="-mb-0.5 me-4"
                          />
                        </button>
                        {file.name}
                      </div>
                    )
                  })
                }
              </div>
            </div>

            {/* bidding end date */}

            <div>
              <div className="text-lg text-start font-semibold pt-10 pb-3">
                입찰 마감일 <span className="text-[#FF4242]">*</span>
              </div>

              <div className="grid grid-cols-2 grid-rows-1 gap-4 items-center bg-white p-4">
                <div className="flex w-full gap-6 items-center">
                  <Calendar
                    className="w-2/3 border rounded-lg"
                    inputClassName="p-3 text-base"
                    dateFormat="yy-mm-dd"
                    placeholder="날짜를 선택해주세요."
                    value={biddingEndDate}
                    onChange={(e) => setBiddingEndDate(e.value)}
                    minDate={addDays(new Date(), 3)}
                    maxDate={addDays(new Date(), 14)}
                    readOnlyInput
                    showIcon
                    icon={() => <img src={calender_icon} alt="calender_icon"/>}
                  />
                </div>

                <div className="flex w-full justify-end items-start gap-x-1">
                  <img src={warning_badge} alt="warning_badge" className="mt-0.5"/>
                  <div className="text-start text-[#FF4242] text-sm font-normal">
                    입찰 마감 시간은 오후 3시입니다. 마감일은 등록일로부터 최소 3일
                    ~ 최대 14일 까지 선택할 수 있습니다.
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="text-lg text-start font-semibold pt-10 pb-3">
                보증금 결제 안내
              </div>

              <div className="gap-4 items-center bg-white p-4">
                <div className="flex w-full items-start gap-x-1">
                  <img src={warning_badge} alt="warning_badge" className="mt-0.5"/>
                  <div className="text-start text-[#FF4242] text-sm font-normal">
                    무통장 입금 방식으로 결제되며, 등록된 통장사본의 정보와 입금자 정보가 반드시 동일해야 결제 확인이 가능합니다.
                    관리자가 입금 확인 후 공고가 게시됩니다.
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-4 gap-4 items-center bg-white p-4 mt-4">
                <div className="text-start font-semibold pb-3">
                  <div>보증금</div>
                  <div className={'mt-2'}>{depositAmount()}</div>
                </div>
                <Accordion className={'col-span-3'}>
                  <AccordionTab header="보증금 조견표 확인">
                    <DataTable
                      value={quotationPriceSample}
                      rowClassName={(data, options) => "border text-sm"}
                      scrollable
                      resizableColumns
                      size={'small'}
                      columnResizeMode="expand"
                      tableClassName="border-separate"
                    >
                      <Column
                        field="area"
                        header="면적"
                        style={{minWidth: "12rem"}}
                        headerClassName=""
                        className=""
                      ></Column>
                      <Column
                        field="price"
                        header="보증금"
                        style={{minWidth: "12rem"}}
                        headerClassName=""
                        className=""
                        body={(item) => {
                          return KRWon.format(item.price)
                        }}
                      ></Column>
                    </DataTable>
                  </AccordionTab>
                </Accordion>
              </div>

              <div className="items-center bg-white p-4 mt-4">
                <div className="text-start font-semibold">
                  무통장 입금 계좌 안내
                </div>
                <div className={'grid grid-cols-3 text-start mt-3 border rounded-lg p-4'}>
                  <div>
                    <span>입금하실 은행</span>
                    <span className={'text-[#3F80EA] font-semibold ms-4'}>국민은행</span>
                  </div>
                  <div>
                    <span>계좌번호</span>
                    <span className={'text-[#3F80EA] font-semibold ms-4'}>775522668899</span>
                  </div>
                  <div>
                    <span>예금주명</span>
                    <span className={'text-[#3F80EA] font-semibold ms-4'}>베타점검</span>
                  </div>
                </div>
              </div>

              <div className="items-center bg-white p-4 mt-4">
                <div className={'grid grid-cols-4 '}>
                  <div className="text-start font-semibold">
                    등록된 계좌 정보
                  </div>
                  <div className="flex w-full items-start gap-x-1 col-span-3">
                    <img src={warning_badge} alt="warning_badge" className="mt-0.5"/>
                    <div className="text-start text-[#FF4242] text-sm font-normal">
                      가입시 등록한 정보로 입금이 확인됩니다. 등록된 통장과 예금주명을 사용하여 입금 부탁드립니다.
                    </div>
                  </div>
                </div>
                <div className={'border rounded-lg p-4 mt-4 grid grid-cols-2 gap-x-10 gap-y-4 text-start'}>
                  <div className={'grid-cols-3 grid'}>
                    <label>예금주명</label>
                    <InputText
                      className={'col-span-2'}
                      disabled
                      value={businessUser?.bankAccOwner}
                    />
                  </div>
                  <div className={'grid-cols-3 grid'}>
                    <label>계좌번호</label>
                    <InputText
                      className={'col-span-2'}
                      disabled
                      value={businessUser?.bankAccNumber}
                    />
                  </div>
                  <div className={'grid-cols-3 grid'}>
                    <label>은행</label>
                    <InputText
                      className={'col-span-2'}
                      disabled
                      value={businessUser?.bankName}
                    />
                  </div>
                  <div className={'grid-cols-3 grid'}>
                    <label>통장사본</label>
                    <button
                      className={'py-2 bg-white disabled:bg-[#DADADA] duration-300 text-base font-extrabold text-[#3F80EA] rounded-xl border-2 border-[#3F80EA]'}
                      onClick={downloadBankbookFile}
                    >파일 다운로드
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* start bidding button */}

            <button
              onClick={() => {
                setSubmitModalvisible(true);
              }}
              className="w-1/2 my-20 px-3 py-2.5 bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA]  disabled:opacity-50 hover:opacity-90 duration-300 text-base font-semibold text-white rounded-xl"
            >
              입찰 시작하기
            </button>

            <Dialog
              header=""
              visible={submitModalvisible}
              closable={false}
              style={{width: "60vw"}}
              onHide={() => {
                if (!submitModalvisible) return;
                setSubmitModalvisible(false);
              }}
            >
              <div className="flex flex-col justify-center items-center gap-6">
                <h4>입찰을 등록하시겠습니까?</h4>

                <div className="text-start text-[#FF4242] text-base">
                  * 입찰 등록 후에는 수정과 취소가 불가하며 1개 이상의 업체가
                  투찰했을시 일부 계약 의무가 있습니다.
                </div>

                <div className="flex justify-center items-center w-full space-x-2 mt-6">
                  <button
                    className="w-1/4 px-3 py-2 disabled:bg-[#DADADA] hover:bg-[#ebebeb] duration-300 text-base font-extrabold border border-black rounded-xl"
                    onClick={() => {
                      setSubmitModalvisible(false);
                    }}
                  >
                    취소
                  </button>
                  <button
                    className="w-1/4 px-3 py-2 bg-[#519CE1] disabled:bg-[#DADADA] hover:bg-[#4a8dcc] duration-300 text-base font-extrabold text-white rounded-xl"
                    onClick={() => {
                      submitBidDetails();
                    }}
                  >
                    확인
                  </button>
                </div>
              </div>
            </Dialog>

            <Dialog
              header=""
              visible={confirmModalvisible}
              closable={false}
              style={{width: "60vw"}}
              onHide={() => {
                if (!confirmModalvisible) return;
                setconfirmModalvisible(false);
              }}
            >
              <div className="flex flex-col justify-center items-center gap-6">
                <h4>입찰 공고 등록이 완료되었습니다.</h4>

                <div className="flex justify-center items-center w-full space-x-2 mt-6">
                  <button
                    className="w-1/4 px-3 py-2 bg-[#519CE1] disabled:bg-[#DADADA] hover:bg-[#4a8dcc] duration-300 text-base font-extrabold text-white rounded-xl"
                    onClick={() => {
                      setconfirmModalvisible(false);
                      navigate('/IACServiceBidList');
                    }}
                  >
                    확인
                  </button>
                </div>
              </div>
            </Dialog>

            <Dialog
              header=""
              visible={confirmModalvisible}
              closable={false}
              style={{width: "60vw"}}
              onHide={() => {
                if (!confirmModalvisible) return;
                setconfirmModalvisible(false);
              }}
            >
              <div className="flex flex-col justify-center items-center gap-6">
                <h4>입찰 공고 등록이 완료되었습니다.</h4>

                <div className="flex justify-center items-center w-full space-x-2 mt-6">
                  <button
                    className="w-1/4 px-3 py-2 bg-[#519CE1] disabled:bg-[#DADADA] hover:bg-[#4a8dcc] duration-300 text-base font-extrabold text-white rounded-xl"
                    onClick={() => {
                      setconfirmModalvisible(false);
                      navigate('/IACServiceBidList');
                    }}
                  >
                    확인
                  </button>
                </div>
              </div>
            </Dialog>

          </div>
        </div>
        <div className={'col-span-2'}></div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default IACServiceBidRegistration;
