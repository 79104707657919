import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import { UserInfoContext } from "../../App";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import {getMyBuildingList, getQuotationList, requestBuildingQuotation} from "../../services/serviceBidService";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {Accordion, AccordionTab} from "primereact/accordion";
import {KRWon} from "../../services/utils/formatUtil";
import warning_badge_blue from "../../images/svg/warning_badge_blue.svg";
import {downloadBUFile, getBusinessUserFiles} from "../../services/authoriedUserService";
import { NiceCommonForm } from "../../components/NiceCommonForm";
import {RegionOptions} from "../../data/data";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";

interface Building {
  buildingId: number;
  buildingName: string;
  address: string;
  totalArea: number;
}
interface Quotation {
  seq: number,
  price: number,
  userId: string,
  buildingIdList: number[],
  status: string
}

function MyBuildingList() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // @ts-ignore
  let { userInfo, logout } = useContext(UserInfoContext);
  const [buildingList, setBuildingList] = useState<Building[]>([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedBuildings, setSelectedBuildings] = useState<Building[]>([]);
  const [requestModalVisible, setRequestModalVisible] = useState(false);
  const niceCommonFormRef = useRef(null);
  const [phone, setPhone] = useState("");
  const [fullName, setFullName] = useState("");
  const [quotationList, setQuotationList] = useState<Quotation[]>([]);
  const nicePhoneValidation = () => {
    // @ts-ignore
    niceCommonFormRef.current.niceFormSubmit();
  };
  const [bankAccFile, setBankAccFile] = useState({
    userId: "",
    fileName: "",
    fileUrl: "",
    fileSize: "",
    seq: 0,
    fileType: "",
    fileTypeName: "",
  });

  const quotationPriceSample = [
    {area: "1,000m²이상 ~ 3,000m²미만", price: 1000000}
    , {area: "3,000m²이상 ~ 5,000m²미만", price: 1500000}
    , {area: "5,000m²이상 ~ 10,000m²미만", price: 2000000}
    , {area: "10,000m²이상 ~ 20,000m²미만", price: 2500000}
    , {area: "20,000m²이상 ~ 30,000m²미만", price: 3000000}
    , {area: "30,000m²이상", price: 3500000}
  ];

  const sumTotalArea = () => {
    let sum = 0;
    if (selectedBuildings.length > 0) {
      selectedBuildings.forEach((item)=>{
        sum += item.totalArea;
      })
    }
    return sum;
  };
  const calculateQuotationPrice = () => {
    const totalArea = sumTotalArea();
    let price = 1000000;
    if (3000 <= totalArea && totalArea < 5000) {
        price = 1500000;
    } else if (5000 <= totalArea && totalArea < 10000) {
      price = 2000000;
    } else if (10000 <= totalArea && totalArea < 20000) {
      price = 2500000;
    } else if (20000 <= totalArea && totalArea < 30000) {
      price = 3000000;
    } else if (30000 <= totalArea) {
      price = 3500000;
    }
    return price;
  };
  const downloadFile = (file: any) => {
    console.log(file);
    downloadBUFile(file.seq, file.fileName).then();
  };

  const locationOptions = RegionOptions;
  const statusOptions = [
    { name: "미요청", value: "EMPTY" },
    { name: "견적 요청중", value: "REQUESTED" },
    { name: "견적 진행중", value: "ASSIGNED" },
    { name: "견적 완료", value: "CLOSED" },
  ];

  const totalAreaTemplate = (data: Building) => {
    return data.totalArea.toLocaleString() + "m²";
  };

  const requestQuotation = () => {
    console.log('selectedBuildings', selectedBuildings);

    if (selectedBuildings.length === 0) {
      alert('건물을 선택해야 합니다.');
      return;
    }

    if (window.confirm('건물 견적을 요청하시겠습니까?')) {
      setRequestModalVisible(true);
    }
  };

  const saveQuotationRequest = ()=> {
    if (!window.confirm("건물 견적을 요청하시겠습니까?")) {
      return;
    }

    // 저장을 하기 위해서 userId, price, buildingIdList 가 필요함
    let buildingIdList = [];
    for (const building of selectedBuildings) {
      buildingIdList.push(building.buildingId);
    }

    const param = {
      userId: userInfo.userId,
      price: calculateQuotationPrice(),
      buildingIdList: buildingIdList,
      quotationStatus: 'REQUESTED'
    }
    setLoading(true);
    requestBuildingQuotation(param).then(()=>{
      alert('건물 견적을 요청하였습니다.');
      setRequestModalVisible(false);
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });

  };

  const handleSearch = () => {
    setLoading(true);

    const payload = {
      userId: userInfo.userId,
      koreanProvinces: selectedLocation,
      status: selectedStatus,
      searchText: searchText,
    };

    getMyBuildingList(payload).then((result) => {
      console.log(result);
      setBuildingList(result);
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });
  };

  useEffect(() => {
    const param = {
      userId: userInfo.userId,
      koreanProvinces: selectedLocation,
      status: selectedStatus,
      searchText: searchText,
    };

    // setPhone
    setPhone(userInfo.phone);

    getQuotationList(param).then((result)=>{
      console.log('getQuotationList', result);
      setQuotationList(result);
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });

    getBusinessUserFiles(userInfo.userId).then((result)=>{
      for (const file of result) {
        if (file.fileType === 'BankAccFile') {
          setBankAccFile(file);
        }
      }
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });

    if (userInfo.companyYn === 'N') {
      alert('권한이 없습니다. 홈으로 이동합니다.');
      navigate('/');
    }

    setLoading(true);
    getMyBuildingList(param).then((result) => {
      console.log(result);
      setBuildingList(result);
    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });
  }, [requestModalVisible]);

  return (
    <div>
      <Header></Header>
      <div className={'content grid grid-cols-12'}>
        <div className={'col-span-2'}></div>
        <div className="col-span-8">
          <div className="">
            <h1 className="pt-10 my-12 font-semibold text-3xl">내 건물 목록</h1>

            <div className="flex items-end w-full bg-[#F5F5F5] p-3 rounded-md gap-3">
              <div className="w-1/4">
                <label
                  htmlFor="location"
                  className="flex text-start text-base font-semibold"
                >
                  지역
                </label>

                <Dropdown
                  value={selectedLocation}
                  showClear
                  filter
                  onChange={(e: DropdownChangeEvent) =>
                    setSelectedLocation(e.value)
                  }
                  options={locationOptions}
                  optionLabel="name"
                  placeholder="선택"
                  id="location"
                  className="w-full rounded-xl mt-2 text-left"
                  scrollHeight={'400px'}
                />
              </div>

              <div className="w-full">
                <label
                  htmlFor="searchBuilding"
                  className="flex text-start text-base font-semibold"
                >
                  검색
                </label>
                <InputText
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyUp={(e) => {
                    e.key === "Enter" && handleSearch();
                  }}
                  placeholder="키워드를 검색해 주세요."
                  id="searchBuilding"
                  className="w-full rounded-xl mt-2"
                />
              </div>

              <button
                className="border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-xl px-8 h-12"
                onClick={() => handleSearch()}
              >
                검색하기
              </button>
            </div>

            <div className="flex justify-between">
              <div className={'items-center pt-8'}>
                Total{" "}
                <span className="font-bold text-[#3F80EA]">
              {buildingList?.length || 0}
            </span>
              </div>

              <div className={''}>
                <button
                  onClick={() => navigate("/myBuilding")}
                  className="mt-5 bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] px-5 py-2.5 disabled:opacity-50 hover:opacity-90 duration-300 text-base font-semibold text-white rounded-xl"
                >
                  새 건물 등록
                </button>
              </div>
            </div>

            <div>
              <DataTable
                value={buildingList}
                selectionMode={"checkbox"}
                showSelectAll={false}
                selection={selectedBuildings}
                onSelectionChange={
                  (e) => {
                    let isProvinceMatch = true;
                    for (const i in e.value) {
                      const stProvince = e.value[0].address.split(' ')[0];
                      if (stProvince !== e.value[i].address.split(' ')[0]) {
                        isProvinceMatch = false;
                      }
                    }
                    if (isProvinceMatch) {
                      setSelectedBuildings(e.value);
                    } else {
                      alert('동일한 광역 지방자치단체(서울,경기 등) 만 한번에 견적 요청을 할 수 있습니다.');
                    }
                  }}
                dataKey="buildingId"
                emptyMessage="유지보수·관리 및 성능점검 대상 건물이 없습니다."
                tableStyle={{minWidth: "50rem"}}
                rowClassName={(data, options) => "border text-sm"}
                scrollable
                resizableColumns
                loading={loading}
                columnResizeMode="expand"
                tableClassName="border-separate"
              >
                <Column selectionMode="multiple" headerStyle={{width: '3rem'}}></Column>
                <Column
                  field="buildingId"
                  header="건물ID"
                  sortable
                  style={{minWidth: "12rem"}}
                  headerClassName=""
                  className=""
                ></Column>
                <Column
                  field="buildingName"
                  sortable
                  header="건물명"
                  headerClassName=""
                  className=""
                ></Column>
                <Column
                  field="address"
                  sortable
                  header="주소"
                  body={(rowData) => {
                    return <span className={'text-blue-600 cursor-pointer'} onClick={() => {
                      navigate("/buildingDetail/" + rowData.buildingId);
                    }}>{rowData.address}</span>
                  }}
                >
                </Column>
                <Column
                  field="totalArea"
                  header="연면적"
                  sortable
                  body={totalAreaTemplate}
                  headerClassName=""
                  className=""
                ></Column>
              </DataTable>
            </div>
          </div>
          <div className={'content'}>
            <button
              onClick={requestQuotation}
              className="mt-5 mb-10 bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] w-1/4 px-5 py-2.5 disabled:opacity-50 hover:opacity-90 duration-300 text-base font-semibold text-white rounded-xl"
            >
              점검견적요청
            </button>
          </div>
          {
            quotationList.length > 0
              ? <div className={'py-10 content'}>
                <div className={'font-semibold text-lg'}>견적 목록</div>
                <div className={'mt-5'}>
                  <DataTable
                    value={quotationList}
                    selectionMode="single"
                    onSelectionChange={(e) => {
                      navigate('/quotationDetail/' + e.value.seq);
                    }}
                    dataKey="seq"
                    emptyMessage="요청된 견적 건이 없습니다."
                    tableStyle={{minWidth: "50rem"}}
                    rowClassName={(data, options) => "border text-sm"}
                    scrollable
                    resizableColumns
                    loading={loading}
                    columnResizeMode="expand"
                    tableClassName="border-separate"
                  >
                    <Column
                      field="seq"
                      sortable
                      header="번호"
                      headerClassName=""
                      className=""
                    ></Column>
                    <Column
                      field="price"
                      sortable
                      header="견적가"
                      headerClassName=""
                      className=""
                      body={(item) => {
                        return KRWon.format(item.price)
                      }}
                    ></Column>
                    <Column
                      field="createDate"
                      sortable
                      header="견적 요청일"
                      headerClassName=""
                      className=""
                    ></Column>
                    <Column
                      field="status"
                      sortable
                      header="상태"
                      headerClassName=""
                      className=""
                    ></Column>
                  </DataTable>
                </div>
              </div>
              : <></>
          }
        </div>
        <div className={'col-span-2'}></div>
      </div>
      <Footer></Footer>

      <Dialog
        header="견적 요청서 작성"
        visible={requestModalVisible}
        closable={true}
        style={{width: "90vw"}}
        onHide={() => {
          if (!requestModalVisible) return;
          setRequestModalVisible(false);
        }}
      >
        <div>
          <div className={'flex justify-between text-lg font-semibold'}>
            <span>건물 정보</span>
            <span>
              총 연면적: {sumTotalArea() + "m²"}
            </span>
          </div>
          <DataTable
            value={selectedBuildings}
            dataKey="buildingId"
            emptyMessage="유지보수·관리 및 성능점검 대상 건물이 없습니다."
            tableStyle={{minWidth: "50rem"}}
            rowClassName={(data, options) => "border text-sm"}
            scrollable
            resizableColumns
            loading={loading}
            size={'small'}
            columnResizeMode="expand"
            tableClassName="border-separate"
          >
            <Column
              field="buildingId"
              header="건물ID"
              style={{minWidth: "12rem"}}
              headerClassName=""
              className=""
            ></Column>
            <Column
              field="buildingName"
              header="건물명"
              headerClassName=""
              className=""
            ></Column>
            <Column
              field="address"
              header="주소"
            >
            </Column>
            <Column
              field="totalArea"
              header="연면적"
              body={totalAreaTemplate}
              headerClassName=""
              className=""
            ></Column>
          </DataTable>
        </div>
        <div className={'mt-10'}>
          <div className={'font-semibold text-lg'}>견적비</div>
          <div className={'grid grid-cols-3'}>
            <div className={'text-lg font-semibold mt-5 ms-3'}>{KRWon.format(calculateQuotationPrice())}</div>
            <div className={'col-span-2'}>
              <Accordion>
                <AccordionTab header="견적비 조견표 확인">
                  <DataTable
                    value={quotationPriceSample}
                    rowClassName={(data, options) => "border text-sm"}
                    scrollable
                    resizableColumns
                    loading={loading}
                    size={'small'}
                    columnResizeMode="expand"
                    tableClassName="border-separate"
                  >
                    <Column
                      field="area"
                      header="면적"
                      style={{minWidth: "12rem"}}
                        headerClassName=""
                        className=""
                      ></Column>
                      <Column
                        field="price"
                        header="견적비용"
                        style={{minWidth: "12rem"}}
                        headerClassName=""
                        className=""
                        body={(item) => {
                          return KRWon.format(item.price)
                        }}
                      ></Column>
                    </DataTable>
                  </AccordionTab>
                </Accordion>
              </div>
            </div>
          </div>

          <div className={'mt-10'}>
            <div className={'text-lg font-semibold'}>무통장 입금 계좌 안내</div>
            <div className={'mt-3 grid grid-cols-3 border border-[#F5F5F5] rounded-xl gap-3 p-6'}>
              <div>
                입금하실 은행
                <span className={'ms-4 text-blue-500 font-semibold'}>신한은행</span>
              </div>
              <div>
                예금주명
                <span className={'ms-4 text-blue-500 font-semibold'}>베타점검</span>
              </div>
              <div>
                계좌번호
                <span className={'ms-4 text-blue-500 font-semibold'}>1785264200589</span>
              </div>
            </div>
          </div>

          <div>
            <div className="flex items-center gap-6 pt-10 pb-3">
              <div className="text-lg text-start font-semibold ">
                등록된 계좌 정보
              </div>
              <div className="flex gap-x-1">
                <img
                  src={warning_badge_blue}
                  alt="warning_badge_blue"
                  className="mt-0.5"
                />
                <div className="text-start text-[#757575] text-sm font-normal">
                  가입시 등록한 정보로 입금이 확인됩니다. 등록된 통장과
                  예금주명을 사용하여 입금 부탁드립니다.
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-3 bg-white p-6 rounded-xl border border-[#F5F5F5]">
              <div className="flex w-full gap-x-3 text-start">
                <div className="w-20 p-3 text-sm text-end font-thin text-[#9E9E9E]">
                  예금주명
                </div>
                <div className="w-3/4 text-start text-base font-semibold p-3 border border-[#F5F5F5] rounded-lg">
                  {userInfo.businessUser?.bankAccOwner}
                </div>
              </div>

              <div className="flex w-full gap-x-3 text-start">
                <div className="w-20 p-3 text-sm text-end font-thin text-[#9E9E9E]">
                  계좌번호
                </div>
                <div className="w-3/4 text-start text-base font-semibold p-3 border border-[#F5F5F5] rounded-lg">
                  {userInfo.businessUser?.bankAccNumber}
                </div>
              </div>

              <div className="flex w-full gap-x-3 text-start">
                <div className="w-20 p-3 text-sm text-end font-thin text-[#9E9E9E]">
                  은행
                </div>
                <div className="w-3/4 text-start text-base font-semibold p-3 border border-[#F5F5F5] rounded-lg">
                  {userInfo.businessUser?.bankName}
                </div>
              </div>

              <div className="flex w-full gap-x-3 text-start">
                <div className="w-20 p-3 text-sm text-end font-thin text-[#9E9E9E]">
                  통장사본
                </div>
                <div className="w-3/4 text-start text-base font-semibold p-3">
                  <button
                    onClick={() => {
                      downloadFile(bankAccFile);
                    }}
                    type="button"
                    className="px-3 bg-white rounded-2xl border border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white hover:border-transparent font-semibold"
                  >
                    파일 다운로드
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="text-lg text-start font-semibold pt-10">
              내 연락처 확인
            </div>

            <div className="flex justify-between items-center w-full bg-white px-3 rounded-xl gap-3">
              <div className="flex gap-x-1">
                <img
                  src={warning_badge_blue}
                  alt="warning_badge_blue"
                  className="mt-0.5"
                />
                <div className="text-start text-[#757575] text-sm font-normal">
                  본인인증시 사용한 연락처가 견적사님께 전달됩니다.
                  수정을 원하시면 다시 본인인증 해주세요.
                </div>
              </div>

              <div
                className={`w-1/3 flex ${
                  phone ? "justify-between" : "justify-end"
                } items-center text-start text-base font-semibold p-2 border border-[#F5F5F5] rounded-xl bg-white`}
              >
                {phone}
                <button
                  className="border-2 border-[#3F80EA] text-[#3F80EA] hover:bg-[#3F80EA] hover:text-white duration-300 font-semibold whitespace-nowrap bg-white rounded-xl px-4 py-3"
                  onClick={nicePhoneValidation}
                >
                  변경하기
                </button>
              </div>
            </div>
          </div>

          <div className={'flex justify-center'}>
            <button
              onClick={saveQuotationRequest}
              className="mt-5 mb-10 bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] w-1/4 px-5 py-2.5 disabled:opacity-50 hover:opacity-90 duration-300 text-base font-semibold text-white rounded-xl"
            >
              견적 요청하기
            </button>
          </div>

          <NiceCommonForm ref={niceCommonFormRef} setPhone={setPhone} setFullName={setFullName}/>
        </Dialog>

      </div>
      );
      }

      export default MyBuildingList;
