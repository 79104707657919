import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import { UserInfoContext } from "../../../App";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import {
  getMyBuildingList,
  getOneIACQuotation,
} from "../../../services/serviceBidService";
import {getUser} from "../../../services/authService";

interface Quotation {
  seq: number,
  status: string,
  userId: string, // 견적사
  price: number,
  createUser: string,
}
interface Building {
  buildingId: number;
  buildingName: string;
  address: string;
  totalArea: number;
  quotationStatus: string,
}
interface BuildingOwner {
  userId: string,
  name: string,
  phone: string,
  email: string
}

function AssignedQuotationDetail() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [quotation, setQuotation] = useState<Quotation>();
  const [buildingList, setBuildingList] = useState<Building[]>([]);
  const [buildingOwner, setBuildingOwner] = useState<BuildingOwner>();
  const { quotationSeq } = useParams();

  const totalAreaTemplate = (data: Building) => {
    return data.totalArea.toLocaleString() + "m²";
  };
  const sumTotalArea = () => {
    let sum = 0;
    if (buildingList.length > 0) {
      buildingList.forEach((item)=>{
        sum += item.totalArea;
      })
    }
    return sum;
  };

  // @ts-ignore
  let { userInfo, logout } = useContext(UserInfoContext);

  useEffect(() => {
    const param = {
      // quotationerId: userInfo.userId,
      quotationSeq: quotationSeq
    }
    setLoading(true);
    getOneIACQuotation(param).then((result) => {
      if (!result) {
        alert('견적 정보를 조회할 수 없습니다.');
        navigate('/assignedBuildingList');
      }
      setQuotation(result);
      getUser(result?.createUser).then((response)=>{
        setBuildingOwner(response);
      });

      const buildingParam = {
        userId: result.createUser,
        quotationSeq: quotationSeq
      };
      getMyBuildingList(buildingParam).then((result)=>{
        setBuildingList(result);
      });

    }).catch((error)=>{
      console.log(error);
      if (error === '토큰 만료, 로그아웃 처리됨.') {
        alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
        logout();
      }
    }).finally(()=>{
      setLoading(false);
    });
  }, [quotationSeq]);

  return (
    <div>
      <Header></Header>
      <div className={'grid grid-cols-12 content'}>
        <div className={'col-span-2'}></div>
        <div className={'col-span-8'}>
          <div className="pb-20">
            <h1 className="pt-10 my-12 font-semibold text-3xl">견적 상세</h1>
            <div>
              <div className="text-lg text-start font-semibold pt-10 pb-3">
                견적 정보
              </div>

              <div className="grid grid-cols-2 gap-x-10 gap-y-3">
                <div className="flex w-full gap-x-5">
                  <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                    견적번호
                  </div>
                  <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                    {quotation?.seq}
                  </div>
                </div>

                <div>
                  <div className="flex w-full gap-x-5">
                    <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                      견적 상태
                    </div>
                    <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                      {quotation?.status}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="text-lg text-start font-semibold pt-10 pb-3">
                발주자 정보
              </div>

              <div className="grid grid-cols-2 grid-rows-2 gap-x-10 gap-y-3">
                <div className="flex w-full gap-x-5">
                  <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                    발주자 ID
                  </div>
                  <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                    {buildingOwner?.userId}
                  </div>
                </div>

                <div>
                  <div className="flex w-full gap-x-5">
                    <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                      이름
                    </div>
                    <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                      {buildingOwner?.name}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="flex w-full gap-x-5">
                    <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                      전화번호
                    </div>
                    <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                      {buildingOwner?.phone}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex w-full gap-x-5">
                    <div className="w-1/4 bg-[#3F80EA] rounded-lg p-3 text-white">
                      이메일
                    </div>
                    <div className="w-3/4 text-start text-base font-semibold p-3 border rounded-lg bg-white">
                      {buildingOwner?.email}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="text-lg text-start pt-10 pb-3 flex justify-between">
                <span className={'font-semibold'}>건물 목록</span>
                <span>
              총 연면적:
              <span className={'font-semibold mx-2'}>
                {sumTotalArea() + "m²"}
              </span>
            </span>
              </div>
              <DataTable
                value={buildingList}
                dataKey="buildingId"
                emptyMessage="대상 건물이 없습니다."
                tableStyle={{minWidth: "50rem"}}
                rowClassName={(data, options) => "border text-sm"}
                scrollable
                resizableColumns
                loading={loading}
                columnResizeMode="expand"
                tableClassName="border-separate"
              >
                <Column
                  field="buildingId"
                  header="건물ID"
                  sortable
                  style={{minWidth: "12rem"}}
                  headerClassName=""
                  className=""
                ></Column>
                <Column
                  field="buildingName"
                  sortable
                  header="건물명"
                  headerClassName=""
                  className=""
                ></Column>
                <Column
                  field="address"
                  sortable
                  header="주소"
                  body={(rowData) => {
                    return <span className={'text-blue-600 cursor-pointer'} onClick={() => {
                      navigate("/assignedBuildingDetail/" + rowData.buildingId, {
                        state: {
                          buildingOwner: buildingOwner,
                          quotationSeq: quotationSeq
                        }
                      });
                    }}>{rowData.address}</span>
                  }}
                >
                </Column>
                <Column
                  field="totalArea"
                  header="연면적"
                  sortable
                  body={totalAreaTemplate}
                  headerClassName=""
                  className=""
                ></Column>
                <Column
                  field="quotationStatus"
                  header="견적 상태"
                  sortable
                  headerClassName=""
                  className=""
                ></Column>
              </DataTable>
            </div>
          </div>
        </div>
        <div className={'col-span-2'}></div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default AssignedQuotationDetail;
