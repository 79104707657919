import { useContext, useEffect, useState } from "react";
import { UserInfoContext } from "../App";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { sendEmail } from "../services/authoriedUserService";
import {useLocation} from "react-router-dom";

function Asking() {
  // @ts-ignore
  let { userInfo, logout } = useContext(UserInfoContext);
  const [loading, setLoading] = useState(false);

  const [emailBody, setEmailBody] = useState("");

  const {state} = useLocation();

  const handleChangeEmailBody = (e: any) => {
    setEmailBody(e.target.value);
  };

  const sendAskingEmail = () => {
    if (window.confirm("문의를 보내시겠습니까?")) {
      const param = {
        subject:
          userInfo.userId +
          " 사용자의 문의가 도착했습니다. (" +
          userInfo.email +
          ")",
        body: emailBody,
        from: "support@mygongsa.com",
        to: "support@mygongsa.com",
        userId: userInfo.userId,
        notificationType: "ASKING",
        siteName: "BETA-CHECK",
        koreanSiteName: "베타점검",
        logoUrl: "https://mygongsa-static-web-contents.s3.ap-northeast-2.amazonaws.com/beta-check/beta-check-logo.png"
      };
      setLoading(true);
      sendEmail(param).then((data) => {
        console.log("email sent!", data);
        alert("전송이 완료되었습니다.");
        setEmailBody("");
      }).catch((error)=>{
        console.log(error);
        if (error === '토큰 만료, 로그아웃 처리됨.') {
          alert('세션 시간이 만료되어 자동 로그아웃됩니다.');
          logout();
        }
      }).finally(()=>{
        setLoading(false);
      });
    } else {
      alert("취소합니다.");
    }
  };

  useEffect(() => {

  }, [state]);

  return (
    <div key={state}>
      <Header />
      <div className={'grid grid-cols-12 content'}>
        <div className={'col-span-2'}></div>
        <div className={'col-span-8'}>
          <div className="pt-10 px-4 py-8 min-h-screen">
            <div className="max-w-full mx-auto">
              <h1 className="text-4xl font-bold my-12">문의하기</h1>
              <p className="mb-6 text-lg">
                문의사항을 보내주시면 가입시 입력하신 이메일로 빠르게 회신
                드리겠습니다.
              </p>
              <div className="mb-4 text-start">
                {userInfo !== null ? (
                  <p className="text-base">회신받는 이메일: {userInfo.email}</p>
                ) : null}
              </div>
              <textarea
                className="w-full p-2 border border-gray-300 rounded mb-4 form-control"
                rows={6}
                value={emailBody}
                onChange={handleChangeEmailBody}
                placeholder="문의 내용을 입력해주세요."
              />
              <button
                className="hover:opacity-90 transition duration-300 ease-in-out bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] text-white font-bold py-2 px-4 rounded"
                onClick={sendAskingEmail}
              >
                문의 보내기
              </button>
            </div>
          </div>
        </div>
        <div className={'col-span-2'}></div>
      </div>

      <Footer/>
    </div>
  );
}

export default Asking;
