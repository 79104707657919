import axios from "axios";
import { getCookie } from "./utils/cookieUtil";

// const BASE_URL = 'http://localhost:8080';
const BASE_URL = "https://user-api.beta-check.com";
export async function isIdExists(userId) {
  let url = `${BASE_URL}/auth/isIdExists`;
  if (userId !== undefined) {
    url += `?userId=${userId}`;
  }
  const response = await axios.get(url);
  return response.data;
}
export async function isEmailExists(email) {
  let url = `${BASE_URL}/auth/isEmailExists`;
  if (email !== undefined) {
    url += `?email=${email}`;
  }
  const response = await axios.get(url);
  return response.data;
}

// 이메일 검증코드 발송 및 회원가입
// 실제 데이터 생성은 이 단계에서 한다.
export async function signUp(userObj) {
  const response = await axios.post(`${BASE_URL}/auth/signup`, userObj, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

// 이메일 코드 확인, 회원가입 완료
export async function confirmSignUp(userObj) {
  const response = await axios.post(`${BASE_URL}/auth/confirmSignup`, userObj, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

// 로그인
export async function signIn(userObj) {
  const response = await axios.post(`${BASE_URL}/auth/signin`, userObj, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

// header에 token 넣기
export async function checkAccessToken(accessToken) {
  let url = `${BASE_URL}/auth/checkAccessToken`;
  if (accessToken !== undefined) {
    url += `?accessToken=${accessToken}`;
  }
  const response = await axios.get(url, {
    headers: {
      authorization: `Bearer ${getCookie("accessToken")}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

// password confirmationCode 받기 (이메일로)
export async function forgotPassword(param) {
  const response = await axios.post(`${BASE_URL}/auth/findPassword`, param, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

// confirmationCode 입력하여 password reset
export async function resetPassword(param) {
  const response = await axios.post(`${BASE_URL}/auth/resetPassword`, param, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function getUser(userId) {
  let url = `${BASE_URL}/auth/getUser`;
  if (userId !== undefined) {
    url += `?userId=${userId}`;
  }
  const response = await axios.get(url, {
    headers: {
      // "authorization": `Bearer ${getCookie("accessToken")}`,
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function getNiceRequestData(siteUrl) {
  let url = `${BASE_URL}/auth/nice/getNiceRequestData?requestUrl=` + siteUrl;
  const response = await axios.get(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function getNiceResponseData(reqParam) {
  let url = `${BASE_URL}/auth/nice/getNiceResponseData`;
  const response = await axios.post(url, reqParam, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.data;
}

export async function findUserIdByEmail(email, siteName) {
  let url = `${BASE_URL}/auth/findUserIdByEmail?email=` + email + '&siteName=' + siteName;
  const response = await axios.get(
      url
      , {
        headers: {
          "Content-Type": 'application/json'
        },
      }
  );
  return response.data;
}