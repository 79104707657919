import React, { useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserInfoContext } from "../../App";
import { checkAccessToken, confirmSignUp } from "../../services/authService";
import { useCookies } from "react-cookie";

function EmailVerification() {
  // @ts-ignore
  let { userInfo, login } = useContext(UserInfoContext);

  const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);

  const authCheck = () => {
    const token = cookies.accessToken;
    checkAccessToken(token)
      .then((data: any) => {
        userInfo = {
          userId: data.userId,
          email: data.email,
          accessToken: data.accessToken,
        };
        login(userInfo);
      })
      .catch(() => {
        console.log("로그인 페이지로 이동");
        goSignIn();
      });
  };

  const navigate = useNavigate();
  const goSignIn = () => {
    navigate("/userSignIn");
  };

  const [emailVerificationNumber, setEmailVerificationNumber] = useState("");
  const handleChangeEmailVerificationNumber = (e: any) => {
    setEmailVerificationNumber(e.target.value);
  };
  const sendEmailVerificationButton = useRef(null);
  const sendEmailVerification = () => {
    const confirmEmailRequest = {
      userId: userInfo.userId,
      confirmationCode: emailVerificationNumber,
    };
    // 이미 저장된 상태에서 업데이트만 한다.
    confirmSignUp(confirmEmailRequest).then((result) => {
      console.log(result);
      if (result.statusCode === 200) {
        console.log("회원가입 완료!");
        // goRegistrationComplete();
      } else {
        console.log("User 업데이트 오류!");
      }
    });
  };

  return (
    <>
      <div className="content min-h-screen">
        <h2 className="p-2 font-bold App text-lg">베타점검 이메일 인증</h2>
        <div className="max-w-6xl mx-auto">
          <form className="m-2">
            <div className="mb-3">
              <label htmlFor="emailVerificationNumber" className="block mb-2">
                인증번호
              </label>
              <div className="flex gap-2 justify-center">
                <input
                  type="text"
                  id="emailVerificationNumber"
                  className="flex-grow p-2 border rounded-md w-full focus:outline-[#3F80EA] focus:ring-1"
                  name="emailVerificationNumber"
                  value={emailVerificationNumber}
                  onChange={handleChangeEmailVerificationNumber}
                />
                <button
                  type="button"
                  className="text-white py-2 rounded hover:opacity-90 transition duration-300 ease-in-out bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] w-1/4"
                  ref={sendEmailVerificationButton}
                  onClick={sendEmailVerification}
                >
                  인증번호 발송
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EmailVerification;
