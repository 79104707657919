import { useEffect } from 'react';

const usePreventClose = () => {
  useEffect(() => {
    const handleBeforeUnload = (event:any) => {
      event.preventDefault();
      event.returnValue = ''; // 필요에 따라 메시지를 반환할 수 있음
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
};

export default usePreventClose;
