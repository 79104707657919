import { forwardRef, useImperativeHandle } from "react";
import { getNiceRequestData } from "../services/authService";

export const NiceCommonForm = forwardRef((props: any, ref) => {
  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    niceFormSubmit,
  }));

  const popupListener = (e: any) => {
    // 동일한 Origin 의 이벤트만 처리하도록 제한
    if (e.origin !== window.location.origin) {
      return;
    }
    if (e.data) {
      props.setFullName(e.data.userName);
      // TODO phone 임시로 자동세팅 -> Nice 에서 정보 받아오게 되면 변경 필요
      props.setPhone("01012349999");

      // props.setPhone(e.data.phone);
    }
  };

  const niceFormSubmit = () => {
    if (window.confirm("본인인증을 하시겠습니까?")) {
      const requestUrl = window.location.href;
      getNiceRequestData(requestUrl).then((data) => {
        localStorage.setItem("niceKey", data.key);
        localStorage.setItem("niceIv", data.iv);

        const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=0, top=0`;
        const popup = window.open(
          "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb",
          "popupChk",
          option
        );

        // @ts-ignore
        const niceForm = document.forms["niceForm"];

        niceForm.action =
          "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";
        niceForm.target = "popupChk";
        niceForm.token_version_id.value = data.tokenVersionId;
        niceForm.enc_data.value = data.encData;
        niceForm.integrity_value.value = data.integrityValue;
        niceForm.submit();

        window.addEventListener("message", popupListener, false);
      });
    }
  };

  return (
    <>
      <form name={"niceForm"}>
        <input type={"hidden"} name={"m"} value={"service"}></input>
        <input type={"hidden"} name={"token_version_id"}></input>
        <input type={"hidden"} name={"enc_data"}></input>
        <input type={"hidden"} name={"integrity_value"}></input>
      </form>
    </>
  );
});
