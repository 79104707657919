import axios from 'axios';

// const BASE_URL = 'http://localhost:8080';
const BASE_URL = 'https://user-api.beta-check.com';

// 사업자 정보 저장하기
export async function saveBusinessUser(businessUser) {
    const response = await axios.post(
        `${BASE_URL}/business/saveBusinessUser`
        , businessUser
        , {headers: {
                "Content-Type": 'multipart/form-data'
            }}
    );

    return response.data;
}
export async function saveAdditionalRegistration(businessUser) {
    const response = await axios.post(
        `${BASE_URL}/business/saveAdditionalRegistration`
        , businessUser
        , {headers: {
                "Content-Type": 'multipart/form-data'
            }}
    );

    return response.data;
}


export async function verifyBusinessRegistrationNumber(businessRegistrationNumber) {
    let url = `${BASE_URL}/business/verifyBusinessRegistrationNumber`;
    if (businessRegistrationNumber !== undefined) {
        url += `?businessRegistrationNumber=${businessRegistrationNumber}`;
    }
    const response = await axios.get(url);
    return response.data;
}

export async function getBusinessUser(userId) {
    let url = `${BASE_URL}/business/getBusinessUser`;
    if (userId !== undefined) {
        url += `?userId=${userId}`;
    }
    const response = await axios.get(
        url
        , {
            headers: {
                // "authorization": `Bearer ${getCookie("accessToken")}`,
                "Content-Type": 'application/json'
            },
        }
    );
    return response.data;
}

