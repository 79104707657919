import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { getNiceResponseData } from "../../services/authService";

function NiceSuccess() {
  const navigate = useNavigate();

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // 1. 본인인증 성공 후 URL queryString 파싱하기
    let tokenVersionId: any = null;
    let encData: any = null;
    let integrityValue: any = null;

    if (searchParams) {
      if (searchParams.get("token_version_id")) {
        tokenVersionId = searchParams.get("token_version_id");
      }
      if (searchParams.get("enc_data")) {
        encData = searchParams.get("enc_data");
      }
      if (searchParams.get("integrity_value")) {
        integrityValue = searchParams.get("integrity_value");
      }
    }

    const key = localStorage.getItem("niceKey");
    const iv = localStorage.getItem("niceIv");

    const reqParam = {
      key: key,
      iv: iv,
      tokenVersionId: tokenVersionId,
      encData: encData,
      integrityValue: integrityValue,
    };

    // 2. 데이터 서버로 보내기
    getNiceResponseData(reqParam).then((response) => {
      window.opener.postMessage(
        { userName: response.name, phone: response.mobileno },
        window.location.origin
      );

      // 3. 팝업창 닫기 (기존 페이지로 돌아가기)
      window.close();
    });
  }, []);

  return (
    <>
      <h2 className="text-lg">나이스 본인인증 성공!</h2>
    </>
  );
}

export default NiceSuccess;
