import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

interface ConfirmDialogProps {
  visible: boolean;
  onHide: () => void;
  message: string;
  onConfirm: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ visible, onHide, message, onConfirm }) => {
  const handleConfirm = () => {
    onConfirm();
    onHide();
  };

  const handleCancel = () => {
    onHide();
  };

  return (
    <Dialog header="확인" visible={visible} style={{ width: '400px' }} modal onHide={onHide} closable={false}>
      <p>{message}</p>
      <div className="mt-6 space-x-4">
        <Button label="확인" icon="pi pi-check" onClick={handleConfirm} autoFocus className={'bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA] rounded-lg'}/>
        <Button label="취소" icon="pi pi-times" onClick={handleCancel} className="bg-white text-primary border border-primary rounded-lg" />
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;