import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

function NotFound() {
  return (
    <div>
      <Header></Header>
      <div className={"content m-2 mt-5 mb-5"}>
        <h5 className="text-xl">
          페이지를 찾을 수 없습니다. URL을 다시한번 확인해주세요.
        </h5>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default NotFound;
