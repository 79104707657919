import { useContext, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { signIn } from "../../services/authService";
import { setCookie } from "../../services/utils/cookieUtil";
import { UserInfoContext } from "../../App";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import {useLoading} from "../../components/LoadingContext";

function UserSignIn() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {setLoading} = useLoading();
  const goUserRegistration = () => {
    navigate("/userRegistration");
  };

  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const passwordRef = useRef(null);
  const passwordTextRef = useRef(null);
  const handleShowPassword = (e: any) => {
    const password = passwordRef.current;
    if (password === null) return;

    setShowPassword(!showPassword);
    if (!showPassword) {
      // @ts-ignore
      password.type = "text";
    } else {
      // @ts-ignore
      password.type = "password";
    }
  };
  const handleChangeUserId = (e: any) => {
    setUserId(e.target.value);
  };
  const handleChangePassword = (e: any) => {
    setPassword(e.target.value);
  };
  // @ts-ignore
  const { login } = useContext(UserInfoContext);

  const userLogin = () => {
    const userObj = {
      userId: userId,
      password: password,
    };
    setLoading(true);
    signIn(userObj)
      .then((result) => {
        const userData = {
          userId: result.userId,
          email: result.email,
          fullName: result.fullName,
          phone: result.phone,
          accessToken: result.accessToken,
          refreshToken: result.refreshToken,
          companyYn: result.companyYn,
          businessUser: result.businessUser,
          userRoles: result.roles,
        };
        login(userData);
        console.log('signIn', result);
        // 정상 로그인 후 페이지로 이동
        if (state) {
          // 이전 페이지가 있으면
          navigate(state);
        } else {
          navigate("/");
        }
      })
      .catch(() => {
        // 로그인 실패 시
        // @ts-ignore
        passwordTextRef.current.className =
          "text-red-500 text-sm text-center p-3 border rounded-md w-auto md:w-[420px] mx-auto font-medium";
      }).finally(()=>{
        setLoading(false);
    });
  };

  return (
    <>
      <Header />
      <div className="content text-left min-h-screen md:pt-32">
        <div className="login-container max-w-md mx-auto p-4">
          <h1 className="p-2 font-semibold text-center mb-5">
            로그인
          </h1>
          <form className="space-y-5 w-auto md:w-[450px] mx-auto">
            <div className="flex justify-center">
              <IconField iconPosition="left">
                <InputIcon className="pi pi-user text-lg" />
                <InputText
                  v-model="value1"
                  className="w-auto md:w-[450px] h-fit md:h-[56px] text-lg focus:border focus:border-primary rounded-xl hover:border-primary focus:ring-0 border-0 shadow-md"
                  value={userId}
                  onChange={handleChangeUserId}
                  placeholder={"아이디를 입력해주세요."}
                />
              </IconField>
            </div>
            <div className="flex justify-center">
              <div className="relative">
                <IconField iconPosition="left">
                  <InputIcon className="pi pi-lock text-lg" />
                  <InputText
                    v-model="value1"
                    className="w-auto md:w-[450px] h-fit md:h-[56px] text-lg focus:border focus:border-primary rounded-xl hover:border-primary focus:ring-0 border-0 shadow-md"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={handleChangePassword}
                    ref={passwordRef}
                    placeholder={"비밀번호를 입력해주세요."}
                  />
                </IconField>

                <button
                  type="button"
                  className="absolute right-2 top-5 text-gray-500"
                  onClick={handleShowPassword}
                >
                  {showPassword ? (
                    <i className="pi pi-eye text-lg" />
                  ) : (
                    <i className="pi pi-eye-slash text-lg" />
                  )}
                </button>
              </div>
            </div>
            <div className="text-end divide-x-2 text-[#757575] group">
              <Link to="/findId" className="pr-2 hover:text-gray-800">
                아이디 찾기
              </Link>
              <Link to="/findPassword" className="pl-2 hover:text-gray-800">
                비밀번호 찾기
              </Link>
            </div>

            <div
              ref={passwordTextRef}
              className="hidden text-red-500 text-sm text-center p-3 border rounded-md w-auto md:w-[420px] mx-auto font-semibold"
            >
              <i className="pi pi-info-circle text-red-500 mr-1 font-semibold"></i>{" "}
              아이디 혹은 비밀번호가 일치하지 않습니다. 다시 확인해 주세요.
            </div>

            <div className="space-y-2 flex flex-col mx-auto w-fit">
              <button
                type="button"
                className="w-auto md:w-[450px] h-fit md:h-[56px] py-2 text-white rounded-xl hover:opacity-90 transition duration-300 ease-in-out bg-gradient-to-r from-[#2AC9A4] to-[#3F80EA]"
                onClick={userLogin}
              >
                로그인
              </button>
              <button
                type="button"
                className="w-auto md:w-[450px] h-fit md:h-[56px] py-2 text-blue-600 border border-blue-600 rounded-xl"
                onClick={goUserRegistration}
              >
                회원가입
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default UserSignIn;
