import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CommonTermsOfUse from "../components/CommonTermsOfUse";

function TermsOfUse() {

  return (
    <div>
      <Header></Header>
        <CommonTermsOfUse/>
      <Footer></Footer>
    </div>
  );
}

export default TermsOfUse;
