import React from "react";

function HealthCheck() {
  return (
    <div>
      <div>
        <h1 className="text-4xl">App is Healthy!</h1>
      </div>
    </div>
  );
}

export default HealthCheck;
